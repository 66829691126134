import React, { useEffect, useState } from "react";
import PageTitle from "../../../misc/LMS/PageTitle";
import EmailTemplate from "./EmailTemplate";
import NoteTemplate from "./NoteTemplate";
import AutoResponse from "./AutoResponse";
import { Grid } from "@material-ui/core";
import SlidingButtonGroup from "../../../misc/SlidingButtonGroup";
import CurrentItems from "./CurrentItems";

const styles = {};

const pages = [
  {
    title: "Notes",
    context: <NoteTemplate />
  },
  {
    title: "Email",
    context: <EmailTemplate />
  },
  {
    title: "Auto Email",
    context: <AutoResponse />
  }
];

const Template = props => {
  const [index, setIndex] = useState(0);
  const getIndex = index => setIndex(index);

  return (
    <div>
      <PageTitle title="Template" />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={7}>
          <SlidingButtonGroup
            pages={pages}
            regular
            padding={0}
            getIndex={getIndex}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5}>
          <CurrentItems index={index} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Template;
