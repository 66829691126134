import React from 'react'

const SvgComponent = props => (
  <svg
    width={412.187}
    height={278.141}
    viewBox="0 0 109.058 73.591"
    id="prefix__svg8"
    {...props}
  >
    <defs id="prefix__defs2">
      <clipPath id="prefix__clipPath3753" clipPathUnits="userSpaceOnUse">
        <path id="prefix__path3751" d="M0 913.485h1280V0H0z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath3753-7">
        <path d="M0 913.485h1280V0H0z" id="prefix__path3751-4" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath3753-6">
        <path d="M0 913.485h1280V0H0z" id="prefix__path3751-3" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath3753-4">
        <path d="M0 913.485h1280V0H0z" id="prefix__path3751-30" />
      </clipPath>
      <clipPath id="prefix__clipPath3753-7-9" clipPathUnits="userSpaceOnUse">
        <path id="prefix__path3751-4-1" d="M0 913.485h1280V0H0z" />
      </clipPath>
    </defs>
    <g id="prefix__layer1" transform="translate(-50.51 -77.956)">
      <g
        id="prefix__g4161"
        transform="matrix(.26458 0 0 .26458 -319.004 -11.202)"
      >
        <path
          id="prefix__polygon4125"
          transform="matrix(2.05805 0 0 2.4452 -381.923 -506.838)"
          fill="red"
          d="M1056.62 345.09h-70.33l-7.67 13.28h62.85l-43.04 74.87-17.86-28.19-7.5 12.98 17.88 28.22 7.82 12.59 7.49-12.98.12-.07-.04-.06 58.11-100.64z"
        />
        <path
          id="prefix__polygon4127"
          transform="matrix(2.05805 0 0 2.4452 -381.923 -506.838)"
          d="M864.17 345.09h15.73l63.8 100.7-11.22 7.11z"
          fill='white'
        />
        <path
          id="prefix__polygon4129"
          transform="matrix(2.05805 0 0 2.4452 -381.923 -506.838)"
          d="M936.09 458.84l-7.82-12.59 43.14-75.04 15.31-.06z"
          fill='white'

        />
      </g>
    </g>
    <style id="style4117" type="text/css" />
    <style id="style5249" type="text/css" />
    <style type="text/css" id="style4117-5" />
    <style type="text/css" id="style4117-3" />
    <style type="text/css" id="style5249-7" />
    <style type="text/css" id="style4117-8" />
    <style type="text/css" id="style5249-78" />
    <style id="style4117-5-1" type="text/css" />
  </svg>
)

export default SvgComponent
