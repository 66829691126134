import React from "react";
import ProfilePage from "../../components/LMS/Profile/Profile";
import Layout from "../../components/LMS/Layout/Layout";

const Profile = props => {
  return (
    <div>
      <Layout {...props}>
        <ProfilePage {...props} />
      </Layout>
    </div>
  );
};

export default Profile;
