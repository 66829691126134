import React from "react";
import { Grid } from "@material-ui/core";
import WadeJr from "../../images/Personal/OnlyLogoWhite";
import DM from "../../images/Marketing/OnlyLogo";
import LMS from "../../images/LMS/LMS_Logo.png";

const styles = {
  root: {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#d7d7d7"
  }
};

const Four04 = props => {
  return (
    <div style={styles.root}>
      <div>
        <h2 className="text-center" style={{ fontSize: "48px" }}>
          404
        </h2>
        <h3 className="text-center mb-5">Page not found 🤨</h3>
        <h3 className="text-center">Where to?</h3>

        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item xs={12} md={4}>
            <a href="/" className="text-center" style={{ color: "black" }}>
              <div>
                <WadeJr style={{ height: "100px", width: "100px" }} />
              </div>
              <h4>Wade Jr</h4>
            </a>
          </Grid>
          <Grid item xs={12} md={4}>
            <a
              href="/marketing"
              className="text-center"
              style={{ color: "black" }}
            >
              <div>
                <DM style={{ height: "100px", width: "100px" }} />
              </div>
              <h4>Dental Marketing</h4>
            </a>
          </Grid>
          <Grid item xs={12} md={4}>
            <a href="/lms" className="text-center" style={{ color: "black" }}>
              <div>
                <img src={LMS} style={{ height: "100px", width: "100px" }} />
              </div>
              <h4>Lead Management System</h4>
            </a>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Four04;
