

export const mainStyle = {
    buttonColor: "#ff0000",
    hoverColor: "#888",
    secondaryColor: "#888",
    defaultColor: "#ff0000",
    button: {
      fontFamily: "Montserrat, 'Proza Libre', -apple-system, sans-serif",
      fontWeight: 700,
    },
    bigButton: {
      fontFamily: "Montserrat, 'Proza Libre', -apple-system, sans-serif",
      fontWeight: 700,
      fontSize: "21px",
      padding: "14px 32px"
    },
    alternateBackgroundColor: {
      backgroundColor:"#eff2f6"//"#f4f6fa"//
    },
  
    section: {
      paddingTop: "75px",
      paddingBottom: "75px"
    },
    text: {
      fontFamily: "Montserrat, 'Proza Libre', -apple-system, sans-serif",
      color: 'black',
      fontSize: '18px',
      fontWeight: 400,
    }
  };
  
  export default mainStyle;
  