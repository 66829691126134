import React from "react";
import ColorButton from "../../../misc/ColorButton";
import mainStyle from "../../../styles/Personal/mainStyle";
import { Container } from "@material-ui/core";
import ScrollAnimation from "react-animate-on-scroll";

const CallMe = () => {
  return (
    <div
      className="section text-center"
      style={{ backgroundColor: "white", width: "100%" }}
    >
      <Container fixed>
        <h1 className="mb-4" style={{ color: "red" }}>
          Get In Touch
        </h1>
        <p className="mb-5 mr-auto ml-auto" style={{ maxWidth: "465px" }}>
          I'm always looking for new opportunities for growth and to acquire
          more assets. Let's work together! I'm the guy to get you where you need to be.
        </p>
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOnce
          initiallyVisible={false}
        >
          <ColorButton
            contained
            noRadius
            color={mainStyle.buttonColor}
            href="mailto:wadealsmith@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
            hoverColor={mainStyle.hoverColor}
            style={{
              ...mainStyle.bigButton
            }}
          >
            Say Hello
          </ColorButton>
        </ScrollAnimation>
      </Container>
    </div>
  );
};

export default CallMe;
