const ScrollBarColor = (thumb, background, hover = null, radius = null) => {
  var sheet = document.createElement("style");
  var hoverStyle;
  if (hover) {
    hoverStyle = ` ::-webkit-scrollbar-thumb:hover {
                        background: ${hover};
                    }`;
  } else {
    hoverStyle = "";
  }

  sheet.innerHTML = `
            ::-webkit-scrollbar {
                width: 15px;
                
            }
            ::-webkit-scrollbar-track  {
            background: ${background};
            }
            ::-webkit-scrollbar-thumb {
            background: ${thumb};
            border-radius: ${radius ? radius : "0px"};
            position: relative;
            z-index: 1001;
            }
            ${hoverStyle}
        `;
  document.body.appendChild(sheet);
};

export default ScrollBarColor;
