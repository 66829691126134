import React, { useEffect, useState } from "react";
import Header from "../../components/Personal/Header/Header";
import ContactForm from "../../components/Personal/Contact/Contact";
import Footer from "../../components/Personal/Footer/Footer";
import Nav from "../../components/Personal/Nav/Nav";
import { Helmet } from "react-helmet";
import Meta from "../../misc/Meta";
import ScrollBarColor from "../../misc/ScrollBarColor";
import ScrollToTop from "../../misc/ScrollToTop";

const meta = {
  title: "Wade Jr. | Contact",
  description:
    "Feel free to contact me with any regarding opportunities for work. Wade Jr. is a computer engineer with interests in full-stack development, marketing, real estate, ecommerce, and stocks.",
  url: "www.wadejr.com/contact"
};

const Contact = () => {
  ScrollBarColor("red", "snow", null, "10px");


  return (
    <div>
      <Helmet>
        <title>Wade Jr. | Contact</title>
        <Meta
          title={meta.title}
          description={meta.description}
          url={meta.url}
        />
      </Helmet>
      <ScrollToTop>
    
          <Nav />
          <Header title="Contact" />
          <ContactForm />
          <Footer />
        </ScrollToTop>
     
    </div>
  );
};

export default Contact;
