import React from "react";
import PropTypes from "prop-types";
import { Container, Divider } from "@material-ui/core";
import backgroundImage from "../../../images/Personal/overlook.jpg";
import lazyBackgroundImage from "../../../images/Personal/overlook-min.jpg";
import LogoWhite from "../../../images/Personal/LogoWhite";
import LazyLoad from "react-lazyload";
import "./style.css";
import mainStyle from "../../../styles/Personal/mainStyle";
import ColorButton from "../../../misc/ColorButton";

const styles = {
  hero: {
    backgroundImage: `url(${backgroundImage})`,
    height: "100vh",
    maxWidth: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  lazyHero: {
    backgroundImage: `url(${lazyBackgroundImage})`,
    height: "100vh",
    maxWidth: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  centerDiv: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
  },
  overlay: {
    backgroundColor: "rgb(0, 0, 0, .6)",
    height: "100%",
    width: "100%"
  },
  gradient: {
    //#292
    background: `-webkit-linear-gradient(${mainStyle.defaultColor}, ${mainStyle.secondaryColor})`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  },
  down: {
    position: "absolute",
    bottom: "0px",
    width: "100%"
  },
  center: {
    position: "relative"
  }
};

const HeroContent = props => {
  return (
    <div
      id="hero"
      className={props.className}
      style={props.lazy ? styles.lazyHero : styles.hero}
    >

      <div style={styles.overlay}>
        <div style={styles.centerDiv}>
          <Container fixed>
            <div style={{ marginTop: "-100px" }} className="w-100">
              <div style={{ color: "white" }} className="text text-center">
                {/* <h1>Wade Jr<span style={{color: mainStyle.defaultColor}}>.</span></h1> */}
                <div className='pb-3' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <LogoWhite style={{ height: "125px" }} />
                </div>
                <div  className='pb-3'>
                  <h1 style={{ fontSize: "32px" }}>
                    <i>Entrepreneur</i>{" "}
                    <span id="line" style={{ color: mainStyle.defaultColor }}>
                      |
                    </span>
                    <i> Engineer</i>
                  </h1>
                </div>
                <div>
                  <ColorButton
                    contained
                    noRadius
                    color={mainStyle.buttonColor}
                    href="mailto:wadealsmith@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    hoverColor={mainStyle.hoverColor}
                    style={{
                      ...mainStyle.bigButton
                    }}
                  >
                    Get In Touch
                  </ColorButton>
                </div>
              </div>
            </div>
          </Container>
          <div style={styles.down}>
            <div style={styles.center}>
              <a
                style={{ textDecoration: "none" }}
                href="/#about"
                className="text-center"
              >
                <p
                  id="bounce"
                  style={{ color: "white", fontSize: 30, marginBottom: 0 }}
                >
                  SCROLL
                </p>
                <div style={{ width: "100%" }}>
                  <Divider
                    orientation="vertical"
                    style={{
                      backgroundColor: mainStyle.defaultColor,
                      height: "50px",
                      width: "5px",
                      marginLeft: "auto",
                      marginRight: "auto"
                    }}
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HeroContent.propTypes = {
  //If true lazy load the hero background
  lazy: PropTypes.bool,
  className: PropTypes.string
};

const Hero = () => {
  return (
    <div>
      <LazyLoad
        once
        height={500}
        offset={200}
        placeholder={<HeroContent lazy />}
      >
        <HeroContent />
      </LazyLoad>
    </div>
  );
};

export default Hero;
