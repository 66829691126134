import React, { useEffect, useState } from "react";
import PageTitle from "../../../misc/LMS/PageTitle";
import CallsOnTime from "./CallsOnTime";
import { Grid } from "@material-ui/core";
import Leads from "./Leads";
import MoreInfo from "./MoreInfo";

const styles = {};

const Dashboard = props => {
  return (
    <div>
      <PageTitle title="Dashboard" />
      <Grid container spacing={3} className="mb-5 mt-3">
        <Grid item xs={12} md={6}>
          <Leads />
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="mb-3">
            <CallsOnTime />
          </div>
          <MoreInfo />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
