import React from "react";
import ArchivePage from "../../components/LMS/Archive/Archive";
import Layout from "../../components/LMS/Layout/Layout";

const Archive = props => {
  return (
    <div>
      <Layout {...props}>
        <ArchivePage {...props} />
      </Layout>
    </div>
  );
};

export default Archive;
