import React from "react";

const SvgComponent = props => (
  <svg
    width={789.881}
    height={533.248}
    viewBox="0 0 208.989 141.088"
    id="prefix__svg8"
    {...props}
  >
    <defs id="prefix__defs2">
      <clipPath id="prefix__clipPath3753" clipPathUnits="userSpaceOnUse">
        <path id="prefix__path3751" d="M0 913.485h1280V0H0z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath3753-7">
        <path d="M0 913.485h1280V0H0z" id="prefix__path3751-4" />
      </clipPath>
    </defs>
    <g id="prefix__layer1" transform="translate(-.505 -77.956)">
      <g id="prefix__g6505">
          <path

            id="prefix__polygon4125"
            transform="matrix(.54453 0 0 .64696 -420.055 -145.303)"
            fill="red"
            d="M990.95 446.25l7.82 12.59 7.49-12.98.12-.07-.04-.06 58.11-100.64h-78.16l-7.67 13.28h62.85l-43.04 74.87-17.86-28.19-7.5 12.98z"
          />
          <path
            id="prefix__polygon4127"
            transform="matrix(.54453 0 0 .64696 -420.055 -145.303)"
            fill="#fff"
            d="M943.7 445.79l-11.22 7.11-68.31-107.81h15.73z"
          />
          <path
            id="prefix__polygon4129"
            transform="matrix(.54453 0 0 .64696 -420.055 -145.303)"
            fill="#fff"
            d="M971.41 371.21l15.31-.06-50.63 87.69-7.82-12.59z"
          />
        <path
          d="M35.524 218.313h-4.9l-3.807-11.438-3.24-11.49-3.196 11.6-3.725 11.328h-4.9L.505 181.91h6.48l7.22 26.26 7.443-26.26h3.763l7.661 26.26 7.003-26.26h6.48z"
          id="prefix__path4131"
          fill="#fff"
          strokeWidth={0.594}
        />
        <path
          d="M71.85 211.449H55.83l-2.625 6.864h-6.257l13.744-36.404h6.305l13.744 36.404h-6.31zm-8.01-21.893l-5.691 15.495h11.38z"
          id="prefix__path4133"
          fill="#fff"
          strokeWidth={0.594}
        />
        <path
          d="M111.24 199.797c.131 9.206-4.596 18.516-15.317 18.516H83.889V181.91h12.034c10.504 0 15.187 8.896 15.317 17.888zm-21.623 11.91h6.306c6.959 0 9.72-6.029 9.583-11.962-.13-5.667-2.935-11.334-9.583-11.334h-6.306z"
          id="prefix__path4135"
          fill="#fff"
          strokeWidth={0.594}
        />
        <path
          d="M137.895 218.313h-22.936V181.91h22.936v6.657h-17.202v8.372h16.587v6.398h-16.587v8.216h17.202z"
          id="prefix__path4137"
          fill="#fff"
          strokeWidth={0.594}
        />
        <path
          d="M176.888 202.767c0 9.413-5.031 16.277-13.482 16.277-7.836 0-13.439-5.564-13.439-15.863h5.69c0 6.243 2.538 9.413 7.749 9.413 5.255 0 7.705-4.315 7.705-9.827v-14.46h-9.235v-6.398h15.012z"
          id="prefix__path4139"
          fill="#fff"
          strokeWidth={0.594}
        />
        <path
          d="M209.495 218.313h-6.872l-8.93-12.17h-5.56v12.17h-5.778v-36.456c4.858 0 9.715.052 14.577.052 7.22.052 11.033 5.77 11.033 12.066 0 4.994-1.928 10.034-7.749 11.438l9.279 12.428zm-21.362-30.006v11.652h8.8c3.675 0 5.254-2.911 5.254-5.823 0-2.91-1.617-5.822-5.255-5.822h-8.8z"
          id="prefix__path4141"
          fill="#fff"
          strokeWidth={0.594}
        />
      </g>
    </g>
    <style id="style4117" type="text/css" />
    <style id="style5249" type="text/css" />
    <style type="text/css" id="style4117-5" />
  </svg>
);

export default SvgComponent;
