import React, { useState, useEffect } from "react";
import {
  Container,
  InputLabel,
  InputAdornment,
  FormControl,
  IconButton,
  OutlinedInput,
  Select
} from "@material-ui/core";
import ColorButton from "../../../misc/ColorButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import mainStyle from "../../../styles/LMS/mainStyle";
import { ClipLoader } from "react-spinners";
import { connect, useSelector } from "react-redux";
import { register } from "../../../redux/LMS/actions/loginActions";
import Recaptcha from "../../../misc/Recaptcha";
import Swal from "sweetalert2";
import PhoneNumberTextbox from "../../../misc/PhoneNumberTextbox";
import { Link } from "react-router-dom";

const statesArray = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Island",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];

const Register = props => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    password2: "",
    showPassword: false,
    showPassword2: false,
    firstName: "",
    lastName: "",
    phone: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    adminId: ""
  });

  const [loading, setLoading] = useState(false);

  const cred = useSelector(state => state.login);

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowPassword2 = () => {
    setValues({ ...values, showPassword2: !values.showPassword2 });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  useEffect(() => {
    setLoading(cred.loading);
  }, [cred.loading]);

  const submit = e => {
    e.preventDefault();
    if (values.password === values.password2) {
      props.register({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        address: values.address,
        address2: values.address2,
        city: values.city,
        state: values.state,
        zip: values.zip,
        admin_id: values.adminId,
        phone: values.phone
      });
    } else {
      Swal.fire({
        timer: 3000,
        timerProgressBar: true,
        title: "Error",
        text: "Passwords don't match",
        type: "error",
        toast: true,
        position: "top-end",
        showConfirmButton: false
      });
    }
  };

  // useEffect(() => {
  //   if (cred.status > 399) {
  //     Swal.fire({
  //       timer: 3000,
  //       timerProgressBar: true,
  //       title: "Error",
  //       text: cred.message,
  //       type: "error",
  //       toast: true,
  //       position: "top-end",
  //       showConfirmButton: false
  //     });
  //   } else if (cred.status === 200) {
  //     // window.location.href = "/dashboard";
  //     console.log('ut oh')
  //   }
  // }, [cred]);
  return (
    <div className="mt-3 mb-3">
      <Container fixed>
        <div className="mb-3 text-center">
          <p style={{ ...mainStyle.text, fontSize: "14px" }}>
            <i>Please make sure you already spoke to Wade about registering.</i>
          </p>
        </div>
        <form onSubmit={submit}>
          <div className="mb-3">
            <FormControl required variant="outlined" style={{ width: "100%" }}>
              <InputLabel required htmlFor="firstName">
                First Name
              </InputLabel>
              <OutlinedInput
                id="firstName"
                type={"text"}
                value={values.firstName}
                onChange={handleChange("firstName")}
                required
                labelWidth={100}
              />
            </FormControl>
          </div>
          <div className="mb-3">
            <FormControl required variant="outlined" style={{ width: "100%" }}>
              <InputLabel required htmlFor="lastName">
                Last Name
              </InputLabel>
              <OutlinedInput
                id="lastName"
                type={"text"}
                value={values.lastName}
                onChange={handleChange("lastName")}
                required
                labelWidth={100}
              />
            </FormControl>
          </div>
          <div className="mb-3">
            <FormControl required variant="outlined" style={{ width: "100%" }}>
              <InputLabel required htmlFor="email">
                Email
              </InputLabel>
              <OutlinedInput
                id="email"
                type={"email"}
                value={values.email}
                onChange={handleChange("email")}
                required
                labelWidth={60}
              />
            </FormControl>
          </div>
          <div className="mb-3" key="work">
            <PhoneNumberTextbox
              value={values.phone}
              onChange={handleChange("phone")}
            />
          </div>

          <div className="mb-3">
            <FormControl required variant="outlined" style={{ width: "100%" }}>
              <InputLabel required htmlFor="address">
                Address
              </InputLabel>
              <OutlinedInput
                id="address"
                type={"text"}
                value={values.address}
                onChange={handleChange("address")}
                required
                labelWidth={100}
              />
            </FormControl>
          </div>
          <div className="mb-3">
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel htmlFor="address2">Address 2</InputLabel>
              <OutlinedInput
                id="address2"
                type={"text"}
                value={values.address2}
                onChange={handleChange("address2")}
                required
                labelWidth={100}
              />
            </FormControl>
          </div>
          <div className="mb-3">
            <FormControl required variant="outlined" style={{ width: "100%" }}>
              <InputLabel required htmlFor="city">
                City
              </InputLabel>
              <OutlinedInput
                id="city"
                type={"text"}
                value={values.city}
                onChange={handleChange("city")}
                required
                labelWidth={100}
              />
            </FormControl>
          </div>
          <div className="mb-3">
            <FormControl required variant="outlined" style={{ width: "100%" }}>
              <InputLabel required htmlFor="state">
                State
              </InputLabel>
              <Select
                id="state"
                name="state"
                value={values.state}
                native
                onChange={handleChange("state")}
                required
                labelWidth={100}
              >
                {statesArray.map((state, idx) => (
                  <option key={idx}>{state}</option>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="mb-3">
            <FormControl required variant="outlined" style={{ width: "100%" }}>
              <InputLabel required htmlFor="zip">
                Zip
              </InputLabel>
              <OutlinedInput
                id="zip"
                type={"text"}
                value={values.zip}
                onChange={handleChange("zip")}
                required
                labelWidth={100}
              />
            </FormControl>
          </div>

          <div className="mb-3">
            <FormControl required variant="outlined" style={{ width: "100%" }}>
              <InputLabel required htmlFor="password">
                Password
              </InputLabel>
              <OutlinedInput
                id="password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={85}
              />
            </FormControl>
          </div>
          <div className="mb-3">
            <FormControl required variant="outlined" style={{ width: "100%" }}>
              <InputLabel required htmlFor="password2">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="password2"
                type={values.showPassword2 ? "text" : "password"}
                value={values.password2}
                onChange={handleChange("password2")}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword2 ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={150}
              />
            </FormControl>
          </div>
          <div className="mb-3">
            <FormControl required variant="outlined" style={{ width: "100%" }}>
              <InputLabel required htmlFor="adminId">
                Admin Id
              </InputLabel>
              <OutlinedInput
                id="adminId"
                type={"text"}
                value={values.adminId}
                onChange={handleChange("adminId")}
                required
                labelWidth={100}
              />
            </FormControl>
          </div>
          <div className="pb-3" style={{ ...mainStyle.text }}>
            By joining you agree to our{" "}
            <a
              href="/lms/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>{" "}
            &{" "}
            <a
              href="/lms/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </div>
          <div>
            <ColorButton
              contained
              noRadius
              textColor="white"
              color={mainStyle.buttonColor}
              hoverColor={mainStyle.hoverColor}
              disabled={loading}
              type="submit"
              style={{
                ...mainStyle.bigButton,
                width: "100%"
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <span className={loading ? "mr-2" : ""}>Register</span>
                <ClipLoader
                  sizeUnit={"px"}
                  size={15}
                  color={mainStyle.defaultColor}
                  loading={loading}
                />
              </div>
            </ColorButton>
          </div>
        </form>
      </Container>
    </div>
  );
};

const actions = {
  register
};
export default connect(null, actions)(Register);
