import {createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './redux/'

const initialState = {}

const middleware = [thunk]
const composeEnchancers = window.__REDUX_DEVTOOLS_EXTENSION__COMPOSE || compose

const store = createStore(rootReducer, initialState, composeEnchancers(applyMiddleware(...middleware),))

export default store