import React from "react";
import TemplatePage from "../../components/LMS/Template/Template";
import Layout from "../../components/LMS/Layout/Layout";

const Template = props => {
  return (
    <div>
      <Layout {...props}>
        <TemplatePage {...props} />
      </Layout>
    </div>
  );
};

export default Template;
