import React from "react";
import LegalTemplate from "./LegalTemplate";

const TermsAndConditions = props => {
  return (
    <div>
      <LegalTemplate name='Terms and Conditions' {...props}>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ligula
          metus, condimentum eget faucibus vel, sodales ut ligula. Vivamus nec
          euismod lorem, id luctus nisl. Pellentesque semper pulvinar finibus.
          Fusce blandit porta tortor, at ultrices felis fermentum sit amet.
          Morbi euismod consectetur euismod. Pellentesque ut elit vitae felis
          aliquet lobortis. Aenean congue tincidunt nunc, vel tincidunt ligula.
          Duis dapibus arcu ac lorem consectetur, sit amet ullamcorper est
          dapibus. Sed ultricies scelerisque fermentum. Sed non viverra ante.
          Proin velit turpis, hendrerit rhoncus nunc vel, vestibulum ornare
          neque. Morbi mollis eget erat et finibus. Vivamus accumsan lorem ut
          sem facilisis ullamcorper. Cras cursus ipsum eu ligula convallis
          pretium. Sed vestibulum convallis elit, vel sollicitudin purus gravida
          nec. Nulla augue massa, blandit ac facilisis sed, maximus quis orci.
          Duis tristique posuere velit at ultricies.
        </p>
        <p>
          Nunc rutrum pellentesque orci, tincidunt faucibus ex hendrerit vel.
          Vestibulum vulputate nec purus sit amet blandit. Suspendisse sed mi
          quis ipsum imperdiet luctus. Pellentesque habitant morbi tristique
          senectus et netus et malesuada fames ac turpis egestas. Morbi volutpat
          metus a nulla suscipit vestibulum. Etiam sit amet tortor sed mi
          posuere fringilla condimentum dignissim enim. Nullam arcu nunc,
          pulvinar eget justo in, ultrices suscipit metus. Vivamus id dapibus
          arcu. Pellentesque sapien nibh, elementum at venenatis non, bibendum
          et odio. Proin et tristique magna. Quisque ultricies elit luctus lacus
          tincidunt rhoncus. Nullam interdum posuere tellus, quis condimentum
          leo malesuada eget. Quisque ut lacinia lectus. Praesent eleifend lorem
          sed massa pretium rutrum. Maecenas at mauris turpis. Donec molestie
          lacus eu sem congue, eget auctor lectus vulputate. Vivamus aliquet,
          nibh quis venenatis maximus, neque sapien pretium ex, ut congue sapien
          mi ut augue. Sed interdum lorem at leo posuere, a sollicitudin arcu
          placerat. Sed gravida vehicula felis, consectetur accumsan libero
          ultrices eget. Vivamus ipsum nunc, maximus nec tortor quis,
          scelerisque tempor diam. Aliquam ut ultricies nisi. Praesent facilisis
          massa a dui dictum, at pulvinar tellus egestas. Suspendisse a diam
          turpis. Nulla facilisi. Duis accumsan dictum elementum. Donec id
          feugiat mauris.
        </p>
        <p>
          Phasellus lobortis efficitur tempor. Suspendisse rutrum, urna laoreet
          iaculis laoreet, erat turpis gravida odio, ut fermentum risus massa
          eget eros. Donec fringilla luctus enim, finibus semper dolor imperdiet
          ut. Pellentesque tincidunt mi sapien, vel dictum dolor placerat eget.
          Maecenas aliquet sapien efficitur, sollicitudin tortor in, eleifend
          tellus. In ullamcorper magna in varius sodales. Cras viverra elit
          risus, sed aliquet turpis sollicitudin et. Nullam lorem libero,
          pharetra eu nisl ac, consequat consequat quam. Suspendisse potenti.
          Nam risus diam, euismod et volutpat sit amet, vulputate volutpat
          tortor. Fusce sit amet nisl eu mauris volutpat feugiat. Curabitur
          congue imperdiet ipsum non elementum. Maecenas sagittis, mi finibus
          iaculis vestibulum, velit arcu posuere dolor, quis tincidunt quam
          neque at elit. Sed fermentum a nulla quis iaculis. Etiam vitae
          pharetra turpis. Nullam consequat vitae velit id placerat. Donec vitae
          laoreet dolor, et elementum libero. Fusce vehicula ex vitae eros
          vulputate ultricies. Morbi pretium tincidunt commodo. Morbi eget lacus
          nunc. Quisque in egestas elit, eu vestibulum orci. Aenean in sapien
          elit. Curabitur quis arcu dui.
        </p>
      </LegalTemplate>
    </div>
  );
};

export default TermsAndConditions;
