import React, { useEffect } from "react";
import Hero from "../../components/Marketing/Hero/Hero";
import Nav from "../../components/Marketing/Nav/Nav";
import Problem from "../../components/Marketing/Problem/Problem";
import Packages from "../../components/Marketing/Packages/Packages";
import CallToAction from "../../components/Marketing/CallToAction/CallToAction";
import Services from "../../components/Marketing/Services/Services";
import FewWords from "../../components/Marketing/FewWords/FewWords";
import wavy from "../../images/Marketing/wavy-bg.svg";
import LMS from "../../components/Marketing/LMS/LMS";
import Download from "../../components/Marketing/Download/Download";
import Footer from "../../components/Marketing/Footer/Footer";
import { Helmet } from "react-helmet";
import Meta from "../../misc/Meta";
import LoadingScreen from "../../components/Marketing/LoadingScreen/LoadingScreen";
// import "../../styles/Marketing/style.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import ScrollBarColor from "../../misc/ScrollBarColor";

const meta = {
  title: "Wade Jr. Dental Marketing | Tampa Bay Dental Marketing",
  description:
    "Wade Jr. Dental Marketing is an all-in-one digital marketing service that offers custom built websites, SEO, Social Media Campaigns, and Advertising. Wade Jr. is actively look to help a dental office in the Tampa bay area.",
  url: "www.wadejr.com/marketing/"
};

const styles = {
  wavyDiv: {
    backgroundColor: "white",
    backgroundImage: `url(${wavy})`,
    height: "100%",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    zIndex: 11
  }
};
const hashCheck = hash => {
  switch(hash) {
    case '#about': 
      window.location.href = '/marketing#about'
    break;
    case '#pricing': 
      window.location.href = '/marketing#pricing'
    break;
    case '#services': 
      window.location.href = '/marketing#services'
    break;
    
    default:
      break;
  }
}

const LandingPage = () => {
  ScrollBarColor(
    "-webkit-linear-gradient(#2f55cc, #1de4be)",
    "#fff",
    null,
    "10px"
  );
  useEffect(() => {
    setTimeout(() => {
      hashCheck(window.location.hash)
    }, 5500)
  }, [])
  

  return (
    <div>
      <Helmet>
        <title>Wade Jr. | Marketing</title>

        <Meta
          title={meta.title}
          description={meta.description}
          url={meta.url}
        />
      </Helmet>
      <LoadingScreen>
        <Hero />
        <div style={{ height: "100vh", maxWidth: "100%", zIndex: -9 }} />
        <div style={{ zIndex: 10 }}>
          <Nav />
          <div style={{ position: "relative", backgroundColor: "white" }}>
            <Problem />
            <CallToAction />
            <Services />
            <div
              style={{ marginTop: "-75px", marginBottom: "75px" }}
              id="about"
            />
            <FewWords />
            <div style={styles.wavyDiv}>
              <LMS />
              <Packages />
            </div>
            <Download />
            <Footer />
          </div>
        </div>
      </LoadingScreen>
    </div>
  );
};

export default LandingPage;
