import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  FormControlLabel,
  Checkbox,
  InputLabel,
  InputAdornment,
  FormControl,
  IconButton,
  OutlinedInput
} from "@material-ui/core";
import ColorButton from "../../../misc/ColorButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import mainStyle from '../../../styles/LMS/mainStyle'
import { ClipLoader } from "react-spinners";
import { connect, useSelector } from "react-redux";
import { login, logout } from "../../../redux/LMS/actions/loginActions";
import qs from "query-string";
import Recaptcha from "../../../misc/Recaptcha";
import Swal from "sweetalert2";
import { eraseCookie } from "../../../misc/utility";
import { setClientCookie, ClientSession } from "../../../misc/Session";
import { Link } from "react-router-dom";

const styles = {
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
};

const Login = props => {
  const session = useContext(ClientSession);
  const cred = useSelector(state => state.login);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
    remember: false,
    password: "",
    showPassword: false
  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleChecked = () => {
    setValues({ ...values, remember: !values.remember });
  };
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  useEffect(() => {
    setLoading(cred.loading);
  }, [cred.loading]);


  const submit = e => {
    e.preventDefault();
    props.login(values.email, values.password, values.remember);
  };

  useEffect(() => {
    if (qs.parse(window.location.search).lo === "true") {
      props.logout();
    } else if (session) {
      props.history.push("/lms/dashboard");
    } else {
       props.logout()
    }
  }, [props]);

  useEffect(() => {
    if (cred.token) {
      setClientCookie(cred.token, values.remember).then(() => {
        window.location.href = "/lms/dashboard";
      });
    } else if (cred.status > 399) {
      Swal.fire({
        timer: 3000,
        timerProgressBar: true,
        title: "Error",
        text: cred.message,
        type: "error",
        toast: true,
        position: "top-end",
        showConfirmButton: false
      });
    }
  }, [cred.token, cred.status]);


  return (
    <div className="mt-3 mb-3 h-100">
      <Container>
        <form onSubmit={submit}>
          <div className="mb-3">
            <FormControl required variant="outlined" style={{ width: "100%" }}>
              <InputLabel required htmlFor="email">
                Email
              </InputLabel>
              <OutlinedInput
                id="email"
                type={"email"}
                value={values.email}
                onChange={handleChange("email")}
                onBlur={handleChange("email")}
                required
                labelWidth={60}
              />
            </FormControl>
          </div>
          <div className="mb-3">
            <FormControl required variant="outlined" style={{ width: "100%" }}>
              <InputLabel required htmlFor="password">
                Password
              </InputLabel>
              <OutlinedInput
                id="password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                onBlur={handleChange("password")}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={85}
              />
            </FormControl>
          </div>
          <div className="mb-3" style={styles.row}>
            <div style={{ marginTop: "10px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.remember}
                    onChange={handleChecked}
                    value={values.remember}
                    color="primary"
                  />
                }
                label="Remember Me"
              />
            </div>
            <div>
              <Link to="/lms/recover" style={{ color: "black" }}>
                Forgot Password?
              </Link>
            </div>
          </div>

          <div className="mb-3">
            <Recaptcha
              getSuccess={setSuccess}
              color={mainStyle.defaultColor}
            />
          </div>
          <div>
            <ColorButton
              contained
              noRadius
              disabled={!success || loading}
              textColor="white"
              color={mainStyle.buttonColor}
              hoverColor={mainStyle.hoverColor}
              type="submit"
              style={{
                ...mainStyle.bigButton,
                width: "100%"
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <span className={loading ? "mr-2" : ""}>Login</span>
                <ClipLoader
                  sizeUnit={"px"}
                  size={15}
                  color={"#fff"}
                  loading={loading}
                />
              </div>
            </ColorButton>
          </div>
        </form>
      </Container>
      {/* <ColorSnacks
        variant="error"
        vertical="bottom"
        horizontal="right"
        open={open}
        duration={6000}
        onClose={handleClose}
        message="This is an error message!"
      /> */}
    </div>
  );
};

const actions = {
  login,
  logout
};
export default connect(null, actions)(Login);
