import React from "react";
import Questions from "./Questions";
import { Container } from "@material-ui/core";

const GetStarted = () => {
  return (
    <div className='mb-5'>
      <Container fixed maxWidth={"md"}>
        <div className="mt-5 mb-5">
          <h2 className="text-center">
            Please fill out this quick questionnaire
          </h2>
        </div>
        <div>
          <Questions />
        </div>
      </Container>
    </div>
  );
};

export default GetStarted;
