import {
  GET_CLIENT,
  CLIENT_LOADING,
  UPDATE_CLIENT,
  ADD_CLIENT,
  DELETE_CLIENT,
  CLIENT_ERROR,
  CLIENT_ERROR_SENT,
  NEW_CLIENT_ADDED
} from "../types";

const initialState = {
  client: null,
//   {
//     admin_id: "",
//     _id: "",
//     firstName: "",
//     lastName: "",
//     email: "",
//     password: "",
//     address: "",
//     address2: "",
//     city: "",
//     state: "",
//     zip: "",
//     occupation: "",
//     phone: "",
//     confirmed: false,
//     newCustomers: 0,
//     status: 200,
//     message: ""
//   },
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENT:
      return {
        ...state,
        client: action.payload,
        loading: false
      };
    case UPDATE_CLIENT:
      return {
        ...state,
        client: action.payload,
        loading: false
      };

    case ADD_CLIENT:
      return {
        ...state,
        client: action.payload,
        status: action.status,
        loading: false
      };
    case NEW_CLIENT_ADDED:
      return {
        ...state,
        status: 200
      };

    case DELETE_CLIENT:
      return {
        ...state,
        client: action.payload,
        loading: false
      };
    case CLIENT_ERROR:
      return {
        ...state,
        status: action.status,
        message: action.message
      };

    case CLIENT_ERROR_SENT:
      return {
        ...state,
        status: 200
      };

    case CLIENT_LOADING:
      return {
        ...state,
        loading: true
      };

    default:
      return state;
  }
}
