import {
  LOGIN,
  LOGOUT,
  LOGIN_LOADING,
  RECAPTCHA,
  RECAPTCHA_LOADING,
  LOGIN_ERROR,
  RECOVER_PASSWORD,
  RESET_PASSWORD,
  RECOVER_ERROR,
  RESET_ERROR,
  REGISTER,
  REGISTER_ERROR
} from "../types";
import axios from "axios";
import { getConfig } from "../../reduxHelpers";

let config;

export const login = (email, password) => dispatch => {
  dispatch(setLoginLoading());
  let user = {
    email: email,
    password: password
  };

  axios
    .post("/api/credentials", user)
    .then(res => {
      dispatch({
        type: LOGIN,
        payload: res.data.token,
        message: res.data.message
      });
    })
    .catch(error => {
      dispatch({
        type: LOGIN_ERROR,
        message: error.response.data.message,
        status: error.response.status
      });
      console.log(error);
    });
};

export const register = client => dispatch => {
  dispatch(setLoginLoading());

  axios
    .post("/api/clients", client, config)
    .then(res => {
      dispatch({
        type: REGISTER,
        payload: res.data.data,
        status: res.data.status
      });
 
    })
    .catch(error => {
      dispatch({
        type: REGISTER_ERROR,
        status: error.response.status,
        message: error.response.data.message
      });
      console.log(error);
    });
};


export const logout = () => dispatch => {
  dispatch(setLoginLoading());
  new Promise(resolve => {
    resolve(dispatch({ type: LOGOUT }));
  }).then(() => {
    config = getConfig("logout");
  });
};

export const recaptcha = key => dispatch => {
  dispatch(setRecaptchaLoading());
  let params = {
    captcha: key
  };

  axios
    .get("/api/credentials", { params })
    .then(res => {
      dispatch({ type: RECAPTCHA, payload: res.data.success });
    })
    .catch(error => {
      console.log(error);
    });
};

export const recoverPassword = email => dispatch => {
  let body = {
    email: email
  };

  axios
    .post("/api/credentials/recover", body)
    .then(res => {
      dispatch({
        type: RECOVER_PASSWORD,
        message: res.data.message,
        status: res.data.status
      });
    })
    .catch(error => {
      console.log(error);
      dispatch({
        type: RECOVER_ERROR,
        message: error.response.data.message,
        status: error.response.status
      });
    });
};

export const resetPassword = (token, password) => dispatch => {
  let body = {
    token: token,
    password: password
  };

  axios
    .patch("/api/credentials/reset", body)
    .then(res => {
      dispatch({
        type: RESET_PASSWORD,
        message: res.data.message,
        status: res.data.status
      });
    })
    .catch(error => {
      console.log(error);
      dispatch({
        type: RESET_ERROR,
        message: error.response.data.message,
        status: error.response.status
      });
    });
};

export const setLoginLoading = () => ({ type: LOGIN_LOADING });
export const setRecaptchaLoading = () => ({ type: RECAPTCHA_LOADING });
