import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      <Box
        p={props.padding !== null ? props.padding : 3}
        style={props.padding === 0 ? { paddingTop: "15px" } : null}
      >
        {children}
      </Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

// function a11yProps(index) {
//   return {
//     id: `action-tab-${index}`,
//     "aria-controls": `action-tabpanel-${index}`
//   };
// }

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    minHeight: 200
  }
}));

export default function FloatingActionButtonZoom(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const CTabs = withStyles(theme => ({
    root: {
      color: props.color
    },
    indicator: {
      backgroundColor: props.color,
      color: props.color
    },

    "&$selected": {
      color: props.color,
      fontWeight: theme.typography.fontWeightMedium
    }
  }))(Tabs);

  const CTab = withStyles(theme => ({
    root: {
      color: props.color,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      "&:focus": {
        opacity: 1
      }
    },
    "&:hover": {
      color: props.color,
      opacity: 1
    },
    "&$selected": {
      color: props.color,
      fontWeight: theme.typography.fontWeightMedium
    },
    "&:focus": {
      color: props.color
    }
  }))(Tab);

  const handleChange = (event, newValue) => {
    if (newValue > props.pages.length - 1) {
      newValue = props.pages.length - 1;
    }
    setValue(newValue);
    props.getIndex(newValue);
  };

  const handleChangeIndex = index => {
    if (index > props.pages.length - 1) {
      index = props.pages.length - 1;
    }
    setValue(index);
    props.getIndex(index)
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          background: "transparent",
          boxShadow: "none",
          outline: "none"
        }}
      >
        <CTabs
          value={value}
          className={classes.indicator}
          onChange={handleChange}
          indicatorColor="primary"
          textColor={props.color || "primary"}
          variant={props.regular ? "" : "fullWidth"}
          aria-label="action tabs"
          style={{ ...props.tabStyle }}
        >
          {props.pages.map((page, idx) => (
            <CTab
              style={{ outline: "none" }}
              label={page.title}
              // {...a11yProps(idx)}
            />
          ))}
        </CTabs>
        <Divider style={{ width: "100%" }} />
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {props.pages.map((page, idx) => (
          <TabPanel
            padding={props.padding}
            value={value}
            index={idx}
            dir={theme.direction}
          >
            {page.context}
          </TabPanel>
        ))}
      </SwipeableViews>
    </div>
  );
}

FloatingActionButtonZoom.defaultProps = {
  getIndex: (i) => {

  }
}