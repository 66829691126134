import React from "react";
import Header from "../../components/Marketing/Header/Header";
import ContactForm from "../../components/Marketing/Contact/Contact";
import Footer from "../../components/Marketing/Footer/Footer";
import Nav from "../../components/Marketing/Nav/Nav";
import { Helmet } from "react-helmet";
import Meta from "../../misc/Meta";
import ScrollBarColor from "../../misc/ScrollBarColor";
import ScrollToTop from "../../misc/ScrollToTop";

const meta = {
  title: "Wade Jr. Contact for Dental Marketing |  Dental Marketing Tampa, FL",
  description:
    "Feel free to contact me with any questions or concerns regarding my dental marketing services. Wade JR. is actively look to help a dental office in the Tampa bay area. ",
  url: "www.wadejr.com/marketing/contact"
};

const Contact = () => {
  ScrollBarColor(
    "-webkit-linear-gradient(#2f55cc, #1de4be)",
    "#fff",
    null,
    "10px"
  );
  return (
    <div>
      <Helmet>
        <title>Wade Jr. | Contact</title>
        <Meta
          title={meta.title}
          description={meta.description}
          url={meta.url}
        />
      </Helmet>
      <ScrollToTop>
        <Nav />
        <Header title="Contact" />
        <ContactForm />
        <Footer />
      </ScrollToTop>
    </div>
  );
};

export default Contact;
