import React from "react";
import PropTypes from "prop-types";
import IconBackground from './IconBackground'
import "./style.css";

const styles = {
  content: {
    position: "relative",
    zIndex: 1,
    borderRadius: "5px",
    border: "1px #555",
    //boxShadow: '0 0 30px rgba(0, 0, 0, 0.6)',
    boxShadow:
      "0 0 20px rgba(0, 0, 0, 0.2), 20px 20px 20px 20px rgba(0, 0, 0, 0.19)",
    backgroundColor: "white",
    height: "100%"
  },
  image: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};

const ServiceIcon = props => {
  return (
    <div id="service" style={{ height: "100%", width: '100%' }}>
      <div style={styles.icon}> 
        <IconBackground
          icon={props.icon}
          background={props.iconBackgroundColor}
          shape="bubble"
          iconStyle={props.iconStyle}
        />
      </div>
      <div>
        <h2 className='text-center' style={{ fontSize: "22px" }}>{props.title}</h2>
      </div>
      <div>
        <p className='text-center' >{props.content}</p>
      </div>
    </div>
  );
};
ServiceIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  iconStyle: PropTypes.object,
  iconTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  iconBackgroundColor: PropTypes.string
};

export default ServiceIcon;
