import React from "react";
import { Divider } from "@material-ui/core";
import mainStyle from "../../styles/Personal/mainStyle";

const Title = props => {
  return (
    <div
      className={props.className}
      style={{
        maxWidth: "100%",
        display: "flex",
        alignItems: "center",
        ...props.style
      }}
    >
      {" "}
      <div>
        <h2>{props.children}</h2>
      </div>
      <div style={{width: '100%'}}>
        <Divider
          style={{
            maxWidth: "300px",
            width: "100%",
            height: "2px",
            backgroundColor: mainStyle.defaultColor,
            marginTop: "-5px",
            marginLeft: "5px"
          }}
        />
      </div>
    </div>
  );
};

export default Title;
