import React from "react";
import {
  Container,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import mainStyle from "../../../styles/Marketing/mainStyle";
import {Link} from 'react-router-dom'

const questions = [
  {
    question: "Do you only work with Dentists?",
    answer:
      "Dentistry is our main focus, we're also open to marketing for Chiropractors and other Doctors."
  },
  {
    question: "What platforms do you advertise on?",
    answer:
      "We advertise on Facebook, Instagram, and Google."
  },
  {
    question: "Can I customize my own package?",
    answer:
      "Yes! You can customize your own package by contacting us and we'll give you a quote."
  },
  {
    question:
      "Can I have photography sessions without the subscriptional plan?",
    answer:
      "Yes, we have a photographer who charges hourly for whatever you may need. Contact us to get your quote!"
  },
  {
    question: "Do you only work with businesses inside Tampa?",
    answer:
      "No, we am open to any remote location. Although, photography sessions are restricted to 2 hours outside of Tampa."
  },
  {
    question: "Can I cancel my subscription at any time?",
    answer:
      "Yes, you are not bound by a contract and you can cancel whenever you like."
  },
  {
    question: "Do you offer a money back guarantee?",
    answer:
      "Yes, if you are not satisfied with our services in the first 30 days, we will give you your money back."
  }
];

const FAQ = () => {
  return (
    <div style={{ ...mainStyle.section }}>
      <Container fixed>
        {questions.map((faq, idx) => (
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h3>{faq.question}</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <p>{faq.answer}</p>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
        <div className="mt-5 mb-2">
          <h2>Still not finding what you need?</h2>
          <p>
            Contact us at{" "}
            <a
              href="mailto:wade@wadejr.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              wade@wadejr.com
            </a>{" "}
            or leave a message on the <Link to="/marketing/contact" title='Marketing Contact Page'>Contact</Link>{" "}
            page.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default FAQ;
