import React from "react";
import SlidingButtonGroup from "./SlidingButtonGroup";
import mainStyle from "../../../styles/LMS/mainStyle";
import logo from "../../../images/LMS/LMS_Logo.png";
import "./style.css";

const styles = {
  title: {
    marginTop: "20px",
    marginBottom: "55px"
  }
};

const Form = props => {
  return (
    <div>
      <div id="image" />
      <div id="form">
        <div style={{ ...styles.title }}>
          <div className="text-center">
            <img src={logo} height={"100px"} width={"100px"} />
          </div>
          <h2 className="text-center px-3" style={{ ...mainStyle.header }}>
            Lead Management System
          </h2>
        </div>
        <SlidingButtonGroup {...props} />
      </div>
    </div>
  );
};

export default Form;
