import React from "react";
import PropTypes from "prop-types";
import { Paper, Chip } from "@material-ui/core";
import { ReactComponent as Web } from "../../../images/Personal/web.svg";
import "./style.css";
import mainStyle from "../../../styles/Personal/mainStyle";

const styles = {
  project: {
    borderRadius: "5px",
    boxShadow: "0px 5px 5px 0 rgba(0,0,0,0.2)"
  }
};

const Project = props => {
  let place = props.place,
    title = props.title,
    description = props.description,
    languages = props.languages;

  return (
    <div style={{maxWidth: '100%'}}>
      <Paper
        className="p-3 project"
        style={{ height: "430px", ...styles.project }}
      >
        <div className="mb-5">
          <Web
            fill={mainStyle.defaultColor}
            style={{ height: "60px", width: "60px", marginLeft: '-8px'}}
          />
        </div>
        <div>
          <h3>{title}</h3>
        </div>
        <div style={{marginTop: '-5px'}} className="mb-3 text-muted">
          <h4>{place}</h4>
        </div>
        <div style={{ height: "160px" }}>
          <p>{description}</p>
        </div>
        <div>
          {languages.map(language => (
            <Chip className="m-1" label={language} />
          ))}
        </div>
      </Paper>
    </div>
  );
};

Project.propTypes = {
  place: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  color: PropTypes.string
};

export default Project;
