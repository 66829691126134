import React from "react";
import mainStyle from "../../../styles/Personal/mainStyle";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import { getYear } from "../../../misc/utility";
import "./style.css";

const styles = {
  root: {
    backgroundColor: mainStyle.secondaryColor,
    height: "125px",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  link: {
    color: 'white',
    marginRight: '3px',
    marginLeft: '3px'
  }
};

const links = [
  {
    icon: <LinkedInIcon height={'25px'} width={'25px'}/>,
    href: "https://www.linkedin.com/in/theengineerwadejr/"
  },
  {
    icon: <GitHubIcon height={'25px'} width={'25px'}/>,
    href: "https://www.github.com/wadejr"
  },
  {
    icon: <InstagramIcon height={'25px'} width={'25px'}/>,
    href: "https://www.instagram.com/eng_wadejr"
  },
  {
    icon: <FacebookIcon height={'25px'} width={'25px'}/>,
    href: "https://www.facebook.com/wade.smith.9083"
  }
];

const Footer = () => {
  return (
    <footer>
      <div style={styles.root}>
        <div>
          <p className='text-center mt-0 mb-1'>Designed & Built by Wade Jr. 😄</p>
          <p className='text-center mt-0 mb-1'>
            {links.map(link => (
              <a id='link' key={link.icon} style={styles.link} href={link.href} target="_blank" rel="noopener noreferrer">
                {link.icon}
              </a>
            ))}
          </p>
          <p className="text-center mb-0">© {getYear()}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
