import React, { useEffect, useState } from "react";
import PageTitle from "../../../misc/LMS/PageTitle";
import {
  Paper,
  OutlinedInput,
  InputLabel,
  FormControl,
  Grid,
  Select
} from "@material-ui/core";
import PhoneNumberTextbox from "../../../misc/PhoneNumberTextbox";
import CardTitle from "../../../misc/LMS/CardTitle";
import ColorButton from "../../../misc/ColorButton";
import mainStyle from "../../../styles/LMS/mainStyle";

const styles = {};

const statesArray = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Island",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];

const Profile = props => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    password2: "",
    showPassword: false,
    showPassword2: false,
    firstName: "",
    lastName: "",
    phone: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    adminId: ""
  });

  const [loading, setLoading] = useState(false);

  const handleChange = prop => event => {
    setValues({
      ...values,
      [prop]: event.target.value
    });
  };
  return (
    <div>
      <PageTitle title="Profile" />
      <div
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Paper elevation={5}>
          <CardTitle title="User Information" />
          <Grid container spacing={3} className="p-3">
            <Grid item xs={12} md={6}>
              <FormControl
                required
                variant="outlined"
                style={{
                  width: "100%"
                }}
              >
                <InputLabel required htmlFor="firstName">
                  First Name
                </InputLabel>
                <OutlinedInput
                  id="firstName"
                  type={"text"}
                  value={values.firstName}
                  onChange={handleChange("firstName")}
                  required
                  labelWidth={100}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                required
                variant="outlined"
                style={{
                  width: "100%"
                }}
              >
                <InputLabel required htmlFor="lastName">
                  Last Name
                </InputLabel>
                <OutlinedInput
                  id="lastName"
                  type={"text"}
                  value={values.lastName}
                  onChange={handleChange("lastName")}
                  required
                  labelWidth={100}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                required
                variant="outlined"
                style={{
                  width: "100%"
                }}
              >
                <InputLabel required htmlFor="email">
                  Email
                </InputLabel>
                <OutlinedInput
                  id="email"
                  type={"email"}
                  value={values.email}
                  onChange={handleChange("email")}
                  required
                  labelWidth={60}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <PhoneNumberTextbox
                value={values.phone}
                onChange={handleChange("phone")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                required
                variant="outlined"
                style={{
                  width: "100%"
                }}
              >
                <InputLabel required htmlFor="address">
                  Address
                </InputLabel>
                <OutlinedInput
                  id="address"
                  type={"text"}
                  value={values.address}
                  onChange={handleChange("address")}
                  required
                  labelWidth={100}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                variant="outlined"
                style={{
                  width: "100%"
                }}
              >
                <InputLabel htmlFor="address2">Address 2</InputLabel>
                <OutlinedInput
                  id="address2"
                  type={"text"}
                  value={values.address2}
                  onChange={handleChange("address2")}
                  required
                  labelWidth={100}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                required
                variant="outlined"
                style={{
                  width: "100%"
                }}
              >
                <InputLabel required htmlFor="city">
                  City
                </InputLabel>
                <OutlinedInput
                  id="city"
                  type={"text"}
                  value={values.city}
                  onChange={handleChange("city")}
                  required
                  labelWidth={100}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                required
                variant="outlined"
                style={{
                  width: "100%"
                }}
              >
                <InputLabel required htmlFor="state">
                  State
                </InputLabel>
                <Select
                  id="state"
                  name="state"
                  value={values.state}
                  native
                  onChange={handleChange("state")}
                  required
                  labelWidth={100}
                >
                  {statesArray.map((state, idx) => (
                    <option key={idx}>{state}</option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                required
                variant="outlined"
                style={{
                  width: "100%"
                }}
              >
                <InputLabel required htmlFor="zip">
                  Zip
                </InputLabel>
                <OutlinedInput
                  id="zip"
                  type={"text"}
                  value={values.zip}
                  onChange={handleChange("zip")}
                  required
                  labelWidth={100}
                />
              </FormControl>
            </Grid>
          </Grid>
          <ColorButton
            className="mb-3 ml-3"
            contained
            noRadius
            // disabled={loading}
            textColor="white"
            color={mainStyle.buttonColor}
            hoverColor={mainStyle.hoverColor}
            type="submit"
            style={{
              ...mainStyle.button,
              fontSize: "18px",
              padding: "10px 24px"
            }}
          >
            Save
          </ColorButton>
        </Paper>
      </div>
    </div>
  );
};

export default Profile;
