import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { ClipLoader } from "react-spinners";
import ColorButton from "../../../misc/ColorButton";
import mainStyle from '../../../styles/LMS/mainStyle'
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { recoverPassword } from "../../../redux/LMS/actions/loginActions";
import Swal from "sweetalert2";
import Recaptcha from "../../../misc/Recaptcha";

const Recover = props => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [recaptchaLoading, setRecaptchaLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const login = useSelector(state => state.login);
  const [response, setResponse] = useState(false);

  const submit = e => {
    e.preventDefault();
    new Promise(resolve => {
      resolve(setResponse(true));
    }).then(() => {
      props.recoverPassword(email);
    });
  };

  useEffect(() => {
    if (response && login.status === 200) {
      Swal.fire({
        title: "Message Sent",
        text: login.message,
        type: "success",
        showConfirmButton: true
      }).then(() => {
        setResponse(false);
      });
    } else if (response && login.status > 399) {
      Swal.fire({
        timer: 5000,
        title: "Error",
        text: login.message,
        type: "error",
        showConfirmButton: true
      }).then(() => {
        setResponse(false);
      });
    }
  }, [login.status]);

  useEffect(() => {
    setLoading(login.loading);
  }, [login.loading]);

  return (
    <div>
      <div>
        <h2
          className="mb-5 text-center"
          style={{ ...mainStyle.subHeader }}
        >
          Forgot Password
        </h2>
      </div>
      <div>
        <p className='text-center' style={{ ...mainStyle.text }}>
          Please enter the email associated with your account.
        </p>
      </div>

      <form onSubmit={submit}>
        <div className="mb-3">
          <FormControl required variant="outlined" style={{ width: "100%" }}>
            <InputLabel required htmlFor="email">
              Email
            </InputLabel>
            <OutlinedInput
              id="email"
              type={"email"}
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
              labelWidth={60}
            />
          </FormControl>
        </div>

        <div className="mb-1">
          <Recaptcha
            getSuccess={setSuccess}
            getLoading={setRecaptchaLoading}
            color={mainStyle.defaultColor}
          />
        </div>
        <div className="mb-3">
          <ColorButton
            contained
            noRadius
            disabled={!success || loading}
            textColor="white"
            color={mainStyle.buttonColor}
            hoverColor={mainStyle.hoverColor}
            type="submit"
            style={{
              ...mainStyle.bigButton,
              width: "100%"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <span className={loading ? "mr-2" : ""}>Submit</span>
              <ClipLoader
                sizeUnit={"px"}
                size={15}
                color={"#fff"}
                loading={loading}
              />
            </div>
          </ColorButton>
        </div>
        <Link to="/lms">Back to login</Link>
      </form>
    </div>
  );
};

const actions = {
  recoverPassword
};
export default connect(null, actions)(Recover);
