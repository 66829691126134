import {
    GET_EMAIL_TEMPLATES,
    GET_RESPONSE_TEMPLATES,
    ADD_EMAIL_TEMPLATE,
    ADD_RESPONSE_TEMPLATE,
    UPDATE_EMAIL_TEMPLATE,
    UPDATE_RESPONSE_TEMPLATE,
    DELETE_EMAIL_TEMPLATE,
    DELETE_RESPONSE_TEMPLATE,
    EMAIL_TEMPLATES_LOADING,
    RESPONSE_TEMPLATES_LOADING
} from '../types'


const initialState = {
   emailTemplates: [],
   responseTemplates: [],
   emailTemplatesLoading: false,
   responseTemplatesLoading: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_EMAIL_TEMPLATES:
            return {
                ...state,
                emailTemplates: action.payload,
                emailTemplatesLoading: false
            }

        case ADD_EMAIL_TEMPLATE:
            return {
                ...state,
                emailTemplates: action.payload,
                emailTemplatesLoading: false
            }

        case UPDATE_EMAIL_TEMPLATE:
            return {
                ...state,
                emailTemplates: action.payload,
                emailTemplatesLoading: false
            }

        case DELETE_EMAIL_TEMPLATE:
            return {
                ...state,
                emailTemplates: action.payload,
                emailTemplatesLoading: false
            }

        case GET_RESPONSE_TEMPLATES:
            return {
                ...state,
                responseTemplates: action.payload,
                responseTemplatesLoading: false
            }    

        case ADD_RESPONSE_TEMPLATE:
            return {
                ...state,
                responseTemplates: action.payload,
                responseTemplatesLoading: false
            }

        case UPDATE_RESPONSE_TEMPLATE:
            return {
                ...state,
                responseTemplates: action.payload,
                responseTemplatesLoading: false
            } 

        case DELETE_RESPONSE_TEMPLATE:
            return {
                ...state,
                responseTemplates: action.payload,
                responseTemplatesLoading: false
            } 

        case EMAIL_TEMPLATES_LOADING:
            return {
                ...state,
                emailTemplatesLoading: true
            }

        case RESPONSE_TEMPLATES_LOADING:
            return {
                ...state,
                responseTemplatesLoading: true
            }

        default:
            return state
    }
}