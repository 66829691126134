import {
  GET_CLIENT,
  CLIENT_LOADING,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  CLIENT_ERROR
} from "../types";
import axios from "axios";
import { getConfig } from "../../reduxHelpers";

let config;

export const getClient = () => dispatch => {
  config = getConfig("client");
  dispatch(setClientLoading());

  axios
    .get("/api/clients/getClient", config)
    .then(res => {
      dispatch({ type: GET_CLIENT, payload: res.data.userData });
    })
    .catch(error => {
      dispatch({
        type: CLIENT_ERROR,
        message: error.response.data.message,
        status: error.response.status
      });
      console.log(error);
    });
};

export const updateClient = client => dispatch => {
  config = getConfig("client");
  dispatch(setClientLoading());

  axios
    .patch("/api/clients", client, config)
    .then(res => {
      dispatch({ type: UPDATE_CLIENT, payload: res.data.client });
    })
    .catch(error => {
      dispatch({
        type: CLIENT_ERROR,
        message: error.response.data.message,
        status: error.response.status
      });
      console.log(error);
    });
};

export const deleteClient = client_id => dispatch => {
  config = getConfig("admin");
  dispatch(setClientLoading());

  axios
    .delete("/api/clients?_id=" + client_id, config)
    .then(res => {
      dispatch({ type: DELETE_CLIENT, payload: res.data.client });
    })
    .catch(error => {
      dispatch({
        type: CLIENT_ERROR,
        message: error.response.data.message,
        status: error.response.status
      });
      console.log(error);
    });
};

export const setClientLoading = () => ({ type: CLIENT_LOADING });
