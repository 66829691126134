import {
  GET_ADMIN,
  GET_ALL_CLIENTS,
  ADD_ADMIN,
  UPDATE_ADMIN,
  DELETE_ADMIN,
  ADMIN_LOADING,
  CLIENTS_LOADING,
  ADMIN_ERROR,
  ADMIN_ERROR_SENT,
  NEW_ADMIN_ADDED
} from "../types";
import axios from "axios";
import { getConfig } from "../../reduxHelpers";

let config;

export const getAdmin = () => dispatch => {
  config = getConfig("admin");
  dispatch(setAdminLoading());

  axios
    .get("/api/admins/myself", config)
    .then(res => {
      dispatch({
        type: GET_ADMIN,
        admin: res.data.admin
      });
    })
    .catch(error => {
      console.log(error);
    });
};

export const getAllClients = () => dispatch => {
  config = getConfig("admin");
  dispatch(setClientsLoading());

  axios
    .get("/api/clients", config)
    .then(res => {
      dispatch({ type: GET_ALL_CLIENTS, payload: res.data.clients });
    })
    .catch(error => {
      console.log(error);
    });
};

export const addAdmin = admin => dispatch => {
  config = getConfig("super");
  dispatch(setAdminLoading());

  axios
    .post("/api/admins", admin, config)
    .then(res => {
      dispatch({
        type: ADD_ADMIN,
        payload: res.data.admin,
        status: res.data.status
      });
      dispatch({ type: NEW_ADMIN_ADDED });
    })
    .catch(error => {
      dispatch({
        type: ADMIN_ERROR,
        status: error.response.status,
        message: error.response.data.message
      });
      dispatch({ type: ADMIN_ERROR_SENT });
      console.log(error);
    });
};

export const updateAdmin = admin => dispatch => {
  config = getConfig("admin");
  dispatch(setAdminLoading());

  axios
    .patch("/api/admins", admin, config)
    .then(res => {
      dispatch({ type: UPDATE_ADMIN, payload: res.data.admin });
    })
    .catch(error => {
      console.log(error);
    });
};

export const deleteAdmin = admin_id => dispatch => {
  config = getConfig("super");
  dispatch(setAdminLoading());

  axios
    .delete("/api/admins", admin_id, config)
    .then(res => {
      dispatch({ type: DELETE_ADMIN, payload: res.data.admin });
    })
    .catch(error => {
      console.log(error);
    });
};

export const setAdminLoading = () => ({ type: ADMIN_LOADING });
export const setClientsLoading = () => ({ type: CLIENTS_LOADING });
