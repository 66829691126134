import React from "react";
import { Grid, Container } from "@material-ui/core";
import Package from "./Package";
import Investment from "./Investment";

const package1 = {
  title: "Setup",
  price: "600",
  features: [
    "Facebook Page Setup",
    "Instagram Page Setup",
    "Google Business Page Setup",
    "Yelp Page Setup",
    "Complementary Logo or Photography Session"
  ]
};
const package2 = {
  title: "Gold",
  price: "1,297",
  features: [
    "Setup Package",
    "Facebook & Instagram Advertising",
    "Google Advertsing & Analytics",
    "Landing Page",
    "SEO",
    "Photography / Videography Session",
    <b>Lead Management System</b>,
    "Logo Design"
  ]
};

const package3 = {
  title: "Diamond",
  price: "2,000",
  features: [
    "Gold Package",
    "Social Media Posts",
    "Website Development and Maintenance (5 pages)",
    "Blog Posts",
    "Newsletters"
  ]
};

const Packages = props => {
  return (
    <div id="pricing">
      <Container id="section" fixed>
        <div className="pb-5 mt-5" style={{ marginBottom: "" }}>
          <Investment />
        </div>

        <div className="mb-5">
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-end"
            spacing={2}
          >
            <Grid item xs={12} md={4}>
              <Package package={package1} flatRate query="p1" />
            </Grid>
            <Grid item xs={12} md={4}>
              <Package recommended package={package2} query="p2" />
            </Grid>
            <Grid item xs={12} md={4}>
              <Package package={package3} query="p3" />
            </Grid>
          </Grid>
        </div>
        <div className="text-center mb-5">
          <p>
            <i>Risk free 30-day money back guarantee</i>
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Packages;

/* <div className="text-center mb-5">
          <h2 style={{ ...props.mainStyle.subHeader }}>Our Pricing</h2>
          <p style={{ ...props.mainStyle.text }}>
            <i>Risk free 30-day money back guarantee</i>
          </p>
        </div> */
