import React from "react";
import { getCookie, eraseCookie, setCookie } from "./utility";

export const AdminSession = React.createContext(null);
export const ClientSession = React.createContext(null);

export const getClientSessionCookie = () => {
  return {
    token: getCookie("clientToken"),
    logout: () => {
      eraseCookie("clientToken");
    }
  };
};

export const getAdminSessionCookie = () => {
  return {
    token: getCookie("adminToken"),
    logout: () => {
      eraseCookie("adminToken");
    }
  };
};

export const setAdminCookie = (token, remember) =>
  new Promise(resolve => {
    resolve(setCookie("adminToken", token, remember ? 7 : 0.5));
  });

export const setClientCookie = token =>
  new Promise(resolve => {
    resolve(setCookie("clientToken", token, 365));
  });


export default ClientSession;
