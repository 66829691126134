import React from "react";
import PropTypes from "prop-types";
import Page1 from './Page1'
import Page2 from './Page2'
import Page3 from './Page3'

const Page = props => {
  const pageNumber = props.pageNumber;
  const info = props.info;
  const setInfo = props.setInfo;
  const requiredDone = props.requiredDone;
  const setRequiredDone = props.setRequiredDone;

  let CurrentPage;
  switch (pageNumber) {
    case 0:
      CurrentPage = (
        <Page1
          info={info}
          setInfo={setInfo}
          requiredDone={requiredDone}
          setRequiredDone={setRequiredDone}
        />
      );
      break;
    case 1:
      CurrentPage = (
        <Page2
          info={info}
          setInfo={setInfo}
          requiredDone={requiredDone}
          setRequiredDone={setRequiredDone}
        />
      );
      break;
    case 2:
      CurrentPage = (
        <Page3
          info={info}
          setInfo={setInfo}
          requiredDone={requiredDone}
          setRequiredDone={setRequiredDone}
        />
      );
      break;
    default:
      break;
  }
  return <div>{CurrentPage}</div>;
};

Page.propTypes = {
  info: PropTypes.string.isRequired,
  setInfo: PropTypes.func.isRequired,
  requiredDone: PropTypes.bool.isRequired,
  setRequiredDone: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired
};

export default Page;
