import React from "react";
import {
  InputLabel,
  FormControl,
  OutlinedInput
} from "@material-ui/core";
import NumberFormat from "react-number-format";

const phone = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      placeholder="(___) ___-____"
      format="(###) ###-####"
      mask="_"
    />
  );
};

const PhoneNumberTextbox = props => {
  return (
    <div className={props.className} style={props.style} key="work">
      <FormControl
        required
        variant="outlined"
        style={{ width: "100%" }}
        key="worfk"
      >
        <InputLabel required htmlFor="phone" key="worak">
          Phone
        </InputLabel>
        <OutlinedInput
          key="worqqk"
          id="phone"
          value={props.value}
          onChange={props.onChange}
          required
          labelWidth={100}
          inputComponent={phone}
        />
      </FormControl>
    </div>
  );
};

export default PhoneNumberTextbox;
