import React from "react";
import Routes from "./router";
import { ParallaxProvider } from "react-scroll-parallax";
import { Provider } from "react-redux";
import store from "./store";
import "./styles/App.css";

function App() {
  return (
    <Provider store={store}>
      <ParallaxProvider>
        <Routes />
      </ParallaxProvider>
    </Provider>
  );
}

export default App;
