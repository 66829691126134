import React, { useState, useEffect } from "react";
import Recover from "./Recover";
import Reset from "./Reset";
import mainStyle from '../../../styles/LMS/mainStyle'
import "./style.css";
import qs from "query-string";
import { Container } from "@material-ui/core";
import logo from "../../../images/LMS/LMS_Logo.png";


const styles = {
    title: {
        marginTop: '20px', 
        marginBottom: '55px'
    }
} 

const RecoverPage = () => {
  const [form, setForm] = useState("recover");
  const token = qs.parse(window.location.search).t;

  useEffect(() => {
    if (token) {
      setForm("reset");
    }
  }, [token]);

  return (
    <div>
      <div id="image" />
      <div id="form">
      <div style={{ ...styles.title }}>
          <div className="text-center">
            <img src={logo} height={"100px"} width={"100px"} />
          </div>
          <h2 className="text-center px-3" style={{ ...mainStyle.header }}>
            Lead Management System
          </h2>
        </div>
        <Container fixed maxWidth="sm">
        {form === "reset" ? <Reset token={token} /> : <Recover />}
        </Container>
      </div>
    </div>
  );
};

export default RecoverPage;
