import React, { useEffect, useState } from "react";
import { Paper, List, Divider } from "@material-ui/core";
import CardTitle from "../../../misc/LMS/CardTitle";
import { Link } from "react-router-dom";
import mainStyle from "../../../styles/LMS/mainStyle";
import { ShowHide } from "../../../misc/utility";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const styles = {};

const leads = [
  {
    _id: "1",
    firstName: "Wade",
    lastName: "Smith",
    date: "6/27/2019 at 9:30am",
    campaign: "First Campaign",
    phone: "+7777777777",
    email: "wadejr@wadejr.com"
  },
  {
    _id: "1",
    firstName: "Wade",
    lastName: "Smith",
    date: "6/27/2019 at 9:30am",
    campaign: "First Campaign",
    phone: "+7777777777",
    email: "wadejr@wadejr.com"
  },
  {
    _id: "1",
    firstName: "Wade",
    lastName: "Smith",
    date: "6/27/2019 at 9:30am",
    campaign: "First Campaign",
    phone: "+7777777777",
    email: "wadejr@wadejr.com"
  },
  {
    _id: "1",
    firstName: "Wade",
    lastName: "Smith",
    date: "6/27/2019 at 9:30am",
    campaign: "First Campaign",
    phone: "+7777777777",
    email: "wadejr@wadejr.com"
  },
  {
    _id: "1",
    firstName: "Wade",
    lastName: "Smith",
    date: "6/27/2019 at 9:30am",
    campaign: "First Campaign",
    phone: "+7777777777",
    email: "wadejr@wadejr.com"
  },
  {
    _id: "1",
    firstName: "Wade",
    lastName: "Smith",
    date: "6/27/2019 at 9:30am",
    campaign: "First Campaign",
    phone: "+7777777777",
    email: "wadejr@wadejr.com"
  },
  {
    _id: "1",
    firstName: "Wade",
    lastName: "Smith",
    date: "6/27/2019 at 9:30am",
    campaign: "First Campaign",
    phone: "+7777777777",
    email: "wadejr@wadejr.com"
  },
  {
    _id: "1",
    firstName: "Wade",
    lastName: "Smith",
    date: "6/27/2019 at 9:30am",
    campaign: "First Campaign",
    phone: "+7777777777",
    email: "wadejr@wadejr.com"
  }
];

const Lead = ({ lead }) => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ ...mainStyle.text, fontSize: "20px", maxWidth: "50%" }}>
          <b>{lead.firstName + " " + lead.lastName}</b>
        </div>
        <div style={{ ...mainStyle.text, maxWidth: "50%", textAlign: "right" }}>
          {lead.date}
        </div>
      </div>
      <div>
        <a style={{ ...mainStyle.text }} href={"tel://" + lead.phone}>
          {lead.phone}
        </a>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ ...mainStyle.text }}>{lead.email}</div>
        <div>
          <Link
            to={"/lms/lead?id=" + lead._id}
            style={{
              ...mainStyle.text,
              color: mainStyle.defaultColor,
              display: "flex",
              alignItems: "center"
            }}
          >
            More <ArrowForwardIcon />
          </Link>
        </div>
      </div>
    </div>
  );
};

const OldLeads = props => {
  const LeadsList = () => (
    <List>
      {leads.map((lead, idx) => (
        <div>
          <div className="p-3">
            <Lead lead={lead} />
          </div>
          <ShowHide show={idx !== leads.length - 1}>
            <Divider variant="fullWidth" />
          </ShowHide>
        </div>
      ))}
    </List>
  );

  return (
    <div>
      <Paper elevation={5}>
        <CardTitle title="Leads" search />
        <LeadsList />
      </Paper>
    </div>
  );
};

export default OldLeads;
