import {GET_FB_LEADS, FB_LEADS_LOADING, GET_FB_FORM_IDS, ADD_FB_FORM_ID, UPDATE_FB_FORM_ID, DELETE_FB_FORM_ID, FB_IDS_LOADING} from '../types'

const initialState = {
    leads: [],
    leadAds:[],
    leadsLoading: false,
    fbIdsLoading: false

}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_FB_LEADS:
            return {
                ...state,
                leads: action.payload,
                leadsLoading: false
            }
        case GET_FB_FORM_IDS:
                return {
                    ...state,
                    leadAds: action.payload,
                    fbIdsLoading: false
                }
        case ADD_FB_FORM_ID:
                return {
                    ...state,
                    leadAds: action.payload,
                    fbIdsLoading: false
                }
        case UPDATE_FB_FORM_ID:
                return {
                    ...state,
                    leadAds: action.payload,
                    fbIdsLoading: false
                }
        case DELETE_FB_FORM_ID:
                return {
                    ...state,
                    leadAds: action.payload,
                    fbIdsLoading: false
                }

        case FB_LEADS_LOADING:
            return {
                ...state,
                leadsLoading: true
            }

        case FB_IDS_LOADING:
                return {
                    ...state,
                    fbIdsLoading: true
                }


        default:
            return state
    }
}
