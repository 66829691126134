import React from "react";
import { Grid, Container } from "@material-ui/core";
import image1 from "../../../images/Personal/wadeAndMakayla.JPG";
import image1Lazy from "../../../images/Personal/wadeAndMakayla-min.JPG";
import "./style.css";
import Title from "../../../misc/Personal/Title";
import mainStyle from "../../../styles/Personal/mainStyle";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";
const styles = {
  center: {
    // display: 'inline-block',
  }
};

const About = () => {
  return (
    <div id="about" className="section">
      <Container fixed style={{ height: "100%" }}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item md={12} lg={6}>
            <div>
              <div style={styles.center}>
                <Title>About</Title>
                <p>
                  <i>Wade A. Smith Jr.</i>
                </p>
              </div>
              <div style={styles.center}>
                <p style={{ maxWidth: "450px" }}>
                  <span>
                    Hello! I am an entrepreneur and software engineer. I'm currently working remote for 
                    GoDaddy as a Senior Software Engineer II. I have
                    a deep passion for learning, as well as solving problems. 
                    My professional interests are in full-stack
                    development,&nbsp;
                  </span>
                  <a
                    href="https://www.dopanutrition.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Ecommerce"
                    style={{ color: "black" }}
                  >
                    <u>ecommerce</u>
                  </a>
                  <span>,&nbsp;</span>
                  <Link
                    to="/marketing"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Wade Jr. Dental Marketing"
                    style={{ color: "black" }}
                  >
                    <u>marketing</u>
                  </Link>
                  <span>
                    , real estate, and stocks. If you're looking to
                    collaborate with a hard-working visionary,&nbsp;
                  </span>
                  <a
                    href="mailto:wadealsmith@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: mainStyle.defaultColor }}
                  >
                    <b>let's chat.</b>
                  </a>
                  <span>
                    &nbsp;On a more personal note, my hobbies consist of playing
                    basketball, spending time with family and friends, and
                    snorkeling. I am married to my best friend, Makayla, who is
                    currently attending Medical School.
                  </span>
                </p>
              </div>
            </div>
          </Grid>
          <Grid item md={12} lg={6}>
            <LazyLoad
              once
              height={500}
              offset={200}
              placeholder={
                <div style={{ backgroundColor: "" }}>
                  <img
                    alt={"Wade and Makayla in Downtown Tampa"}
                    src={image1Lazy}
                    title={"Wade and Makayla in Tampa, Fl"}
                    className="image"
                  />
                </div>
              }
            >
              <div style={{ backgroundColor: "" }}>
                <img
                  alt={"Wade and Makayla in Downtown Tampa"}
                  className="image"
                  src={image1}
                  title={"Wade and Makayla in Tampa, Fl"}
                />
              </div>
            </LazyLoad>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default About;
