import React from "react";
import { TextField } from "@material-ui/core";

const Page1 = props => {
  const info = props.info;
  const setInfo = props.setInfo;
  const requiredDone = props.requiredDone;
  const setRequiredDone = props.setRequiredDone;
  const onNameChange = e => setInfo({ ...info, name: e.target.value });
  const onEmailChange = e => setInfo({ ...info, email: e.target.value });
  const onPhoneChange = e => setInfo({ ...info, phone: e.target.value });

  if (
    info.name !== "" &&
    info.email !== "" &&
    info.phone !== "" &&
    !requiredDone.p1
  ) {
    setRequiredDone({ ...requiredDone, p1: true });
  } else if (info.name === "" && requiredDone.p1) {
    setRequiredDone({ ...requiredDone, p1: false });
  } else if (info.email === "" && requiredDone.p1) {
    setRequiredDone({ ...requiredDone, p1: false });
  } else if (info.phone === "" && requiredDone.p1) {
    setRequiredDone({ ...requiredDone, p1: false });
  }

  return (
    <div>
      <div className="mb-3">
        <p className="mb-1">
          Full Name <span style={{ color: "red" }}>*</span>
        </p>
        <TextField
          id="name"
          error={info.name === ""}
          required
          value={info.name}
          onChange={onNameChange}
          variant="outlined"
          style={{ width: "100%" }}
        />
      </div>
      <div className="mb-3">
        <p className="mb-1">
          Email <span style={{ color: "red" }}>*</span>
        </p>
        <TextField
          id="email"
          error={info.email === ""}
          type='email'
          required
          value={info.email}
          onChange={onEmailChange}
          variant="outlined"
          style={{ width: "100%" }}
        />
      </div>
      <div className="mb-3">
        <p className="mb-1">
          Phone <span style={{ color: "red" }}>*</span>
        </p>
        <TextField
          id="phone"
          required
          error={info.phone === ""}
          value={info.phone}
          onChange={onPhoneChange}
          variant="outlined"
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
};

export default Page1;
