import React from "react";
import { Fade, Paper, Slide } from "@material-ui/core";

export function getYear() {
  let date = Date.now();
  let d = new Date(date);
  return d.getFullYear();
}

export function titleCase(text) {
  return text
    .toLowerCase()
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const setToken = (token, remember) =>
  new Promise(resolve => {
    resolve(setCookie("token", token, remember ? 7 : 0.5));
  });

export function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function eraseCookie(name) {
  document.cookie = name + "=; Max-Age=-99999999;";
}

export function ShowHide(props) {
  let style = !props.show && !props.placeholder ? { display: "none" } : {};
  let content;
  switch (props.variant.toLowerCase()) {
    case "fade":
      content = (
        <div>
          <Fade in={!props.show} enter={false} unmountOnExit>
            <Paper>{props.placeholder}</Paper>
          </Fade>

          <Fade in={props.show} timeout={1000} mountOnEnter>
            <Paper>{props.children}</Paper>
          </Fade>
        </div>
      );
      break;
    case "slide":
      content = (
        <div>
          <Slide in={!props.show} enter={false} unmountOnExit>
            <Paper>{props.placeholder}</Paper>
          </Slide>

          <Slide
            in={props.show}
            direction={props.direction || "up"}
            timeout={1000}
            mountOnEnter
          >
            <Paper>{props.children}</Paper>
          </Slide>
        </div>
      );
      break;

    default:
      content = (
        <div style={style}>
          {props.placeholder && !props.show
            ? props.placeholder
            : props.children}
        </div>
      );
      break;
  }

  return content;
}
ShowHide.defaultProps = {
  variant: ""
};

export function getImgSrcPieces(contentType, data) {
  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach(b => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  }

  let base64Flag = "data:" + contentType + ";base64,";
  let imageStr = arrayBufferToBase64(data);
  return base64Flag + imageStr;
}

export function getImgSrc(image) {
  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach(b => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  }
  try {
    let base64Flag = "data:" + image.contentType + ";base64,";
    let imageStr = arrayBufferToBase64(image.data.data);
    return base64Flag + imageStr;
  } catch {
    console.log("Failed to get image src for: ", image);
    return "";
  }
}


function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
};

export function formatPrice(price) {
  return `$${formatMoney(price)}`
  // return `$${price.toFixed(2).toString()}`;
}

export function splitPathName(path) {
  let splitPath = path.split("/").filter(p => p !== "");

  let newPathArr = [];
  splitPath.map(sPath => {
    newPathArr.push(sPath.replace(/%20/g, " "));
  });

  return newPathArr;
}

export function getCartTotalAndCount(cart) {
  let count = 0;
  let total = 0;
  if (cart) {
    cart.map(item => {
      count += item.quantity;
      total += item.quantity * item.price;
    });
  }
  localStorage.setItem(
    "cartTotalAndCount",
    JSON.stringify({ count: count, total: total })
  );
  return { count: count, total: total };
}

export function getCartTotalItems(cart) {
  let items = 0;
  if (cart) {
    cart.map(item => {
      items += item.quantity;
    });
  }

  return items;
}

export function maxChars(string, maxChar, placeholder) {
  if (string.length > maxChar) {
    return string.slice(0, maxChar) + placeholder;
  }
  return string;
}

export function getShortDescription (shortDescription, weight, size) {
  let retVal = shortDescription;
  if (weight && weight.length) {
    return retVal + " - " + weight;
  } else if (size && size.length) {
    return retVal + " - " + size;
  }
  return retVal;
};

export function formatDate(d) {
  var date = new Date(d);
  var monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return monthNames[monthIndex] + ' ' + day + ', ' + year;
}

export function removeDuplicates(array) {
  return Array.from(
    new Set(array.map(x => (typeof x === "string" ? x.toLowerCase() : x)))
  );
}



export function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  if (0 === currentIndex) {
    return array;
  }
}

export function convertDate(inputDate) {
  var date = new Date(inputDate)

  let month = date.getMonth() + 1
  let day = date.getDate()
  let year = date.getFullYear()
  let hour;
  let hourBefore = date.getHours()
  if (hourBefore === 0) {
      hour = 12;
  } else if (hourBefore > 12) {
      hour = hourBefore - 12
  } else {
      hour = hourBefore
  }
  let min = date.getMinutes()
  let am_pm = hourBefore < 12
      ? 'am'
      : 'pm'
  if (min < 10) {
      return (month + '/' + day + '/' + year + ' at ' + hour + ':0' + min + am_pm)
  }
  return (month + '/' + day + '/' + year + ' at ' + hour + ':' + min + am_pm)

}