import React, { useEffect, useState } from "react";
import PageTitle from "../../../misc/LMS/PageTitle";
import SlidingButtonGroup from "../../../misc/SlidingButtonGroup";
import { Grid } from "@material-ui/core";
import LeadInfo from "./LeadInfo";
import Notes from "./Notes";
import EmailLead from "./EmailLead";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import mainStyle from "../../../styles/LMS/mainStyle";
import qs from "query-string";

const styles = {};

const lead = {
  _id: "1",
  firstName: "Wade",
  lastName: "Smith",
  date: "6/27/2019 at 9:30am",
  campaign: "First Campaign",
  phone: "+7777777777",
  email: "wadejr@wadejr.com"
};

const pages = [
  {
    title: "Notes",
    context: <Notes _id={lead._id} />
  },
  {
    title: "Email",
    context: <EmailLead email={lead.email} />
  }
];

const Lead = props => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <PageTitle title="Lead" />
        <div
          style={{
            ...mainStyle.text,
            cursor: "pointer",
            display: "flex",
            alignItems: "center"
          }}
          onClick={() => {
            props.history.goBack()
          }}
        >
          <ArrowBackIcon /> Back
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid item md={12} lg={7}>
          <SlidingButtonGroup pages={pages} regular padding={0} />
        </Grid>
        <Grid item md={12} lg={5}>
          <LeadInfo lead={lead} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Lead;
