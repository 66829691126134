import React from "react";
import LeadPage from "../../components/LMS/Lead/Lead";
import Layout from "../../components/LMS/Layout/Layout";

const Lead = props => {
  return (
    <div>
      <Layout {...props}>
        <LeadPage {...props} />
      </Layout>
    </div>
  );
};

export default Lead;
