import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button, MobileStepper, Paper } from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Page from "./Page/Page";
import { connect } from "react-redux";
import { addContact } from "../../../redux/Marketing/actions/contactActions";
import Swal from "sweetalert2";
import qs from "query-string";
import "./style.css";

const useStyles = makeStyles({
  root: {
    width: "100%",
    flexGrow: 1
  }
});

const Questions = props => {
  const query = qs.parse(window.location.search).p;

  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [requiredDone, setRequiredDone] = useState({
    p1: false,
    p3: false
  });
  const [info, setInfo] = useState({
    name: "",
    email: "",
    phone: "",
    q1: false,
    q2: "",
    q3: false,
    q4: "",
    q5: false,
    q6: "",
    date: null,
    time: null
  });

  const handleNext = () => {
    if (activeStep === 2) {
      submit();
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const submit = () => {
    props.addContact({
      fullName: info.name,
      email: info.email,
      phone: info.phone,
      questions: [
        {
          "Have you tried any form of marketing before?": info.q1
        },
        {
          "How did it work for you?": info.q2
        },
        {
          "Do you currently have a marketing team?": info.q3
        },
        {
          "Are there any problems that you're currently facing with them?":
            info.q4
        },
        {
          "Do you currently have a website or landing page?": info.q5
        },
        {
          "URL?": info.q6
        },
        {
          Date: info.date,
          Time: info.time
        },
        {
          "Package selected: ": query || "none"
        }
      ],
      page: "Questionairre",
      test: true
    });
  };

  if (props.contact.success) {
    Swal.fire({
      title: "Success!",
      text: props.contact.message,
      type: "success",
      confirmButtonText: "Ok"
    }).then(() => {
      window.location.replace("/marketing");
    });
  }

  let done;
  switch (activeStep) {
    case 0:
      done = !requiredDone.p1;
      break;
    case 2:
      done = !requiredDone.p3;
      break;
    default:
      break;
  }

  return (
    <Paper
      style={{
        minHeight: "500px",
        position: "relative"
      }}
      className="mb-3 shadow"
    >
      <div className="p-5">
        <Page
          pageNumber={activeStep}
          info={info}
          setInfo={setInfo}
          requiredDone={requiredDone}
          setRequiredDone={setRequiredDone}
        />
      </div>
      <div
        className="mr-2"
        style={{
          position: "absolute",
          bottom: "20px",
          width: "100%"
        }}
      >
        <div style={{ width: "100%", textAlign: "center" }}>
          <div style={{ display: "inline-block", width: "80%" }}>
            <MobileStepper
              variant="progress"
              steps={3}
              position="static"
              activeStep={activeStep}
              className={classes.root}
              nextButton={
                <Button size="small" disabled={done || props.contact.contactLoading} onClick={handleNext}>
                  {activeStep === 2 ? "Submit" : "Next"}
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
          </div>
        </div>
      </div>
    </Paper>
  );
};

Questions.propTypes = {
  addContact: PropTypes.func.isRequired,
  success: PropTypes.bool,
  message: PropTypes.string,
  contact: PropTypes.object,
  contactLoading: PropTypes.bool
};

const mapStateToProps = state => ({ contact: state.contact });
const actions = {
  addContact
};

export default connect(
  mapStateToProps,
  actions
)(Questions);
