import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import CardTitle from "../../../misc/LMS/CardTitle";
import ColorButton from "../../../misc/ColorButton";
import mainStyle from "../../../styles/LMS/mainStyle";
import { ShowHide } from "../../../misc/utility";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";

const styles = {};

const LeadInfo = props => {
  const { lead } = props;
  return (
    <div>
      <Paper elevation={5} className="mt-3">
        <CardTitle title="Contact" />
        <div className="p-3" style={{ maxWidth: "100%" }}>
          <div
            className="text-muted mb-1"
            style={{ ...mainStyle.text, fontSize: "16px" }}
          >
            Name
          </div>
          <div className="mb-2" style={{ ...mainStyle.text, fontSize: "20px" }}>
            {lead.firstName + " " + lead.lastName}
          </div>
          <div
            className="text-muted mb-1"
            style={{ ...mainStyle.text, fontSize: "16px" }}
          >
            Phone
          </div>
          <div>
            <a
              className="mb-2"
              style={{ ...mainStyle.text, fontSize: "20px" }}
              href={"tel://" + lead.phone}
            >
              {lead.phone}
            </a>
          </div>
          <div
            className="text-muted mb-1"
            style={{ ...mainStyle.text, fontSize: "16px" }}
          >
            Email
          </div>
          <div className="mb-2" style={{ ...mainStyle.text, fontSize: "20px" }}>
            {lead.email}
          </div>
          <div
            className="text-muted mb-1"
            style={{ ...mainStyle.text, fontSize: "16px" }}
          >
            Date Received
          </div>
          <div className="mb-2" style={{ ...mainStyle.text, fontSize: "20px" }}>
            {lead.date}
          </div>
          <ShowHide show={true}>
            <div
              className="text-muted mb-1"
              style={{ ...mainStyle.text, fontSize: "16px" }}
            >
              Please submit this lead as contacted. Make sure to leave a note.
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <ColorButton
                contained
                noRadius
                // disabled={loading}
                textColor="white"
                color={mainStyle.buttonColor}
                hoverColor={mainStyle.hoverColor}
                type="submit"
                style={{
                  ...mainStyle.bigButton,
                  width: "100%"
                }}
              >
                <ThumbUpIcon />
              </ColorButton>
              <ColorButton
                contained
                noRadius
                // disabled={loading}
                textColor="white"
                color={"#ff3333"}
                hoverColor={"#ff1111"}
                type="submit"
                style={{
                  ...mainStyle.bigButton,
                  width: "100%"
                }}
              >
                <ThumbDownIcon />
              </ColorButton>
              <ColorButton
                contained
                noRadius
                // disabled={loading}
                textColor="white"
                color={"#c8c8c8"}
                hoverColor={"#c5c5c5"}
                type="submit"
                style={{
                  ...mainStyle.bigButton,
                  width: "100%"
                }}
              >
                <PhoneCallbackIcon />
              </ColorButton>
            </div>
          </ShowHide>
        </div>
      </Paper>
    </div>
  );
};

export default LeadInfo;
