import React, { useEffect, useState } from "react";
import PageTitle from "../../../misc/LMS/PageTitle";
import { Grid } from "@material-ui/core";
import OldLeads from "./OldLeads";
import CallBacks from "./CallBacks";

const styles = {};

const Archive = props => {
  return (
    <div>
      <PageTitle title="Archive" />
      <div>
        <Grid container spacing={3} className="mb-5 mt-3">
          <Grid item md={12} lg={6}>
            <OldLeads />
          </Grid>
          <Grid item md={12} lg={6}>
            <CallBacks />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Archive;
