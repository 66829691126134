import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Grid, TextField, Container } from "@material-ui/core";
import mainStyle from "../../../styles/Marketing/mainStyle";
import ColorButton from "../../../misc/ColorButton";
import call from "../../../images/Marketing/call.svg";
import mail from "../../../images/Marketing/email.svg";
import Recaptcha from "../../../misc/Recaptcha";
import { connect } from "react-redux";
import { addContact } from "../../../redux/Marketing/actions/contactActions";
import Swal from "sweetalert2";
import { ClipLoader } from "react-spinners";


const styles = {
  root: {},
  links: {
    display: "flex",
    alignItems: "center"
  }
};

const Contact =  props => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  let requiredDone = false

  const onNameChange = e => {
    setName(e.target.value)
  };
  const onEmailChange = e => {
    setEmail(e.target.value)  
  };
  const onPhoneChange = e => {
    setPhone(e.target.value)
  };
  const onMessageChange = e => {
    setMessage(e.target.value)
  };
  const sendContact = () => {
    props.addContact({fullName: name, email: email, phone: phone, message: message, page: 'Marketing', test: false})
  }

 
  if(name === '' || email === '' || phone === '' || message === '') {
    requiredDone = false
  } else {
    requiredDone = true
  }

  if(props.contact.success) { 
    Swal.fire({
      title: "Success!",
      text: "We will contact you shortly.",
      type: "success",
      confirmButtonText: "Ok"
    }).then(() => {
      setName('')
      setEmail('')  
      setPhone('')
      setMessage('')
    });
  }

  return (
    <div style={{ ...styles.root, ...mainStyle.section, backgroundColor: mainStyle.backgroundColor }}>
      <Container fixed maxWidth={"md"}>
        <div className="mb-3">
          <h2 className="text-center">We Can't Wait To Hear From You!</h2>
        </div>
        <form>
          <Grid container>
            <Grid className="mb-3" item xs={12} md={6}>
              <div className="mb-2" style={styles.links}>
                <div className="mr-2">
                  <img
                    alt={"Mail Icon"}
                    src={mail}
                    title="Email Me"
                    style={{ height: "40px", width: "40px" }}
                  />
                </div>
                <div>
                  <a
                    href="mailto:wade@wadejr.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ ...mainStyle.text }}
                  >
                    wade@wadejr.com
                  </a>
                </div>
              </div>
              <div style={styles.links}>
                <div className="mr-2">
                  <img
                    alt={"Call Icon"}
                    src={call}
                    title="Call Me"
                    style={{ height: "40px", width: "40px" }}
                  />
                </div>
                <div>
                  <a href="tel:17273247781" style={{ ...mainStyle.text }}>
                    +1.727.324.7781
                  </a>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid item xs={12}>
                <TextField
                  id="name"
                  label="Name"
                  required
                  value={name}
                  onChange={onNameChange}
                  margin="normal"
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="phone"
                  label="Phone"
                  required
                  value={phone}
                  onChange={onPhoneChange}
                  margin="normal"
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  label="Email"
                  required
                  type='email'
                  value={email}
                  onChange={onEmailChange}
                  margin="normal"
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="message"
                  label="Message"
                  required
                  multiline
                  rows="6"
                  value={message}
                  onChange={onMessageChange}
                  margin="normal"
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12}>
              <div className='mb-1'>
                  <Recaptcha getSuccess={setSuccess} getLoading={setLoading} color={mainStyle.defaultColor}/>
                </div>
              </Grid>
              <Grid item xs={12}>
                <ColorButton
                  contained
                  noRadius
                  disabled={!success || !requiredDone || props.contact.contactLoading }
                  textColor="white"
                  color={mainStyle.buttonColor}
                  hoverColor={mainStyle.hoverColor}
                  style={{
                    ...mainStyle.bigButton,
                    width: "100%"
                  }}
                  onClick={sendContact}
                >
                  <div style={{display: 'flex', alignItems:'center', justifyContent: 'center'}}>
                        <span className={props.contact.contactLoading ? 'mr-2': ''}>
                        Submit</span>
             
                        <ClipLoader
                          sizeUnit={"px"}
                          size={15}
                          color={mainStyle.defaultColor}
                          loading={props.contact.contactLoading }
                        /></div>
                </ColorButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
};

Contact.propTypes = { 
  addContact: PropTypes.func.isRequired,
  success: PropTypes.bool,
  message: PropTypes.string,
  contact: PropTypes.object,
  contactLoading: PropTypes.bool
}
 
const mapStateToProps = state => ({ contact: state.contact });
const actions = {
  addContact
};

export default connect(
  mapStateToProps,
  actions
)(Contact);
