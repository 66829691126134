import React from 'react'
import RecoverPage from '../../components/LMS/Login/RecoverPage'


const Recover = (props) => {
    return (
        <div>
            <RecoverPage {...props}/>
        </div>
    )
};

export default Recover;