import React from "react";
import { Container } from "@material-ui/core";
import Title from "../../../misc/Personal/Title";
import Jobs from "./Jobs";

const styles = {
  root: {
    // display: 'flex',
    // justifyContent: 'center', 
  // textAlign: 'center'
  width: '100%'
  }
}

const Work = () => {
  return (
    <div className="section">
      <Container fixed style={styles.root}>
        <div style={{marginRight: 'auto', marginLeft: 'auto', maxWidth: '800px'}}>
          <Title className='mb-3'>Work</Title>
          <Jobs />
        </div>
      </Container>
    </div>
  );
};

export default Work;
