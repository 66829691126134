import React, { Component } from "react";
import { Container, Grid } from "@material-ui/core";
import Project from "./Project";
import Title from "../../../misc/Personal/Title";
import FeaturedProject from "./FeaturedProject";
import $ from "jquery";
import "./style.css";

const fu = () => {
  $.fn.strech_text = function() {
    var elmt = $(this),
      cont_width = "100",
      txt = elmt.html(),
      one_line = $('<span class="stretch_it">' + txt + "</span>"),
      nb_char = elmt.text().length,
      spacing = cont_width / nb_char,
      txt_width;

    elmt.html(one_line);
    txt_width = 94;// one_line.width();


    if (txt_width < cont_width) {
      var char_width = txt_width / nb_char;
      var ltr_spacing = spacing - char_width + (spacing - char_width) / nb_char;

      one_line.css({ "letter-spacing": ltr_spacing });
    } else {
      one_line.contents().unwrap();
      elmt.addClass("justify");
    }
  };

  $(document).ready(function() {
    $(".stretch").each(function() {
      $(this).strech_text();
    });
  });
};

const projects = [
  {
    place: "USF, Hackathon",
    title: "Hack-A-Bull",
    description:
      "With one collaborator, together we created a web application to automatically make a description of an uploaded image.",
    languages: ["Django", "Azure", "AI", "API", "Computer Vision"]
  },
  {
    place: "UF, Hackathon",
    title: "SwampHacks",
    description:
      "Contributed in creating an application which pinpoints a person's location by displaying a 3D beacon on a camera.",
    languages: ["Django", "AWS", "Android Studio"]
  },
  {
    place: "Embry Riddle, Hackathon",
    title: "HackRiddle",
    description:
      "Collaborated with a group of two to create two games which responds to hand gestures.",
    languages: ["Leap Motion SDK", "Unity", "Python"]
  },
  {
    place: "USF",
    title: "Senior Project",
    description:
      "Collaborated with a team of three to partially automate the C.S.E. advising system via voice and SMS channels.",
    languages: ["Twilio", "Node.js", "Python", "MongoDB"]
  },
  {
    place: "Gulf Controls",
    title: "Traveling Salesman",
    description:
      "Developed an algorithm to solve a Traveling Salesman Problem to potentially improve productivity by at least 15% a week for up to 29 locations.",
    languages: ["C#", "Windows Forms", "Algorithms"]
  },
  {
    place: "Gulf Controls",
    title: "Heat Shrink",
    description:
      "Proposed and developed an application to drastically simplify the process of cutting heat shrink and increased productivity by at least 50% a day.",
    languages: ["C#", "MySQL", "Windows Forms"]
  },
  {
    place: "Gulf Controls",
    title: "Label Printer",
    description:
      "Optimized the label printing process by creating a label printer application to print zpl images to labels.",
    languages: ["C#", "Asp.Net", "MySQL", "HTML", "CSS"]
  },
  {
    place: "Gulf Controls",
    title: "Numbers Application",
    description:
      "Developed a webform application with four pages that is used to display contents of a database with CRUM functionalities.",
    languages: ["C#", "Asp.Net", "MySQL", "HTML", "CSS"]
  },
  {
    place: "Gulf Controls",
    title: "OCR",
    description:
      "Automated processing scanned documents and emails by creating an OCR application.",
    languages: ["C#", "OCR"]
  },
  {
    place: "Personal",
    title: "Class Sign-up",
    description:
      "Created a selenium application to guarantee my seat in every class I desired by having the application click the sign-up button exactly at the allotted time.",
    languages: ["Selenium", "Automation", "C#", "Widows Forms"]
  }
];

export class Projects extends Component {
  componentDidMount() {
    fu();
    window.addEventListener("resize", fu);
    setTimeout(() => {
      fu();
    }, 2000);
  }
  render() {
    return (
      <div
        onLoad={fu}
        style={{ backgroundColor: "none", position: "relative" }}
        id="projects"
        className="section"
      >
        <Container fixed>
          <Title className="mb-3">Projects</Title>
          <FeaturedProject />
        </Container>
        <div style={{ position: "relative" }}>
          <div
            id="hideName"
            style={{
              position: "absolute",
              zIndex: "-1",
              color: "red",
              height: "100%",
              width: "100%"
            }}
          >
            <svg viewBox="0 0 100 100">
              <foreignObject
                width="100%"
                height="100%"
              >
                <h2
                  className="stretch mr-0"
                  style={{ maxWidth: "100%" }}
                >
                  WADE
                </h2>
                <h2 className="">JR.</h2>

              </foreignObject>
            </svg>
          </div>
          <Container fixed className="scroll">
            <Grid container direction="row" justify="space-around" spacing={3}>
              {projects.map(project => (
                <Grid item xs={12} md={6} lg={4}>
                  <Project
                    place={project.place}
                    title={project.title}
                    description={project.description}
                    languages={project.languages}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
}

export default Projects;
