import React, { useEffect, useState } from "react";
import { Slide, Zoom, Fade, Paper } from "@material-ui/core";
import image from "../../../images/Marketing/dots.svg";
import Logo from "../../../images/Marketing/Logo";
import mainStyle from "../../../styles/Marketing/mainStyle";
import { ShowHide } from "../../../misc/utility";
import { ClipLoader } from "react-spinners";

let zIndex = 11100;
const styles = {
  loading: {
    position: "fixed",
    zIndex: zIndex,
    height: "100vh",
    width: "100vw",
    backgroundColor: "black",
    backgroundImage: `url(${image})`,
    backgroundRepeat: "repeat-x",
    backgroundPosition: "center"
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    position: 'relative'
  }
};

const Screen = () => {
  return (
    <div style={styles.loading}>
      <div style={styles.logo}>
        <div className='text-center h-50'>
          <Logo style={{ height: "100%", width: "100%", zIndex: zIndex + 1 }} />
          <div className="text-center">
            <ClipLoader
              sizeUnit={"px"}
              size={50}
              color={mainStyle.defaultColor}
              loading={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const LoadingScreen = props => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <div>
      <ShowHide show={!loading} variation="fade" placeholder={<Screen />}>
        {props.children}
      </ShowHide>
    </div>
  );
};

export default LoadingScreen;
