import React, { useEffect, useState } from "react";
import PieChart from "../../../misc/LMS/PieChart";
import { Paper } from "@material-ui/core";
import CardTitle from "../../../misc/LMS/CardTitle";
import { connect, useSelector } from "react-redux";
import { getCot } from "../../../redux/LMS/actions/leadActions";
import { ShowHide } from "../../../misc/utility";
import { ClipLoader } from "react-spinners";
import mainStyle from "../../../styles/LMS/mainStyle";

const styles = {
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};

// let values = [44, 55, 13];
let options = {
  chart: {
    width: 380,
    type: "pie"
  },
  labels: ["< 10 min", "< 1 hour", "Late"],
  colors: ["#1988ff", "#7fbdff", "#ff3333"],
  responsive: [
    {
      breakpoint: 1050,
      options: {
        // chart: {
        //   width: 360
        // },
        legend: {
          position: "bottom"
        }
      }
    },
    {
      breakpoint: 960,
      options: {
        chart: {
          width: 380
        },
        legend: {
          position: "right"
        }
      }
    },
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 360
        },
        legend: {
          position: "bottom"
        }
      }
    },
    {
      breakpoint: 400,
      options: {
        chart: {
          width: 310
        },
        legend: {
          position: "bottom"
        }
      }
    },
    {
      breakpoint: 358,
      options: {
        chart: {
          width: 260
        },
        legend: {
          position: "bottom"
        }
      }
    }
  ]
};

const CallsOnTime = props => {
  const c = useSelector(state => state.lead);
  const [cot, setCot] = useState(null);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(null);

  useEffect(() => {
    props.getCot();
  }, []);

  useEffect(() => {
    if (c.cot) {
      setCot(c.cot);
    }
  }, [c.cot]);

  useEffect(() => {
    setLoading(c.cotLoading);
  }, [c.cotLoading]);

  useEffect(() => {
    if (cot && cot.lt10 !== null) {
      setValues([cot.lt10, cot.lt60, cot.other]);
    }
  }, [cot]);

  return (
    <div>
      <Paper elevation={5}>
        <CardTitle title="Calls on Time" />
        <div style={styles.center} className="pt-3">
          <ShowHide
            show={values && !loading}
            placeholder={
              <ShowHide
                show={!loading}
                placeholder={
                  <ClipLoader
                    sizeUnit={"px"}
                    size={40}
                    color={mainStyle.defaultColor}
                    loading={loading}
                    className="py-3"
                  />
                }
              >
                <p className="text-muted text-center py-3">No data.</p>
              </ShowHide>
            }
          >
            <PieChart values={values} options={options} />
          </ShowHide>
        </div>
      </Paper>
    </div>
  );
};

const actions = {
  getCot
};
export default connect(null, actions)(CallsOnTime);
