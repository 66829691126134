import React, { Component } from "react";
import { recaptcha } from "../redux/LMS/actions/loginActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import RecaptchaBox from "react-recaptcha";
import { ScaleLoader } from "react-spinners";

const styles = {
  recaptchaStyle: {
    transform: "scale(0.77)",
    WebkitTransform: "scale(0.77)",
    transformOrigin: "0 0",
    WebkitTransformOrigin: "0 0"
  }
};

class Recaptcha extends Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
      recaptchaLoading: false,
      recaptchaBoxLoading: true,
      loading: false
    };

    this.verifyCallback = this.verifyCallback.bind(this);

    this.onLoadCallback = this.onLoadCallback.bind(this);

    this.expiredCallback = this.expiredCallback.bind(this);
  }

  verifyCallback = response => {
    this.props.getLoading( true );
    this.props.recaptcha(response);
  };

  expiredCallback() {
    this.setState({ success: false });
    this.props.getSuccess(false);
  }

  onLoadCallback() {
    this.setState({ recaptchaBoxLoading: false });
  }

  componentWillReceiveProps(nextProps) {

    this.setState(
      {
        success: nextProps.login.success,
        recapatchaLoading: nextProps.login.recapatchaLoading
      },
      () => {
        this.props.getSuccess(nextProps.login.success);
        if (this.state.success) {
          this.props.getLoading( false );
        }
      }
    );
  }

  render() {
    let small = this.props.small ? styles.recaptchaStyle: {}
    return (
      <div className={this.props.className} style={{...this.props.style}}>
        <div id={this.props.id} style={{...small, ...this.props.recaptchaStyle}}>
          <RecaptchaBox
            className="gRecaptcha"
            sitekey="6LfSCKsUAAAAAOOfOyru1WbXcFLHYcfXgqRoTvlM"
            render="explicit"
            verifyCallback={this.verifyCallback}
            onloadCallback={this.onLoadCallback}
            expiredCallback={this.expiredCallback}
            badge="inline"
          />
        </div>

        <div style={{}}>
          <ScaleLoader
            sizeUnit={"px"}
            size={15}
            color={this.props.color || '#000'}
            loading={this.state.recaptchaBoxLoading}
          />
        </div>
      </div>
    );
  }
}

Recaptcha.propTypes = {
  //Usable
  getSuccess: PropTypes.func.isRequired,
  getLoading: PropTypes.func,
  color: PropTypes.string,
  style: PropTypes.object,
  recaptchaStyle: PropTypes.object,
  className: PropTypes.string,
  small: PropTypes.bool,
  id: PropTypes.string,


  recaptcha: PropTypes.func.isRequired,
  recapatchaLoading: PropTypes.bool,
 

};

Recaptcha.defaultProps = {
  getLoading: (nothing) => {
    return;
  }
}

const mapStateToProps = state => ({ login: state.login });
const actions = {
  recaptcha
};

export default connect(
  mapStateToProps,
  actions
)(Recaptcha);
