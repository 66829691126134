

export const mainStyle = {
  buttonColor: "#0072ff",//"#007bff", 
  backgroundColor: '#fff',
  hoverColor: "#0060ff", //"#1de4be",
  // secondaryColor: "#1de4be",
  defaultColor: "#0072ff",//"#2f55cc",
  button: {
    fontFamily: "Montserrat, 'Proza Libre', -apple-system, sans-serif",
    fontWeight: 700,
  },
  bigButton: {
    fontFamily: "Montserrat, 'Proza Libre', -apple-system, sans-serif",
    fontWeight: 700,
    fontSize: "21px",
    padding: "14px 36px"
  },
  alternateBackgroundColor: {
    backgroundColor:"#eff2f6"//"#f4f6fa"//
  },

  section: {
    paddingTop: "75px",
    paddingBottom: "75px"
  },
  text: {
    fontFamily: "Montserrat, 'Proza Libre', -apple-system, sans-serif",
    color: '#3d5170',//'black',
    fontSize: '18px',
    fontWeight: 400,
  }
};

export default mainStyle;
