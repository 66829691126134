import React from "react";
import PropTypes from "prop-types";
import mainStyle from "../../../styles/Marketing/mainStyle";
import { Container } from "@material-ui/core";

const styles = {
  root: {
    background: `linear-gradient(150deg,${mainStyle.defaultColor}, ${mainStyle.secondaryColor})`
  },
  center: {
    height: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};

const Header = props => {
  return (
    <div style={styles.root}>
      <Container fixed>
        <div style={styles.center}>
          <h1 style={{ color: "white" }}>{props.title}</h1>
        </div>
      </Container>
    </div>
  );
};


Header.propTypes = {
  title: PropTypes.string.isRequired
};

export default Header;
