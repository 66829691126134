import React from "react";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Grid
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import "date-fns";

const Page3 = props => {
  const info = props.info;
  const setInfo = props.setInfo;
  const requiredDone = props.requiredDone;
  const setRequiredDone = props.setRequiredDone;
  const onQ5Change = e => setInfo({ ...info, q5: e.target.value });
  const onQ6Change = e => setInfo({ ...info, q6: e.target.value });
  const onDateChange = e => setInfo({ ...info, date: e });
  const onTimeChange = e => setInfo({ ...info, time: e });

  if (
    info.date !== null &&
    info.date !== "" &&
    info.time !== null &&
    info.time !== "" &&
    !requiredDone.p3
  ) {
    setRequiredDone({ ...requiredDone, p3: true });
  } else if (info.date === null && requiredDone.p3) {
    setRequiredDone({ ...requiredDone, p3: false });
  } else if (info.date === '' && requiredDone.p3) {
    setRequiredDone({ ...requiredDone, p3: false });
  } else if (info.time === null && requiredDone.p3) {
    setRequiredDone({ ...requiredDone, p3: false });
  } else if (info.time === "" && requiredDone.p3) {
    setRequiredDone({ ...requiredDone, p3: false });
  }

  return (
    <div>
      <div className="mb-3">
        <p className="mb-1">
          Do you currently have a website or landing page?
        </p>
        <FormControl variant="outlined" style={{ width: "100%" }}>
          <Select
            id="q5"
            value={info.q5}
            onChange={onQ5Change}
            style={{ width: "100%" }}
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
      </div>
      {info.q5 ? (
        <div className="mb-3">
          <p className="mb-1">Url</p>
          <TextField
            id="q6"
            value={info.q6}
            onChange={onQ6Change}
            variant="outlined"
            style={{ width: "100%" }}
          />
        </div>
      ) : (
        <div></div>
      )}

      <div className="mb-3">
        <p className="mb-1">
          When is a good time for me to contact you?{" "}
          <span style={{ color: "red" }}>*</span>
        </p>
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <KeyboardDatePicker
                  id="date-picker-dialog"
                  label="Date"
                  format="MM/dd/yyyy"
                  error={info.date === '' || info.date === null}
                  value={info.date}
                  onChange={onDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <KeyboardTimePicker
                  id="time-picker"
                  label="Time"
                  error={info.time === '' || info.time === null}
                  value={info.time}
                  onChange={onTimeChange}
                  KeyboardButtonProps={{
                    "aria-label": "change time"
                  }}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </div>
  );
};

export default Page3;
