import React from "react";
import PropTypes from "prop-types";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav as Navigation,
  NavItem,
  NavLink
} from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";

import OnlyLogo from "../../../images/Marketing/OnlyLogo";
import clsx from "clsx";
import {
  Container,
  ClickAwayListener,
  List,
  ListItem,
  Divider,
  SwipeableDrawer,
  IconButton
} from "@material-ui/core";
import ColorButton from "../../../misc/ColorButton";
import mainStyle from "../../../styles/Marketing/mainStyle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";

const backgroundColor = "white";

const useStyles = makeStyles({
  list: {
    width: 250
  }
});

const styles = {
  logo: {
    height: "45%",
    width: "45%",
    position: {
      position: "absolute"
    }
  },

  backgroundColor: backgroundColor,

  gradient: {
    //#292
    background: `-webkit-linear-gradient(${mainStyle.defaultColor}, ${mainStyle.secondaryColor})`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  }
};

const navLinks = [
  {
    title: "Services",
    href: "/marketing#services",
    samePage: true
  },
  {
    title: "About",
    href: "/marketing#about",
    samePage: true
  },
  {
    title: "Pricing",
    href: "/marketing#pricing",
    samePage: true
  },
  {
    title: "FAQ",
    href: "/marketing/faq",
    samePage: false
  },
  {
    title: "Contact",
    href: "/marketing/contact",
    samePage: false
  },
  {
    title: "Log In",
    href: "/lms",
    samePage: false
  }
];

const NavContent = props => {
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const classes = useStyles();
  const [open] = React.useState(false);
  const [state, setState] = React.useState({
    right: false
  });

  const toggleDrawer = (side, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const MyDesktopNavLink = props => {
    return (
      <React.Fragment>
        {props.link.samePage ? (
          <a
            style={{
              color: "black",
              textDecoration: "none"
            }}
            href={props.link.href}
            title={props.link.title}
          >
            <p
              style={{
                height: "100%",
                marginTop: "auto",
                marginBottom: "auto"
              }}
            >
              {props.link.title}
            </p>
          </a>
        ) : (
          <Link
            style={{
              textDecoration: "none",
              color: "black"
            }}
            title={props.link.title}
            to={props.link.href}
          >
            <p
              style={{
                height: "100%",
                marginTop: "auto",
                marginBottom: "auto"
              }}
            >
              {props.link.title}
            </p>
          </Link>
        )}
      </React.Fragment>
    );
  };

  const MyNavLink = props => {
    return (
      <React.Fragment>
        {props.link.samePage ? (
          <a
            style={{
              display: "block",
              height: "100%",
              width: "100%",
              color: "black",
              textDecoration: "none"
            }}
            href={props.link.href}
          >
            <ListItem button key={props.link.title}>
              <p>{props.link.title}</p>
            </ListItem>
          </a>
        ) : (
          <ListItem button>
            <Link
              style={{
                textDecoration: "none",
                color: "black",
                display: "block",
                height: "100%",
                width: "100%"
              }}
              title={props.link.title}
              to={props.link.href}
            >
              <p>{props.link.title}</p>
            </Link>
          </ListItem>
        )}
      </React.Fragment>
    );
  };

  const sideList = side => (
    <div
      className={classes.list}
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        <ListItem button>
          <div style={{ display: "flex" }}>
            <ArrowBackIcon
              style={{
                marginRight: "10px",
                marginTop: "4px",
                color: mainStyle.defaultColor
              }}
            />
            <h2>Menu</h2>
          </div>
        </ListItem>
        {navLinks.map(link => (
          <MyNavLink link={link} />
        ))}

        <Link
          style={{ textDecoration: "none", color: mainStyle.defaultColor }}
          title="Get Started"
          to="/marketing/get-started"
        >
          <ListItem button>
            <p>Get Started</p>
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <Navbar light className="h-100" sticky="top" expand="lg">
      <Container fixed style={{ height: "100%" }}>
        <div
          className="d-flex"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <NavbarBrand
              href="/marketing"
              title="The best dental marketing in Tampa, Florida. Wade Jr. Dental Marketing."
            >
              <span>
                <OnlyLogo style={{ height: "35px", width: "35px" }} />{" "}
                <span
                  style={{
                    ...mainStyle.text,
                    fontWeight: 700,
                    ...styles.gradient
                  }}
                >
                  Dental Marketing
                </span>
              </span>
            </NavbarBrand>
          </div>

          <div
            id="mobileNav"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={toggleDrawer("right", true)}
              className={clsx(open && classes.hide)}
              style={{
                outline: "none",
                marginTop: "auto",
                marginBottom: "auto"
              }}
              id="mobileNav"
            >
              <MenuIcon style={{ height: "35px", width: "35px" }} />
            </IconButton>

            <SwipeableDrawer
              style={{ zIndex: "100000000", position: "relative" }}
              anchor="right"
              disableBackdropTransition={!iOS}
              disableDiscovery={iOS}
              open={state.right}
              onClose={toggleDrawer("right", false)}
              onOpen={toggleDrawer("right", true)}
            >
              {sideList("right")}
            </SwipeableDrawer>
          </div>
          <div id="bigNav">
            <Navigation
              className="h-100 d-flex mr-2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
              navbar
            >
              {navLinks.map((link, idx) => (
                <NavItem
                  key={idx}
                  className="mr-2 "
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  {/* <NavLink id="navLinks" href={link.href} title={link.title}>
                    <div style={{ ...mainStyle.text, fontSize: "14px" }}>
                      {link.title}
                    </div>
                  </NavLink> */}
                  <MyDesktopNavLink link={link} />
                </NavItem>
              ))}

              <NavItem id="getStartedButton">
                <Link
                  style={{ textDecoration: "none" }}
                  title="Get Started"
                  to="/marketing/get-started"
                >
                  <ColorButton
                    contained
                    noRadius
                    color={mainStyle.buttonColor}
                    hoverColor={mainStyle.hoverColor}
                    style={{
                      ...mainStyle.button,
                      padding: "10px 14px"
                    }}
                  >
                    Get Started
                  </ColorButton>
                </Link>
              </NavItem>
            </Navigation>
          </div>
        </div>
      </Container>
    </Navbar>
  );
};

NavContent.propTypes = {
  toggleNavbar: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  transparentBackground: PropTypes.string.isRequired
};

export default NavContent;
