import { getAdminSessionCookie, getClientSessionCookie } from "../misc/Session";
// Get token from cookies and format to send to api
export const getConfig = userType => {
  let token;

  if (userType === "admin") {
    token = getAdminSessionCookie().token;
  } else if (userType === "logout") {
    getAdminSessionCookie().logout();
    getClientSessionCookie().logout();
    return;
  } else {
    token = getClientSessionCookie().token;
  }

  const config = {
    headers: {
      authorization: "Bearer " + token
    }
  };

  return config;
};
