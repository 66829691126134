import React from "react";
import Dash from "../../components/LMS/Dashboard/Dashboard";
import Layout from "../../components/LMS/Layout/Layout";

const Dashboard = props => {
  return (
    <div>
      <Layout {...props}>
        <Dash {...props} />
      </Layout>
    </div>
  );
};

export default Dashboard;
