import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export default function ColorSwitch(props) {
  const CustomSwitch = withStyles({
    switchBase: {
      color: props.color,
      "&$checked": {
        color: props.checkedColor
      },
      "&$checked + $track": {
        backgroundColor: props.checkedColor
      }
    },
    checked: {},
    track: {}
  })(Switch);

  return (
    <FormGroup className={props.className} style={props.style} row>
      <FormControlLabel
        style={props.labelStyle}
        control={
          <CustomSwitch
            checked={props.checked}
            onChange={props.onChange}
            value={props.value}
            color='primary'
          />
        }
        label={props.label}
        labelPlacement={props.labelPlacement}
      />
    </FormGroup>
  );
}

ColorSwitch.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  color: PropTypes.string,
  checkedColor: PropTypes.string
};
