import { combineReducers } from 'redux'
import leadReducer from './LMS/reducers/leadReducer'
import clientReducer from './LMS/reducers/clientReducer';
import loginReducer from './LMS/reducers/loginReducer';
import adminReducer from './LMS/reducers/adminReducer';
import superReducer from './LMS/reducers/superReducer';
import fbReducer from './LMS/reducers/fbReducer'
import templateReducer from './LMS/reducers/templateReducer';
import contactReducer from './Marketing/reducers/contactReducer';



export default combineReducers({
    lead: leadReducer,
    client: clientReducer,
    login: loginReducer,
    admin: adminReducer,
    super: superReducer,
    fb: fbReducer,
    template: templateReducer,
    contact: contactReducer
})
