import React, {useEffect} from "react";
import Hero from "../../components/Personal/Hero/Hero";
import Nav from "../../components/Personal/Nav/Nav";
import About from "../../components/Personal/About/About";
import Projects from "../../components/Personal/Projects/Projects";
import Work from "../../components/Personal/Work/Work";
import Skills from "../../components/Personal/Skills/Skills";
import doubleSlant from "../../images/Personal/doubleSlant.svg";
import CallMe from "../../components/Personal/CallMe/CallMe";
import Footer from "../../components/Personal/Footer/Footer";
import { Helmet } from "react-helmet";
import Meta from "../../misc/Meta";
import LoadingScreen from "../../components/Personal/LoadingScreen/LoadingScreen";
import ScrollBarColor from "../../misc/ScrollBarColor";
import qs from "query-string";

const meta = {
  title: "Wade Jr. Portfolio | Tampa, FL",
  description:
    "Wade Jr. is a Computer Engineer out of the University of South Florida with interests in full-stack development, marketing, real estate, ecommerce, and stocks.",
  keywords: "wade jr., wade, jr. wade junior, junior, computer engineer, software developer, tampa, fl, florida, largo, clearwater, entreprenuer, marketing, digital marketing",
  url: "www.wadejr.com/"
};

const styles = {
  slant: {
    backgroundImage: `url(${doubleSlant})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    zIndex: 0
  }
};

const hashCheck = hash => {
  switch(hash) {
    case '#about': 
      window.location.href = '/#about'
    break;
    case '#projects': 
      window.location.href = '/#projects'
    break;
    case '#skills': 
      window.location.href = '/#skills'
    break;
    
    default:
      break;
  }
}


const LandingPage = () => {
  ScrollBarColor("red", "snow", null, "10px");
  useEffect(() => {
    setTimeout(() => {
      hashCheck(window.location.hash)
    }, 5500)
  }, [])
  return (
    <div>
      <Helmet>
        <title>Wade Jr.</title>
        <Meta
          title={meta.title}
          description={meta.description}
          url={meta.url}
        />
      </Helmet>

      <LoadingScreen>
        <Nav />
        <Hero />
        <div style={{ backgroundColor: "snow" }}>
          <div style={styles.slant}>
            <About />
            <Work />
            <Projects />
          </div>

          <Skills />
        </div>
        <CallMe />
        <Footer />
      </LoadingScreen>
    </div>
  );
};

export default LandingPage;
