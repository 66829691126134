import React from "react";
import mainStyle from "../../../styles/Marketing/mainStyle";
import { Container, Grid } from "@material-ui/core";
import ColorButton from "../../../misc/ColorButton";
import { getYear } from "../../../misc/utility";
import "./style.css";

const styles = {
  root: {
    backgroundColor: "rgb(43, 45, 58)",
    // marginTop: "150px",
    paddingBottom: "25px",
    zIndex: 10
  }
};

const links = [
  {
    title: "Services",
    href: "/marketing#services"
  },
  {
    title: "About",
    href: "/marketing#about"
  },
  {
    title: "Pricing",
    href: "/marketing#pricing"
  },
  {
    title: "FAQ",
    href: "/marketing/faq"
  },
  {
    title: "Contact",
    href: "/marketing/contact"
  },
  {
    title: "Log In",
    href: "/lms"
  }
];

const Footer = () => {
  return (
    <footer>
      <div style={{ ...mainStyle.section, ...styles.root }}>
        <Container fixed>
          <Grid container direction="row" justify="space-between">
            <Grid item xs={12} md={4} className="mb-3">
              <div>
                <h1 style={{ color: "white", fontWeight: 400 }}>Ready to</h1>
                <h1 style={{ color: "white" }}>Get Started?</h1>
              </div>
              <div>
                <ColorButton
                  contained
                  noRadius
                  color={mainStyle.buttonColor}
                  hoverColor={mainStyle.hoverColor}
                  href="/marketing/get-started"
                  style={{
                    ...mainStyle.bigButton
                  }}
                >
                  Get Started
                </ColorButton>
              </div>
            </Grid>

            <Grid item xs={6} md={4} id="container" className="mb-3">
              <div>
                <p id="left">
                  <b style={{ color: "white" }}>Links</b>
                </p>
              </div>
              {links.map(link => (
                <div key={link.title}>
                  <a
                    style={{ ...mainStyle.text, color: "white" }}
                    href={link.href}
                  >
                    <div id="left">{link.title}</div>
                  </a>
                </div>
              ))}
            </Grid>
            <Grid item xs={6} md={4} id="container">
              <div>
                <p id="left">
                  <b style={{ color: "white" }}>Contact</b>
                </p>
              </div>

              <div>
                <a
                  href="mailto:wade@wadejr.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    ...mainStyle.text,
                    color: "white",
                    wordWrap: "break-word"
                  }}
                >
                  <div id="left">wade@wadejr.com</div>
                </a>
              </div>
              <div>
                <a
                  href="tel:17273247781"
                  style={{ ...mainStyle.text, color: "white" }}
                >
                  <div id="left">+1.727.324.7781</div>
                </a>
              </div>
            </Grid>
          </Grid>
          <div style={{ color: "white" }}>
            <p className="text-center mt-5 mb-1">
              Designed & Built by Wade Jr. 😄
            </p>
            <p className="text-center">© {getYear()} Wade Jr. Marketing</p>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
