import React from 'react'
import Terms from '../../components/LMS/Legal/TermsAndConditions'


const TermsAndConditions = (props) => {
    return (
        <div>
            <Terms {...props}/>
        </div>
    )
};

export default TermsAndConditions;