import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import mainStyle from "../../styles/LMS/mainStyle";
import { Divider } from "@material-ui/core";
import { ShowHide } from "../utility";
import ColorTextField from "../ColorTextField";
import ArrowDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowUpIcon from "@material-ui/icons/ArrowDropUp";

const CardTitle = props => {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(false);

  useEffect(() => {
    props.getSearch(search);
  }, [search]);

  useEffect(() => {
    props.getSort(sort);
  }, [sort]);

  return (
    <div>
      <div
        className="pt-4 mb-4 px-3"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
        id="cardTitle"
      >
        <div
          style={
            props.sort
              ? {
                  height: "50px",
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center"
                }
              : { height: "50px" }
          }
          onClick={
            props.sort
              ? () => {
                  setSort(!sort);
                }
              : null
          }
        >
          <h3 style={{ marginTop: "18px", color: "#3d5170" }}>{props.title}</h3>
          <ShowHide show={props.sort}>
            <div style={{ paddingTop: "10px" }}>
              <ShowHide
                show={sort}
                placeholder={
                  <ArrowUpIcon style={{ color: mainStyle.defaultColor }} />
                }
              >
                <ArrowDownIcon style={{ color: mainStyle.defaultColor }} />
              </ShowHide>
            </div>
          </ShowHide>
        </div>
        <div
          style={{
            width: "250px",
            maxWidth: "50%"
          }}
        >
          <ShowHide show={props.search}>
            <ColorTextField
              label="Search"
              color={mainStyle.defaultColor}
              value={search}
              onChange={e => setSearch(e.target.value)}
              style={{
                paddingTop: "0px",
                marginTop: "0px",
                width: "100%"
              }}
            />
          </ShowHide>
        </div>
      </div>
      <div>
        <Divider />
      </div>
    </div>
  );
};

CardTitle.propTypes = {
  title: PropTypes.string.isRequired,
  search: PropTypes.bool,
  getSearch: PropTypes.func,
  getSort: PropTypes.func
};

CardTitle.defaultProps = {
  getSearch: f => {},
  getSort: f => {}
};

export default CardTitle;
