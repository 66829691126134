import React from "react";
export default function({ description, title, url, keywords }) {
  return (
    <React.Fragment>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta
        name="description"
        content={description}
      />
      <meta name="keywords" content={keywords || "wade jr., wade, jr. wade junior, junior, computer engineer, software developer, tampa, fl, florida, largo, clearwater, entreprenuer, marketing, digital marketing"} />
      <meta
        name="robots"
        content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <link rel="canonical" href={`//${url}`} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta
        property="og:title"
        content={title}
      />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`https://${url}`} />
      <meta property="og:site_name" content="Wade Jr." />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={title} />
    </React.Fragment>
  );
}
