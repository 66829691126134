import React from "react";
import PropTypes from "prop-types";

const styles = {
    bubble: {
        height: '100px',
        width: '100px',
        borderRadius: '50% 50% 50% 5px ',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    circle: {
        height: '100px',
        width: '100px',
        borderRadius: '50%',
        backgroundColor: 'black'
    }
};

const IconBackground = props => {
    let shape = props.shape === 'bubble' ? styles.bubble : styles.circle
    let background = props.gradient ? { backgroundImage: props.background } : {backgroundColor: props.background}
 
    return (
    <div style={{...shape, ...background }}>
        <img
          alt={props.iconTitle}
          height="70"
          src={props.icon}
          title={props.iconTitle}
          style={{ ...props.iconStyle }}
        />
    </div>
  );
};

IconBackground.propTypes = {
  icon: PropTypes.string.isRequired,
  background: PropTypes.string,
  shape: PropTypes.string,
  iconStyle: PropTypes.object
};

export default IconBackground;
