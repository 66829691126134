import React from "react";
import { Grid, Hidden, Container } from "@material-ui/core";
import LmsImage from "./LmsImage";
import mainStyle from "../../../styles/Marketing/mainStyle";
import assistance from "../../../images/Marketing/assistance.svg";
import mail from "../../../images/Marketing/mail.svg";
import folder from "../../../images/Marketing/folder.svg";

const features = [
  {
    icon: assistance,
    title: "Convert More Leads",
    text:
      "Leads are sent directly to your email, as well as, LMS. Through LMS, We track the response times to place accountability on contacting the leads quickly."
  },
  {
    icon: mail,
    title: "Send Emails Quicker",
    text:
      "Make email templates to speed up the process of reaching out to your lead."
  },
  {
    icon: folder,
    title: "Keep Leads Organized",
    text:
      "LMS makes it easy to log converting and non-converting leads and retains them in the your archive."
  }
];

const LMS = () => {
  return (
    <div style={{ ...mainStyle.section }}>
      <Container fixed>
        <Grid container>
          <Grid item md={12} lg={6}>
           
              <LmsImage />
       
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <h2>Lead Management System</h2>
            {features.map(feature => (
              <div key={feature.icon} className="mt-3">
                <div className='mb-2'>
                  <img
                    alt={"feature icon"}
                    src={feature.icon}
                    title={feature.title}
                    style={{ height: "70px", width: "70px" }}
                  />
                </div>
                <div className='mb-2' style={{ ...mainStyle.text }}>
                  <b>{feature.title}</b>
                </div>
                <div style={{ ...mainStyle.text }}>{feature.text}</div>
              </div>
            ))}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default LMS;
