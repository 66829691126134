import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  ListItem,
  List,
  SwipeableDrawer,
  Divider
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import mainStyle from "../../../styles/Personal/mainStyle";
import OnlyLogoWhite from "../../../images/Personal/OnlyLogoWhite";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  list: {
    width: 250
  }
});

const styles = {
  root: {
    position: "fixed",
    top: "15px",
    left: "15px",
    zIndex: 100
  }
};

const links = [
  {
    title: "Home",
    href: "/",
    samePage: true
  },
  {
    title: "About",
    href: "/#about",
    samePage: true
  },
  {
    title: "Projects",
    href: "/#projects",
    samePage: true
  },
  {
    title: "Skills",
    href: "/#skills",
    samePage: true
  },
  {
    title: "Contact",
    href: "/contact",
    samePage: false
  }
];

const MyNavLink = props => {
  return (
    <React.Fragment>
      {props.link.samePage ? (
        <a
          style={{
            display: "block",
            height: "100%",
            width: "100%",
            color: "black",
            textDecoration: "none"
          }}
          href={props.link.href}
        >
          <ListItem button key={props.link.title}>
            <p>{props.link.title}</p>
          </ListItem>
        </a>
      ) : (
        <ListItem button>
          <Link
            style={{
              textDecoration: "none",
              color: "black",
              display: "block",
              height: "100%",
              width: "100%"
            }}
            title={props.link.title}
            to={props.link.href}
          >
            <p>{props.link.title}</p>
          </Link>
        </ListItem>
      )}
    </React.Fragment>
  );
};

const Nav = () => {
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const classes = useStyles();
  const [open] = React.useState(false);
  const [state, setState] = React.useState({
    left: false
  });

  const toggleDrawer = (side, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={classes.list}
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        <ListItem button>
          <div style={{ display: "flex" }}>
            <h2>Menu</h2>
            <ArrowForwardIcon
              style={{ marginLeft: "10px", marginTop: "4px", color: "red" }}
            />
          </div>
        </ListItem>
        {links.map(link => (
          <MyNavLink link={link} />
        ))}

        <a
          style={{
            display: "block",
            height: "100%",
            width: "100%",
            color: "black",
            textDecoration: "none"
          }}
          href="/Resume.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListItem button>
            <p>Resume</p>
          </ListItem>
        </a>

        <Divider style={{ backgroundColor: mainStyle.defaultColor }} />
        <ListItem button>
          <a
            style={{
              textDecoration: "none",
              color: "black",
              display: "block",
              height: "100%",
              width: "100%"
            }}
            title="Remy"
            href='https://www.hangoverremy.com'
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Remy</p>
          </a>
        </ListItem>
        <ListItem button>
          <a
            style={{
              textDecoration: "none",
              color: "black",
              display: "block",
              height: "100%",
              width: "100%"
            }}
            title="Dopa Nutrition"
            href='https://www.dopanutrition.com'
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Dopa Nutrition</p>
          </a>
        </ListItem>
        <ListItem button>
          <Link
            style={{
              textDecoration: "none",
              color: "black",
              display: "block",
              height: "100%",
              width: "100%"
            }}
            title="Wade Jr. Dental Marketing"
            to="/marketing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Marketing</p>
          </Link>
        </ListItem>

        {/* <a
          style={{
            display: "block",
            height: "100%",
            width: "100%",
            color: "black",
            textDecoration: "none"
          }}
          href="/marketing"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListItem button>
            <p>Marketing</p>
          </ListItem>
        </a> */}
      </List>
    </div>
  );

  return (
    <div style={{ ...styles.root }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={toggleDrawer("left", true)}
        className={clsx(open && classes.hide)}
        style={{ backgroundColor: "black", outline: "none" }}
      >
        <OnlyLogoWhite style={{ height: "40px", width: "40px" }} />
      </IconButton>

      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={state.left}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        {sideList("left")}
      </SwipeableDrawer>
    </div>
  );
};

export default Nav;
