import React from "react";
import Chip from "@material-ui/core/Chip";
import mainStyle from "../../../styles/Personal/mainStyle";
import image1 from "../../../images/Personal/LMS.png";
import "./style.css";

const styles = {
  root: {
    marginTop: "50px",
    marginBottom: "50px",
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  description: {
    backgroundColor: "grey",
    maxWidth: "500px",
    borderRadius: "5px",
    padding: "25px"
  },
  background: {
    marginLeft: "-40px",
    zIndex: "1",
    backgroundImage: `url(${image1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "347px",
    width: "557px",
    position: 'relative'
  },
  overlay: {
    backgroundColor: "rgb(255, 0, 0, .4)",
    height: "100%",
    width: "100%"
  }
};

const languages = [
  "React",
  "Node.js",
  "Express",
  "MongoDB",
  "Facebook API",
  "Heroku"
];

const FeaturedProject = () => {
  return (
    <div style={styles.root} id="root">
      <div id="content" style={{position: 'relative', zIndex: 100}}>
        <div id="featuredImage">
          <div>
            <h4 style={{ color: mainStyle.defaultColor }}>Featured Project</h4>
          </div>
          <div>
            <h3>Lead Management System</h3>
          </div>
          <div style={{ marginTop: "-5px" }} className="mb-3 text-muted">
            <h4>Personal</h4>
          </div>
          <div>
            <p style={styles.description} id="description">
              Deployed a full-stack CRM
              software to Heroku, using a MERN tech stack. This web app allows
              my marketing clients to keep record of how fast they contact their
              leads, automate emails, and manage their leads.
            </p>
          </div>
          <div style={{ maxWidth: "400px" }}>
            {languages.map(language => (
              <Chip className="m-1" label={language} />
            ))}
          </div>
        </div>
      </div>
        <div id="bg" style={styles.background}>
          <div style={styles.overlay}></div>
        </div>
    </div>
  );
};

export default FeaturedProject;
