import React from 'react'
import LegalTemplate from './LegalTemplate'

const PrivacyPolicy = (props) => {
    return (
        <div>
            <LegalTemplate name='Privacy Policy' {...props}>
            <h1>Welcome to our Privacy Policy</h1>
                <h3>Your privacy is critically important to us.</h3>
                Lead Management System is located at:<br/>
                <address>
                    Lead Management System<br/>6980 Ulmerton Rd #6f Largo
                    <br/>33771 - Florida , United States<br/>727-324-7781
                </address>

                <p>It is Lead Management System's policy to respect your privacy regarding any
                    information we may collect while operating our website. This Privacy Policy
                    applies to { ' ' }
                    <a href="http://www.wadejr.com">www.wadejr.com { ' ' }</a>
                    (hereinafter, "us", "we", or "www.wadejr.com"). We respect your privacy and are
                    committed to protecting personally identifiable information you may provide us
                    through the Website. We have adopted this privacy policy ("Privacy Policy") to
                    explain what information may be collected on our Website, how we use this
                    information, and under what circumstances we may disclose the information to
                    third parties. This Privacy Policy applies only to information we collect
                    through the Website and does not apply to our collection of information from
                    other sources.</p>
                <p>This Privacy Policy, together with the Terms and conditions posted on our
                    Website, set forth the general rules and policies governing your use of our
                    Website. Depending on your activities when visiting our Website, you may be
                    required to agree to additional terms and conditions.</p>

                <h2>Website Visitors</h2>
                <p>Like most website operators, Lead Management System collects
                    non-personally-identifying information of the sort that web browsers and servers
                    typically make available, such as the browser type, language preference,
                    referring site, and the date and time of each visitor request. Lead Management
                    System's purpose in collecting non-personally identifying information is to
                    better understand how Lead Management System's visitors use its website. From
                    time to time, Lead Management System may release non-personally-identifying
                    information in the aggregate, e.g., by publishing a report on trends in the
                    usage of its website.</p>
                <p>Lead Management System also collects potentially personally-identifying
                    information like Internet Protocol (IP) addresses for logged in users and for
                    users leaving comments on http://www.wadejr.com blog posts. Lead Management
                    System only discloses logged in user and commenter IP addresses under the same
                    circumstances that it uses and discloses personally-identifying information as
                    described below.</p>

                <h2>Gathering of Personally-Identifying Information</h2>
                <p>Certain visitors to Lead Management System's websites choose to interact with
                    Lead Management System in ways that require Lead Management System to gather
                    personally-identifying information. The amount and type of information that Lead
                    Management System gathers depends on the nature of the interaction. For example,
                    we ask visitors who sign up for a blog at http://www.wadejr.com to provide a
                    username and email address.</p>

                <h2>Security</h2>
                <p>The security of your Personal Information is important to us, but remember
                    that no method of transmission over the Internet, or method of electronic
                    storage is 100% secure. While we strive to use commercially acceptable means to
                    protect your Personal Information, we cannot guarantee its absolute security.</p>

                <h2>Links To External Sites</h2>
                <p>Our Service may contain links to external sites that are not operated by us.
                    If you click on a third party link, you will be directed to that third party's
                    site. We strongly advise you to review the Privacy Policy and terms and
                    conditions of every site you visit.</p>
                <p>We have no control over, and assume no responsibility for the content,
                    privacy policies or practices of any third party sites, products or services.</p>

                <h2>Aggregated Statistics</h2>
                <p>Lead Management System may collect statistics about the behavior of visitors
                    to its website. Lead Management System may display this information publicly or
                    provide it to others. However, Lead Management System does not disclose your
                    personally-identifying information.</p>

                <h2>Cookies</h2>
                <p>To enrich and perfect your online experience, Lead Management System uses
                    "Cookies", similar technologies and services provided by others to display
                    personalized content, appropriate advertising and store your preferences on your
                    computer.</p>
                <p>A cookie is a string of information that a website stores on a visitor's
                    computer, and that the visitor's browser provides to the website each time the
                    visitor returns. Lead Management System uses cookies to help Lead Management
                    System identify and track visitors, their usage of http://www.wadejr.com, and
                    their website access preferences. Lead Management System visitors who do not
                    wish to have cookies placed on their computers should set their browsers to
                    refuse cookies before using Lead Management System's websites, with the drawback
                    that certain features of Lead Management System's websites may not function
                    properly without the aid of cookies.</p>
                <p>By continuing to navigate our website without changing your cookie settings,
                    you hereby acknowledge and agree to Lead Management System's use of cookies.</p>

                <h2>Privacy Policy Changes</h2>
                <p>Although most changes are likely to be minor, Lead Management System may
                    change its Privacy Policy from time to time, and in Lead Management System's
                    sole discretion. Lead Management System encourages visitors to frequently check
                    this page for any changes to its Privacy Policy. Your continued use of this site
                    after any change in this Privacy Policy will constitute your acceptance of such
                    change.</p>

                
                <p></p>

                <h2>Credit & Contact Information</h2>
                <p>This privacy policy was created at
                    <a
                        style={{color: 'inherit', textDecoration:'none'}}
                        href="https://termsandconditionstemplate.com/privacy-policy-generator/"
                        title="Privacy policy template generator"
                        target="_blank"
                        rel="noopener noreferrer">termsandconditionstemplate.com</a>. If you have any questions about this Privacy Policy, please contact us via
                    <a href="mailto:wadealsmith@gmail.com"> { ' ' } email</a>
                    { ' ' } or
                    <a href="tel:7273247781"> { ' ' } phone</a>.</p>
            </LegalTemplate>

        </div>
    )
};

export default PrivacyPolicy;