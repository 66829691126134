import React, { useEffect, useState } from "react";
import { Paper, List, Divider, ButtonGroup, Button } from "@material-ui/core";
import CardTitle from "../../../misc/LMS/CardTitle";
import { ShowHide } from "../../../misc/utility";
import mainStyle from "../../../styles/LMS/mainStyle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ColorButton from "../../../misc/ColorButton";

const styles = {};

const items = [
  {
    title: "mucho",
    _id: 1
  },
  {
    title: "mango",
    _id: 1
  },
  {
    title: "not mucho",
    _id: 1
  },
  {
    title: "mucho mange",
    _id: 1
  }
];

const Item = ({ item }) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <div style={{ ...mainStyle.text }}>{item.title}</div>
        <div>
          <ButtonGroup variant="contained">
            <ColorButton
              contained
              noRadius
              textColor="white"
              color={mainStyle.buttonColor}
              hoverColor={mainStyle.hoverColor}
              type="submit"
              style={{
                ...mainStyle.button
              }}
            >
              <EditIcon />
            </ColorButton>
            <ColorButton
              contained
              noRadius
              textColor="white"
              color={"#c8c8c8"}
              hoverColor={"#c5c5c5"}
              type="submit"
              style={{
                ...mainStyle.button
              }}
            >
              <DeleteForeverIcon />
            </ColorButton>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};

const CurrentItems = props => {
  const ItemsList = () => (
    <List>
      {items.map((item, idx) => (
        <div>
          <div className="p-3">
            <Item item={item} />
          </div>
          <ShowHide show={idx !== items.length - 1}>
            <Divider variant="fullWidth" />
          </ShowHide>
        </div>
      ))}
    </List>
  );

  let title;

  switch (props.index) {
    case 0:
      title = "Notes";
      break;
      
      case 1:
        title = "Email Templates";
      break;
    default:
      title = "Notes";
  }

  return (
    <Paper
      style={props.index === 2 ? { display: "none" } : null}
      elevation={5}
      className="mt-3"
    >
      <CardTitle title={title} />
      <ItemsList />
    </Paper>
  );
};

export default CurrentItems;
