import {
  CONTACT_LOADING,
  ADD_CONTACT,
  ADD_CONTACT_SUCCESS,
  CONTACT_ERROR,
  DOWNLOAD,
  DOWNLOAD_ERROR,
  DOWNLOAD_LOADING,
  DOWNLOAD_SUCCESS,
  COMPLETED
} from "../types";

const initialState = {
  contact: {
    fullName: "",
    email: "",
    phone: "",
    questions: []
  },
  contactLoading: false,
  message: "",
  success: false
};

export default function(state = initialState, action) {
  switch (action.type) {
      
    case ADD_CONTACT:
      return {
        ...state,
        contact: action.payload
      };

    case ADD_CONTACT_SUCCESS:
      return {
        ...state,
        message: "Thank you, I will contact you shortly.",
        contactLoading: false,
        success: true
      };

    case CONTACT_ERROR:
      return {
        ...state,
        message: action.message,
        contactLoading: false
      };

    case CONTACT_LOADING:
      return {
        ...state,
        contactLoading: true
      };
    case DOWNLOAD:
      return {
        ...state,
        contact: action.payload
      };

    case DOWNLOAD_SUCCESS:
      return {
        ...state,
        contactLoading: false,
        success: true
      };

    case DOWNLOAD_ERROR:
      return {
        ...state,
        message: action.message,
        contactLoading: false
      };
    case COMPLETED:
      return {
        ...state,
        success: false
      };

    case DOWNLOAD_LOADING:
      return {
        ...state,
        contactLoading: true
      };

    default:
      return state;
  }
}
