import React from "react";
// import PropTypes from "prop-types";
import image1 from "../../../images/Marketing/LMS.png";
import image1Lazy from "../../../images/Marketing/LMS-min-1.png";
import LazyLoad from "react-lazyload";
import './style.css'

// const styles = {
//   background: {
//     backgroundColor: "black",
//     height: "500px",
//     width: "500px"
//   }
// };

const LmsImage = props => {
  return (
    <div>
      <LazyLoad
        once
        height={500}
        offset={200}
        placeholder={
          <img
            alt={"Lead Management System for Dental Clients"}
            src={image1Lazy}
            title={"Lead Management System for Dental Clients"}
           
            id='lmsImage'
          />
        }
      >
        <img
          alt={"Lead Management System for Dental Clients"}
          src={image1}
          title={"Lead Management System for Dental Clients"}
         
          id='lmsImage'
        />
      </LazyLoad>
    </div>
  );
};

export default LmsImage;
