import React from "react";
import { Grid, Container } from "@material-ui/core";
import ColorButton from "../../../misc/ColorButton";
import ScrollAnimation from "react-animate-on-scroll";
import mainStyle from "../../../styles/Marketing/mainStyle";
import LazyLoad from "react-lazyload";
import headshot from "../../../images/Marketing/headshot.jpg";
import headshotLazy from "../../../images/Marketing/headshot-min.jpg";
import { Link } from "react-router-dom";

const styles = {
  shadow: {
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
  }
};

const FewWords = () => {
  return (
    <div className="section">
      <Container fixed>
        <Grid container>
          <Grid item xs={12} md={6}>
            <div className="m-3" style={{ maxWidth: "500px" }}>
              <h2>Few Words About Wade</h2>
            </div>
            {/* <ScrollAnimation
              animateIn="fadeInLeft"
              initiallyVisible={false}
              animateOnce
            > */}
            <div className="m-3" style={{ maxWidth: "500px" }}>
              <p style={{ fontSize: "18px" }}>
                Wade is a Digital Marketer with a primary focus on
                <b> Dentists</b>. Wade's main goal is truly help every one of
                his clients grow their practice. What separates Wade from our
                competition is that he has a strategic system in place which
                allows us to <b>get high quality leads to convert</b>. Wade is a
                Computer Engineer with extensive marketing experience. He's
                developed a <b>Lead Management System</b> soley for our clients
                which will allow you to have the proper resources to{" "}
                <b>keep your leads organized</b> and it also provides you with
                analytics which emphasizes the{" "}
                <b>importance of contacting leads in a timely manner</b>. Wade
                is dedicated to maximizing your return on investment!
              </p>
            </div>
            {/* </ScrollAnimation> */}
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOnce
              initiallyVisible={false}
            >
              <div className="m-3">
                <ColorButton
                  contained
                  noRadius
                  color={mainStyle.buttonColor}
                  href="/marketing/get-started"
                  hoverColor={mainStyle.hoverColor}
                  style={{
                    ...mainStyle.bigButton
                  }}
                >
                  Get Started
                </ColorButton>
              </div>
            </ScrollAnimation>
          </Grid>

          <Grid item xs={12} md={6} className="mt-auto mb-auto">
            <LazyLoad
              once
              height={200}
              offset={[-200, 200]}
              placeholder={
                <img
                  src={headshotLazy}
                  alt="Headshot of Wade Jr."
                  title="Wade Jr. Marketing Consultant"
                  style={{ maxWidth: "100%", ...styles.shadow }}
                />
              }
            >
              <img
                src={headshot}
                alt="Headshot of Wade Jr."
                title="Wade Jr. Marketing Consultant"
                style={{ maxWidth: "100%", ...styles.shadow }}
              />
            </LazyLoad>
            <p className="text-center" style={{ fontSize: "18px" }}>
              <i>
                Find out more about our Founder/CEO by clicking{" "}
                <Link
                  to="/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Wade Jr. Portfolio"
                >
                  here
                </Link>
                .
              </i>
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default FewWords;
