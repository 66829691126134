import {
  LOGIN,
  LOGOUT,
  LOGIN_LOADING,
  RECAPTCHA,
  RECAPTCHA_LOADING,
  LOGIN_ERROR,
  LOGIN_ERROR_SENT,
  RECOVER_PASSWORD,
  RESET_PASSWORD,
  RECOVER_ERROR,
  RESET_ERROR,
  REGISTER,
  REGISTER_ERROR
} from "../types";

const initialState = {
  token: "",
  loading: false,
  success: false,
  recaptchaLoading: false,
  message: "",
  status: 200,
  resetLoading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.payload,
        loading: false
      };

    case REGISTER:
      return {
        ...state,
        token: action.payload,
        loading: false
      };
      case LOGOUT:
        return {
          ...state,
          ...initialState
        };
    case LOGIN_ERROR:
      return {
        ...state,
        message: action.message,
        status: action.status,
        loading: false
      };
    case RECOVER_PASSWORD:
      return {
        ...state,
        message: action.message,
        status: action.status
      };

    case RESET_PASSWORD:
      return {
        ...state,
        message: action.message,
        status: action.status,
        resetLoading: false
      };
    case LOGIN_ERROR_SENT:
      return {
        ...state,
        status: 200,
        loading: false
      };
    case RECAPTCHA:
      return {
        ...state,
        success: action.payload,
        status: 200,
        recaptchaLoading: false
      };
    case RESET_ERROR:
      return {
        ...state,
        message: action.message,
        status: action.status,
        loading: false
      };

    case RECOVER_ERROR:
      return {
        ...state,
        message: action.message,
        status: action.status,
        loading: false
      };
    case REGISTER_ERROR:
      return {
        ...state,
        message: action.message,
        status: action.status,
        loading: false
      };

    case RECAPTCHA_LOADING:
      return {
        ...state,
        recaptchaLoading: true
      };

    case LOGIN_LOADING:
      return {
        ...state,
        status: null,
        loading: true
      };

    default:
      return state;
  }
}
