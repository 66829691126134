import React, { useEffect, useState } from "react";
import { Paper, Grid } from "@material-ui/core";
import CardTitle from "../../../misc/LMS/CardTitle";
import mainStyle from "../../../styles/LMS/mainStyle";
import { connect, useSelector } from "react-redux";
import { leadAndCustomerCount } from "../../../redux/LMS/actions/leadActions";
import { ShowHide } from "../../../misc/utility";
import { ClipLoader } from "react-spinners";

const styles = {};

const MoreInfo = props => {
  const lead = useSelector(state => state.lead);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.leadAndCustomerCount();
  }, []);

  useEffect(() => {
    if (lead.stats) {
      setStats(lead.stats);
    }
  }, [lead.stats]);

  useEffect(() => {
    setLoading(lead.countLoading);
  }, [lead.countLoading]);

  return (
    <div>
      <Paper elevation={5}>
        <CardTitle title="Statistics" />
        <ShowHide
          show={stats && !loading}
          placeholder={
            <ShowHide
              show={!loading}
              placeholder={
                <div className="text-center py-3">
                  <ClipLoader
                    sizeUnit={"px"}
                    size={40}
                    color={mainStyle.defaultColor}
                    loading={loading}
                  />
                </div>
              }
            >
              <p className="text-muted text-center py-3">No data.</p>
            </ShowHide>
          }
        >
          <Grid container className="px-3 pt-3">
            <Grid item xs={6}>
              <div
                className="text-muted"
                style={{ ...mainStyle.text, fontSize: "16px" }}
              >
                Campaigns
              </div>
              <div style={{ ...mainStyle.text, fontSize: "24px" }}>
                <b>{stats && stats.campaigns}</b>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                className="text-muted"
                style={{ ...mainStyle.text, fontSize: "16px" }}
              >
                New Customers
              </div>
              <div style={{ ...mainStyle.text, fontSize: "24px" }}>
                <b>{stats && stats.customerCount}</b>
              </div>
            </Grid>
          </Grid>
          <Grid container className="p-3">
            <Grid item xs={6}>
              <div
                className="text-muted"
                style={{ ...mainStyle.text, fontSize: "16px" }}
              >
                Leads
              </div>
              <div style={{ ...mainStyle.text, fontSize: "24px" }}>
                <b>{stats && stats.leadsCount}</b>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                className="text-muted"
                style={{ ...mainStyle.text, fontSize: "16px" }}
              >
                Impressions
              </div>
              <div style={{ ...mainStyle.text, fontSize: "24px" }}>
                <b>3680</b>
              </div>
            </Grid>
          </Grid>
        </ShowHide>
      </Paper>
    </div>
  );
};

const actions = {
  leadAndCustomerCount
};
export default connect(null, actions)(MoreInfo);
