// Default Imports
import Four04 from "./Default/Four04";

// Wade Jr Imports
import Personal from "./Personal/LandingPage";
import Contact from "./Personal/Contact";

// Marketing Imports
import LandingPage from "./Marketing/LandingPage";
import FAQ from "./Marketing/FAQ";
import MarketingContact from "./Marketing/Contact";
import GetStarted from "./Marketing/GetStarted";

// Lms Imports
import Login from "./LMS/Login";
import Recover from "./LMS/Recover";
import PrivacyPolicy from "./LMS/PrivacyPolicy";
import TermsAndConditions from "./LMS/TermsAndConditions";
import Dashboard from "./LMS/Dashboard";
import Lead from "./LMS/Lead";
// import Admin from "./LMS/Admin";
import Archive from "./LMS/Archive";
import Profile from "./LMS/Profile";
import Template from "./LMS/Template";

export default {
  Four04: Four04,
  Personal: Personal,
  Contact: Contact,
  LandingPage: LandingPage,
  FAQ: FAQ,
  MarketingContact: MarketingContact,
  GetStarted: GetStarted,
  Login: Login,
  Recover: Recover,
  PrivacyPolicy: PrivacyPolicy,
  TermsAndConditions: TermsAndConditions,
  Dashboard: Dashboard,
  Lead: Lead,
//   Admin: Admin,
  Archive: Archive,
  Profile: Profile,
  Template: Template
};
