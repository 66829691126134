import React, { useEffect, useState } from "react";
import { Slide, Zoom, Fade, Paper } from "@material-ui/core";
import image from "../../../images/Personal/suit.jpg";
import Logo from "../../../images/Personal/Logo";
import { ShowHide } from "../../../misc/utility";
import { ClipLoader } from "react-spinners";

let zIndex = 11100;
const styles = {
  loading: {
    position: "fixed",
    zIndex: zIndex,
    height: "100vh",
    width: "100vw",
    backgroundColor: "white",
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: 'cover'
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    position: "relative"
  },
  loader: {
    position: "absolute",
    bottom: "20%",
    left: "50%",
    marginLeft: "-25px"
  }
};

const Screen = () => {
  return (
    <div style={styles.loading}>
      <div style={styles.logo}>
        <div className="text-center">
          <div style={{paddingTop: '125px'}}/>
          <Logo
            style={{
              height: "50%",
              width: "50%",
              zIndex: zIndex + 1
            }}
          />
          <div style={{paddingTop: '100px'}}>
            <ClipLoader
              sizeUnit={"px"}
              size={50}
              color={"#ff0000"}
              loading={true}
            />
          </div>
        </div>
      </div>
      {/* <div style={styles.loader} className="text-center">
            <ClipLoader
              sizeUnit={"px"}
              size={50}
              color={"#ff0000"}
              loading={true}
            />
          </div> */}
    </div>
  );
};

const LoadingScreen = props => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <div>
      <ShowHide show={!loading} variation="fade" placeholder={<Screen />}>
        {props.children}
      </ShowHide>
    </div>
  );
};

export default LoadingScreen;
