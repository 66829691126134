import React from "react";
import { TextField, FormControl, Select, MenuItem } from "@material-ui/core";

const Page2 = props => {
  const info = props.info;
  const setInfo = props.setInfo;

  const onQ1Change = e => setInfo({ ...info, q1: e.target.value });
  const onQ2Change = e => setInfo({ ...info, q2: e.target.value });
  const onQ3Change = e => setInfo({ ...info, q3: e.target.value });
  const onQ4Change = e => setInfo({ ...info, q4: e.target.value });

  return (
    <div>
      <div className="mb-3">
        <p className="mb-1">Have you tried any form of marketing before?</p>
        <FormControl variant="outlined" style={{ width: "100%" }}>
          <Select
            id="q1"
            value={info.q1}
            onChange={onQ1Change}
            style={{ width: "100%" }}
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
      </div>
      {info.q1 ? (
        <div className="mb-3">
          <p className="mb-1">How did it work for you?</p>
          <TextField
            id="q2"
            value={info.q2}
            onChange={onQ2Change}
            variant="outlined"
            style={{ width: "100%" }}
          />
        </div>
      ) : (
        <div></div>
      )}

      <div className="mb-3">
        <p className="mb-1">Do you currently have a marketing team?</p>
        <FormControl variant="outlined" style={{ width: "100%" }}>
          <Select
            id="q3"
            value={info.q3}
            onChange={onQ3Change}
            style={{ width: "100%" }}
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
      </div>
      {info.q3 ? (
        <div style={{ marginBottom: "35px" }}>
          <p className="mb-1">
            Are there any problems that you're currently facing with them?
          </p>
          <TextField
            id="q4"
            value={info.q4}
            onChange={onQ4Change}
            variant="outlined"
            style={{ width: "100%" }}
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Page2;
