import React from 'react'
import Form from '../../components/LMS/Login/Form'


const Login = (props) => {
    return (
        <div>
            <Form {...props}/>
        </div>
    )
};

export default Login;