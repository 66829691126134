import React from "react";
import SkillBar from "react-skillbars";
import Title from "../../../misc/Personal/Title";
import { Container, Grid } from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import mainStyle from "../../../styles/Personal/mainStyle";

const styles = {
  left: {
    marginBottom: 0,
    paddingBottom: 0,
    marginTop: 0,
    paddingTop: 0
  },
  right: {
    marginTop: 0,
    paddingTop: 0
  }
};

const skills1 = [
  {
    type: "React",
    level: 100
  },
  {
    type: "NoSQL",
    level: 100
  },
  {
    type: "HTML",
    level: 100
  },
  {
    type: "Node.js",
    level: 100
  },
  {
    type: "CSS/SCSS",
    level: 95
  },
  {
    type: "SQL",
    level: 90
  },
  {
    type: "TypeScript",
    level: 85
  },
  {
    type: "C#",
    level: 80
  },
  {
    type: "Java",
    level: 80
  },
  {
    type: "C++",
    level: 75
  }
];
const skills2 = [
  {
    type: "Django",
    level: 75
  },
  {
    type: "Golang",
    level: 75
  },
  {
    type: "Rails",
    level: 75
  },
  {
    type: "C",
    level: 70
  },

  {
    type: "Asp.Net",
    level: 70
  },
  {
    type: "Docker",
    level: 70
  },
  {
    type: "Native",
    level: 70
  },

  {
    type: "Angular7",
    level: 60
  },

  {
    type: "MATLAB",
    level: 60
  },

  {
    type: "Vue",
    level: 50
  }
];

const otherSkills1 = [
  "AWS",
  "Git",
  "Selenium",
  "Database Design",
  "Windows and Linux OS",
  "Restful Web Services",
  "Express",
  "Redux",
  "Twilio",
  "Azure"
];
const otherSkills2 = [
  "TCP / IP",
  "MySQL",
  "Cyber Security",
  "PostgreSql",
  "Automation",
  "MongoDB",
  "Heroku",
  "Testing",
  "React Hooks",
  "Agile"
];

const colors = {
  bar: "#888",
  title: {
    text: "#fff",
    background: mainStyle.defaultColor
  }
};

const Skills = () => {
  return (
    <div id="skills" className="section">
      <Container fixed>
        <Title className="mb-1">Skills</Title>
        <div className="mb-5">
          <h4>
            <span style={{ color: mainStyle.defaultColor }}>
              Current Stack:
            </span>{" "}
            MERN
          </h4>
        </div>
        <div className="mb-5 scroll">
          <Grid className='pt-3' container row spacing={4}>
            <Grid item style={styles.left} xs={12} md={6}>
              <SkillBar
                animationDelay={0}
                offset={0}
                skills={skills1}
                colors={colors}
              />
            </Grid>
            <Grid item style={styles.right} xs={12} md={6}>
              <SkillBar
                animationDelay={0}
                offset={0}
                skills={skills2}
                colors={colors}
              />
            </Grid>
          </Grid>
        </div>
        <div className="mb-3">
          <h3 className="text-center">Other Technologies</h3>
        </div>
        <div className='scroll'>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            <Grid item style={styles.left} xs={12} md={6}>
              {otherSkills1.map(skill => (
                <div className="mb-1" style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "auto",
                      marginLeft: "auto",
                      width: "300px"
                    }}
                  >
                    <div style={{}}>
                      <ArrowRightIcon
                        className="mr-1"
                        style={{ color: mainStyle.defaultColor }}
                      />
                    </div>
                    <div style={{ ...mainStyle.text}}>{skill}</div>
                  </div>
                </div>
              ))}
            </Grid>
            <Grid item style={styles.right} xs={12} md={6}>
              {otherSkills2.map(skill => (
                <div className="mb-1" style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "auto",
                      marginLeft: "auto",
                      width: "300px"
                    }}
                  >
                    <div style={{}}>
                      <ArrowRightIcon
                        className="mr-1"
                        style={{ color: mainStyle.defaultColor }}
                      />
                    </div>
                    <div style={{ ...mainStyle.text}}>{skill}</div>
                  </div>
                </div>
              ))}
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default Skills;
