import React, { useEffect, useState } from "react";
import { Paper, TextField } from "@material-ui/core";
import ColorButton from "../../../misc/ColorButton";
import mainStyle from "../../../styles/LMS/mainStyle";

const styles = {};

const NoteTemplate = props => {
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");

  const onChange = e => {
    setNote(e.target.value);
  };

  const submit = e => {
    e.preventDefault();
    setNote("wade");
  };
  return (
    <div>
      <Paper elevation={5} className="p-3">
        <form onSubmit={submit}>
          <div className="mb-3">
            <p className="mb-1">
              Title{" "}
              <span
                style={{
                  color: "red"
                }}
              >
                *
              </span>
            </p>
            <TextField
              id="title"
              value={title}
              onChange={e => setTitle(e.target.value)}
              required
              variant="outlined"
            />
          </div>
          <textarea
            style={{ width: "100%", outline: "none", padding: "5px" }}
            className="pb-3"
            onChange={e => {
              onChange(e);
            }}
            value={note}
            rows="5"
            placeholder="Add a note"
            required
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <ColorButton
              contained
              noRadius
              // disabled={loading}
              textColor="white"
              color={mainStyle.buttonColor}
              hoverColor={mainStyle.hoverColor}
              type="submit"
              style={{
                ...mainStyle.button,
                fontSize: "18px",
                padding: "10px 24px"
              }}
            >
              Add
            </ColorButton>

            <ColorButton
              contained
              noRadius
              // disabled={loading}
              textColor="white"
              color={"#c8c8c8"}
              hoverColor={"#c5c5c5"}
              style={{
                ...mainStyle.button,
                fontSize: "18px",
                padding: "10px 24px"
              }}
            >
              Clear
            </ColorButton>
          </div>
        </form>
      </Paper>
    </div>
  );
};

export default NoteTemplate;
