import React, { useState } from "react";
import { List, ListItem, Divider, Grid } from "@material-ui/core";
import mainStyle from "../../../styles/Personal/mainStyle";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { fadeIn, fadeOut } from "react-animations";
import Radium, { StyleRoot } from "radium";
import "./style.css";

const styles = {
  sidebar: {
    paddingTop: 0,
    paddingBottom: 0,
    width: "150px"
  },
  fadeIn: {
    animation: "x .5s",
    animationName: Radium.keyframes(fadeIn, "fadeIn")
  },
  fadeOut: {
    animation: "x .5s",
    animationName: Radium.keyframes(fadeOut, "fadeOut")
  }
};

const jobs = [
  {
    company: "GoDaddy",
    date: "Jun 2020 -  Present",
    role: "Software Engineer II",
    description: [
      "Collaborating with team to develop GoDaddy's internal backend systems.",
      "Writing unit tests to ensure efficiencies using mocha, jest, and test-library.",
      "Collaborated with a team to create GoDaddy's new payment method page using React.",
      "Created integration tests to ensure individual software modules operate correctly when connected, using cypress.",
      "Provided assistance and guidance to other co-workers in the learning process."
    ]
  },
  {
    company: "GSL Solutions",
    date: "Jun 2020 - Nov 2020",
    role: "Full-Stack Developer",
    description: [
      "Created a website application to display large amounts of data in various charts with React and Redux.",
      "Lead team in creating a Snapchat replica using Meteor and React.",
      "Utilized an existing framework to create a Gmail replica with HTML and CSS."
    ]
  },
  {
    company: "Self Employed",
    date: "Jun 2017 - May 2018, Jan 2019 - Jun 2020",
    role: "Freelance Full-Stack Developer",
    description: [
      "Working remotely and collaborating with clients to discuss requirements, deadlines and completed work.",
      "Developing creative solutions for websites and web applications from scratch, using a MERN tech stack.",
      "Utilizing excellent organizational, problem-solving, and interpersonal skills to propel client projects to peak results."
    ]
  },
  {
    company: "ISPA Technology",
    date: "Jun 2019 - Jun 2020",
    role: "Full-Stack Developer",
    description: [
      "Collaborating with a team remotely to build out a full-stack web application using React and Golang.",
      "Utilizing Material UI framework to build out complex front-end solutions."
    ]
  },
  {
    company: "Uptimize Digital Marketing",
    date: "Mar 2019 - Nov 2019",
    role: "Co-Founder and President",
    description: [
      "Lead a team of 5 remotely to develop websites for various clients.",
      "Delegated tasks and followed up on progression.",
      "Gathered all project information from clients and regularly update clients on progression."
    ]
  },
  {
    company: "Gulf Controls",
    date: "May 2018 - Jan 2019",
    role: "Intern",
    description: [
      "Developed an algorithm using C# to solve a Traveling Salesman Problem to potentially improve productivity by at least 15% a week for up to 29 different items.",
      "Proposed and developed an application to simplify the process of cutting heat shrink and increased productivity by at least 50% a day using C# and MySQL.",
      "Created a label printer application using C# and Asp.Net.",
      "Developed a webform used to display and search a database using C#, Asp.Net, JavaScript, and MySQL.",
      "Automated processing scanned documents by creating an OCR application using C#."
    ]
  }
];

const Jobs = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [jobIndex, setJobIndex] = useState(0);
  const [fadeIo, setFadeIo] = useState(1);

  const setIndex = index => {
    setFadeIo(0);
    setSelectedIndex(index);
    
    setTimeout(() => {
      setJobIndex(index)
      setFadeIo(1);
    }, 400);
  };
  const backgroundColor = "rgb(3, 3, 3, .1)";

  return (
    <div style={{minHeight: '430px'}}>
      <StyleRoot>
        <Grid container row>
          <Grid item xs={12} md={3} className='mb-3'>
            <List
              style={{ backgroundColor: backgroundColor, ...styles.sidebar }}
              className='listItem'
            >
              {jobs.map((job, idx) => (
                <a
                  style={{
                    display: "block",
                    height: "100%",
                    width: "100%",
                    color: idx === selectedIndex ? "white" : "black",
                    backgroundColor:
                      idx === selectedIndex
                        ? mainStyle.defaultColor
                        : backgroundColor,
                    textDecoration: "none"
                  }}
                  onClick={() => setIndex(idx)}
                  
                >
                  <ListItem className='listItem2' id={idx !== selectedIndex ? "listItem" : ""}>
                    {job.company}
                  </ListItem>
                  {idx !== jobs.length - 1 ? <Divider className='hideme' /> : <div></div>}
                </a>
              ))}
            </List>
          </Grid>

          <Grid item xs={12} md={9}>
            <div style={fadeIo === 1 ? styles.fadeIn : styles.fadeOut}>
              <div style={{ maxWidth: "600px" }}>
                <div>
                  <h3>
                    {jobs[jobIndex].role}{" "}
                    <span style={{ color: mainStyle.defaultColor }}>
                      <i>@</i> {jobs[jobIndex].company}
                    </span>
                  </h3>
                </div>
                <div>
                  <p>{jobs[jobIndex].date}</p>
                </div>
                <div>
                  {jobs[jobIndex].description.map(d => (
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-start"
                      spacing={0}
                      className="mb-2"
                    >
                      <Grid item xs={1}>
                        <ArrowRightIcon
                          style={{ color: mainStyle.defaultColor }}
                        />
                      </Grid>
                      <Grid item xs={11}>
                        <div style={{ ...mainStyle.text }}>{d}</div>
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </StyleRoot>
    </div>
  );
};

export default Jobs;
