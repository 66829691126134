import React from "react";
import Header from "../../components/Marketing/Header/Header";
import FAQS from "../../components/Marketing/FAQ/FAQ";
import Footer from "../../components/Marketing/Footer/Footer";
import Nav from "../../components/Marketing/Nav/Nav";
import { Helmet } from "react-helmet";
import Meta from "../../misc/Meta";
import ScrollBarColor from "../../misc/ScrollBarColor";
import ScrollToTop from "../../misc/ScrollToTop";

const meta = {
  title: "Wade Jr. FAQ for Dental Marketing | Dental Marketing Tampa, FL",
  description:
    "Frequently asked questions from my dental marketing clients. Wade JR. is actively look to help a dental office in the Tampa bay area.",
  url: "www.wadejr.com/marketing/faq"
};

const FAQ = () => {
  ScrollBarColor(
    "-webkit-linear-gradient(#2f55cc, #1de4be)",
    "#fff",
    null,
    "10px"
  );
  return (
    <div>
      <Helmet>
        <title>Wade Jr. | FAQ</title>
        <Meta
          title={meta.title}
          description={meta.description}
          url={meta.url}
        />
      </Helmet>
      <ScrollToTop>
        <Nav />
        <Header title="Frequently Asked Questions" />
        <FAQS />
        <Footer />
      </ScrollToTop>
    </div>
  );
};

export default FAQ;
