import React, { useEffect, useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import { Paper } from "@material-ui/core";
import mainStyle from "../../../styles/LMS/mainStyle";
import ColorButton from "../../../misc/ColorButton";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

const styles = {
  board: {
    minHeight: "500px",
    height: "500px",
    maxHeight: "70vh",
    width: "100%",
    background: "#E6E6E5"
  }
};

const notes = [
  {
    message:
      "I am the first note I am the first note I am the first note I am the first note I am the first note I am the first note I am the first note I am the first note I am the first note I am the first note I am the first note I am the first note I am the first note I am the first note ",
    date: "6/27/2019 at 9:30am"
  },
  {
    message: "I am the first note",
    date: "6/27/2019 at 9:30am"
  },
  {
    message: "I am the first note",
    date: "6/27/2019 at 9:30am"
  },
  {
    message: "I am the first note test test",
    date: "6/27/2019 at 9:30am"
  },
  {
    message: "I am the first note",
    date: "6/27/2019 at 9:30am"
  },
  {
    message: "I am the first note",
    date: "6/27/2019 at 9:30am"
  },
  {
    message: "I am the first note",
    date: "6/27/2019 at 9:30am"
  },
  {
    message: "I am the first note",
    date: "6/27/2019 at 9:30am"
  },
  {
    message: "I am the first note",
    date: "6/27/2019 at 9:30am"
  },
  {
    message: "I am the first note",
    date: "6/27/2019 at 9:30am"
  },
  {
    message: "I am the first note",
    date: "6/27/2019 at 9:30am"
  },
  {
    message: "I am the first note",
    date: "6/27/2019 at 9:30am"
  },
  {
    message: "I am the first note",
    date: "6/27/2019 at 9:30am"
  },
  {
    message: "I am the first note",
    date: "6/27/2019 at 9:30am"
  },
  {
    message: "I am the first note",
    date: "6/27/2019 at 9:30am"
  },
  {
    message: "I am the first note",
    date: "6/27/2019 at 9:30am"
  },
  {
    message: "I am the first note",
    date: "6/27/2019 at 9:30am"
  },
  {
    message: "I am the first note",
    date: "6/27/2019 at 9:30am"
  },
  {
    message: "I am the first note",
    date: "6/27/2019 at 9:30am"
  },
  {
    message: "I am the first note",
    date: "6/27/2019 at 9:30am"
  }
];

const Board = props => {
  return (
    <div className="py-3 pl-3 pr-2" style={styles.board}>
      <div style={{ overflowY: "auto", height: "100%" }} className="pr-1">
        {props.children}
      </div>
    </div>
  );
};

const Note = ({ note }) => {
  return (
    <div>
      <Paper className="mb-2" style={{ display: "inline-block" }}>
        <div style={{ textAlign: "right", cursor: "pointer" }} className="pr-1">
          <ClearIcon style={{ ...mainStyle.text }} />
        </div>
        <div className="p-2" style={{ ...mainStyle.text }}>
          {note.message}
        </div>
        <div
          style={{ textAlign: "right", ...mainStyle.text, fontSize: "12px" }}
          className="pr-1 text-muted"
        >
          {note.date}
        </div>
      </Paper>
    </div>
  );
};

const DropDown = props => {
  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!isOpen);

  return (
    <div>
      <ButtonDropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle
          caret
          color="primary"
          style={{
            ...mainStyle.button,
            fontSize: "18px",
            padding: "10px 24px",
            borderRadius: "0px"
          }}
        >
          Template
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem style={{ ...mainStyle.text, color: "black" }}>
            Another Action
          </DropdownItem>
          <DropdownItem style={{ ...mainStyle.text, color: "black" }}>
            Another Action
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );
};

const Message = props => {
  const [note, setNote] = useState("");

  const onChange = e => {
    setNote(e.target.value);
  };

  const submit = e => {
    e.preventDefault();
    setNote("wade");
  };
  return (
    <form onSubmit={submit}>
      <textarea
        style={{ width: "100%", outline: "none", padding: "5px" }}
        className="pb-2"
        onChange={e => {
          onChange(e);
        }}
        value={note}
        rows="2"
        placeholder="Add a note"
        required
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <ColorButton
          contained
          noRadius
          // disabled={loading}
          textColor="white"
          color={mainStyle.buttonColor}
          hoverColor={mainStyle.hoverColor}
          type="submit"
          style={{
            ...mainStyle.button,
            fontSize: "18px",
            padding: "10px 24px"
          }}
        >
          Add
        </ColorButton>
        <DropDown />

      </div>
    </form>
  );
};

const Notes = props => {
  return (
    <div>
      <Board>
        {notes.map(note => (
          <Note note={note} />
        ))}
      </Board>
      <div style={{ background: "#c0c0c0" }} className="p-3">
        <Message />
      </div>
    </div>
  );
};

export default Notes;
