import {
  GET_NEW_LEADS,
  ADD_LEAD,
  DELETE_LEAD,
  UPDATE_LEAD,
  LEADS_LOADING,
  COT_LOADING,
  COUNT_LOADING,
  SEND_EMAIL,
  CALLS_ON_TIME,
  GET_LEAD_AND_CUSTOMER_COUNT
} from "../types";

const initialState = {
  newLeads: [],
  archivedLeads: [],
  cbLeads: [],
  cot: null,
  stats: null,
  leadsLoading: false,
  cotLoading: false,
  countLoading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_NEW_LEADS:
      return {
        ...state,
        newLeads: action.payload,
        leadsLoading: false
      };
    case ADD_LEAD:
      return {
        ...state,
        leads: action.payload,
        leadsLoading: false
      };

    case DELETE_LEAD:
      return {
        ...state,
        leads: action.payload,
        leadsLoading: false
      };

    case UPDATE_LEAD:
      return {
        ...state,
        leads: action.payload,
        leadsLoading: false
      };

    case SEND_EMAIL:
      return {
        ...state,
        leadsLoading: false
      };

    case CALLS_ON_TIME:
      return {
        ...state,
        cot: action.payload,
        cotLoading: false
      };

    case GET_LEAD_AND_CUSTOMER_COUNT:
      return {
        ...state,
        stats: {
          leadsCount: action.leadsCount,
          customerCount: action.customerCount,
          campaigns: action.campaigns
        },
        countLoading: false
      };

    case LEADS_LOADING:
      return {
        ...state,
        leadsLoading: true
      };

    case COT_LOADING:
      return {
        ...state,
        cotLoading: true
      };

    case COUNT_LOADING:
      return {
        ...state,
        countLoading: true
      };

    default:
      return state;
  }
}
