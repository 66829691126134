import React from "react";
import ReactApexChart from "react-apexcharts";

// series: [44, 55, 13, 43, 22],
// options: {
//   chart: {
//     width: 380,
//     type: 'pie',
//   },
//   labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
//   responsive: [{
//     breakpoint: 480,
//     options: {
//       chart: {
//         width: 200
//       },
//       legend: {
//         position: 'bottom'
//       }
//     }
//   }]
// },

const PieChart = props => {
  return (
    <div>
      <ReactApexChart
        options={props.options}
        series={props.values}
        type="pie"
        width={380}
      />
    </div>
  );
};

// class PiChart extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       series: [44, 55, 13, 43, 22],
//       options: {
//         chart: {
//           width: 380,
//           type: "pie"
//         },
//         labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
//         responsive: [
//           {
//             breakpoint: 480,
//             options: {
//               chart: {
//                 width: 200
//               },
//               legend: {
//                 position: "bottom"
//               }
//             }
//           }
//         ]
//       }
//     };
//   }

//   render() {
//     return (
//       <div id="chart">
//         <ApexChart
//           options={this.state.options}
//           series={this.state.series}
//           type="pie"
//           width={380}
//         />
//       </div>
//     );
//   }
// }

export default PieChart;
