import React, { useEffect, useState } from "react";
import ResponsiveNavDrawer from "../../../misc/ResponsiveNavDrawer";
import Logo from "../../../images/LMS/LMS_Logo.png";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ArchiveIcon from "@material-ui/icons/Archive";
import SubjectIcon from "@material-ui/icons/Subject";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { splitPathName } from "../../../misc/utility";
import mainStyle from "../../../styles/LMS/mainStyle";

const styles = {};

const listItems = [
  {
    icon: <DashboardIcon />,
    text: "Dashboard",
    route: "/lms/dashboard"
  },
  {
    icon: <ArchiveIcon />,
    text: "Archive",
    route: "/lms/archive"
  },
  {
    icon: <SubjectIcon />,
    text: "Template",
    route: "/lms/template"
  },
  {
    icon: <AccountCircleIcon />,
    text: "Profile",
    route: "/lms/profile"
  },
  {
    icon: <ExitToAppIcon />,
    text: "Log out",
    route: "/lms?lo=true"
  }
];

const getSelected = text => {
  let pathname = splitPathName(window.location.pathname);
  if (text.toLowerCase() === pathname[pathname.length - 1].toLowerCase()) {
    return true;
  }
  return false;
};

const Layout = props => {
  const logo = (
    <div
      className="text-center"
      onClick={() => {
        props.history.push("/lms/dashboard");
      }}
      style={{ cursor: "pointer" }}
    >
      <img src={Logo} style={{ height: "100px", width: "100px" }} />
    </div>
  );

  const LI = ({ item }) => {
    let selected = getSelected(item.text);

    return (
      <ListItem
        button
        key={item.text}
        selected={selected}
        onClick={() => {
          props.history.push(item.route);
        }}
      >
        <ListItemIcon style={selected ? { color: "#0072ff" } : null}>
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.text}
          style={selected ? { color: "#0072ff" } : null}
        />
      </ListItem>
    );
  };

  const list = (
    <List>
      {listItems.map(item => (
        <LI item={item} />
      ))}
    </List>
  );

  return (
    <div>
      <ResponsiveNavDrawer logo={logo} list={list} background={mainStyle.alternateBackgroundColor.backgroundColor}>
          {props.children}
      </ResponsiveNavDrawer>
    </div>
  );
};

export default Layout;
