import React from "react";
import ColorButton from "../../../misc/ColorButton";
import mainStyle from "../../../styles/Marketing/mainStyle";
import { Container } from "@material-ui/core";

const styles = {
  center: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "50px",
    paddingBottom: "50px"
  },
  bg: {
    background: "rgb(47,85,204)",
    background:
      "radial-gradient(circle, rgba(47,85,204,0.9808298319327731) 0%, rgba(29,228,190,1) 100%)"
  }
};

const CallToAction = () => {
  return (
    <div id="section" style={styles.bg}>
      <Container fixed>
        <div className="text-center" style={styles.center}>
          <div style={{ display: "inline-block", color: 'white' }}>
            <h2>Transform Your Business – Drive More Leads and Revenue</h2>
            <p className="mb-3">
              Grow your business, get more customers and beat your competition –
              find out how
            </p>
            <ColorButton
              contained
              noRadius
              color={mainStyle.buttonColor}
              href="/marketing/get-started"
              hoverColor={mainStyle.hoverColor}
              style={{
                ...mainStyle.bigButton
              }}
            >
              Get Started
            </ColorButton>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CallToAction;
