import React, { useEffect, useState } from "react";
import CKEditor from "react-ckeditor-component";
import { TextField, Paper } from "@material-ui/core";
import ColorButton from "../../../misc/ColorButton";
import mainStyle from "../../../styles/LMS/mainStyle";
import { ClipLoader } from "react-spinners";
import ClearIcon from "@material-ui/icons/Clear";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

const styles = {};

const DropDown = props => {
  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!isOpen);

  return (
    <div>
      <ButtonDropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle
          caret
          color="primary"
          style={{
            ...mainStyle.button,
            fontSize: "18px",
            padding: "10px 24px",
            borderRadius: "0px"
          }}
        >
          Template
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem style={{ ...mainStyle.text, color: "black" }}>
            Another Action
          </DropdownItem>
          <DropdownItem style={{ ...mainStyle.text, color: "black" }}>
            Another Action
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );
};

const EmailTemplate = props => {
  const [title, setTitle] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);

  const onChange = evt => {
    var newContent = evt.editor.getData();
    setBody(newContent);
  };

  const submit = e => {
    e.preventDefault();
  };

  return (
    <Paper elevation={5} className="p-3">
      <form onSubmit={submit}>
        <div className="mb-3">
          <p className="mb-1">
            Title{" "}
            <span
              style={{
                color: "red"
              }}
            >
              *
            </span>
          </p>
          <TextField
            id="title"
            value={title}
            onChange={e => setTitle(e.target.value)}
            required
            variant="outlined"
            style={{}}
          />
        </div>
        <div className="mb-3">
          <p className="mb-1">
            Subject{" "}
            <span
              style={{
                color: "red"
              }}
            >
              *
            </span>
          </p>
          <TextField
            id="subject"
            value={subject}
            onChange={e => setSubject(e.target.value)}
            required
            variant="outlined"
            style={{}}
          />
        </div>
        <div className="mb-3">
          <CKEditor
            activeClass="p10"
            content={body}
            events={{
              //   blur: onBlur,
              // afterPaste: afterPaste,
              change: onChange
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
          className="mt-3"
        >
          <ColorButton
            contained
            noRadius
            // disabled={loading}
            textColor="white"
            color={mainStyle.buttonColor}
            hoverColor={mainStyle.hoverColor}
            type="submit"
            style={{
              ...mainStyle.button,
              fontSize: "18px",
              padding: "10px 24px"
            }}
          >
            Add
          </ColorButton>

          <ColorButton
            contained
            noRadius
            // disabled={loading}
            textColor="white"
            color={"#c8c8c8"}
            hoverColor={"#c5c5c5"}
            style={{
              ...mainStyle.button,
              fontSize: "18px",
              padding: "10px 24px"
            }}
          >
            Clear
          </ColorButton>
        </div>
      </form>
    </Paper>
  );
};

export default EmailTemplate;
