import {GET_ALL_CLIENTS, GET_ADMIN, ADD_ADMIN, UPDATE_ADMIN, DELETE_ADMIN, ADMIN_LOADING, CLIENTS_LOADING, ADMIN_ERROR, ADMIN_ERROR_SENT, NEW_ADMIN_ADDED} from '../types'

const initialState = {
    admin: null,
    clients: [],  
    clientsLoading: false,
    loading: false,
    status: 200,
    message: ''

}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_ADMIN:
            return {
                ...state,
                admin: action.admin,
                loading: false
            }
        case GET_ALL_CLIENTS:
            return {
                ...state,
                clients: action.payload,
                clientsLoading: false
            }
            
        case ADD_ADMIN:
            return {
                ...state,
                admin: action.payload,
                loading: false,
                status: action.status
            }  
        case NEW_ADMIN_ADDED:
            return {
                ...state,
                status: 200
            } 
        case UPDATE_ADMIN:
            return {
                ...state,
                admin: action.payload,
                loading: false
            }  
            
        case DELETE_ADMIN:
            return {
                ...state,
                admin: action.payload,
                loading: false
            } 
        case ADMIN_ERROR:
            return {
                ...state,
                status: action.status,
                message: action.message
            } 
            
        case ADMIN_ERROR_SENT:
            return {
                ...state,
                status: 200
            } 
                

        case ADMIN_LOADING:
            return {
                ...state,
                loading: true
            }

        case CLIENTS_LOADING:
            return {
                ...state,
                clientsLoading: true
            }
        default:
            return state
    }
}