//Login Type
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const RECAPTCHA = 'RECAPTCHA'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_ERROR_SENT = 'LOGIN_ERROR_SENT'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_ERROR = 'RESET_ERROR'
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD'
export const RECOVER_ERROR = 'RECOVER_ERROR'
export const REGISTER_ERROR = 'REGISTER_ERROR'
export const REGISTER = 'REGISTER'

//Lead Types
export const GET_NEW_LEADS = 'GET_NEW_LEADS'
export const ADD_LEAD = 'ADD_LEAD'
export const DELETE_LEAD = 'DELETE_LEAD'
export const UPDATE_LEAD = 'UPDATE_LEAD'
export const SEND_EMAIL = 'SEND_EMAIL'
export const CALLS_ON_TIME = 'CALLS_ON_TIME'
export const GET_LEAD_AND_CUSTOMER_COUNT = 'GET_LEAD_AND_CUSTOMER_COUNT'

//Client Types
export const GET_CLIENT = 'GET_CLIENT'
export const UPDATE_CLIENT = 'UPDATE_CLIENT'
export const ADD_CLIENT = 'ADD_CLIENT'
export const NEW_CLIENT_ADDED = 'NEW_CLIENT_ADDED'
export const DELETE_CLIENT = 'DELETE_CLIENT'
export const CLIENT_ERROR = 'CLIENT_ERROR'
export const CLIENT_ERROR_SENT = 'CLIENT_ERROR_SENT'


//FB Types
export const GET_FB_LEADS = 'GET_FB_LEADS'
export const GET_FB_FORM_IDS = 'GET_FB_FORM_IDS'
export const ADD_FB_FORM_ID = 'ADD_FB_FORM_ID'
export const UPDATE_FB_FORM_ID = 'UPDATE_FB_FORM_ID'
export const DELETE_FB_FORM_ID = 'DELETE_FB_FORM_ID'

//Template Types
export const GET_EMAIL_TEMPLATES = 'GET_EMAIL_TEMPLATES'
export const ADD_EMAIL_TEMPLATE = 'ADD_EMAIL_TEMPLATE'
export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE'
export const DELETE_EMAIL_TEMPLATE = 'DELETE_EMAIL_TEMPLATE'
export const GET_RESPONSE_TEMPLATES = 'GET_RESPONSE_TEMPLATES'
export const ADD_RESPONSE_TEMPLATE = 'ADD_RESPONSE_TEMPLATE'
export const UPDATE_RESPONSE_TEMPLATE = 'UPDATE_RESPONSE_TEMPLATE'
export const DELETE_RESPONSE_TEMPLATE = 'DELETE_RESPONSE_TEMPLATE'

//Admin Types
export const ADD_ADMIN = 'ADD_ADMIN'
export const NEW_ADMIN_ADDED = 'NEW_ADMIN_ADDED'
export const UPDATE_ADMIN = 'UPDATE_ADMIN'
export const GET_ADMIN = 'GET_ADMIN'
export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS'
export const DELETE_ADMIN = 'DELETE_ADMIN'
export const ADMIN_ERROR = 'ADMIN_ERROR'
export const ADMIN_ERROR_SENT = 'ADMIN_ERROR_SENT'

//Super Types
export const GET_SUPER = 'GET_SUPER'
export const GET_ALL_ADMINS = 'GET_ALL_ADMINS'

//Loading Types
export const LEADS_LOADING = 'LEADS_LOADING'
export const LOGIN_LOADING = 'LOGIN_LOADING'
export const CLIENT_LOADING = 'CLIENT_LOADING'
export const ADMIN_LOADING = 'ADMIN_LOADING'
export const TEMPLATE_LOADING = 'TEMPLATE_LOADING'
export const COT_LOADING = 'COT_LOADING'
export const COUNT_LOADING = 'COUNT_LOADING'
export const FB_LEADS_LOADING = 'FB_LEADS_LOADING'
export const EMAIL_TEMPLATES_LOADING = 'EMAIL_TEMPLATES_LOADING'
export const RESPONSE_TEMPLATES_LOADING = 'RESPONSE_TEMPLATES_LOADING'
export const FB_IDS_LOADING = 'FB_IDS_LOADING'
export const CLIENTS_LOADING = 'CLIENTS_LOADING'
export const RECAPTCHA_LOADING = 'RECAPTCHA_LOADING'

