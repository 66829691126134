import React from 'react'

const SvgComponent = props => (
  <svg
    width={786.954}
    height={539.77}
    viewBox="0 0 208.215 142.814"
    id="prefix__svg8"
    {...props}
  >
    <defs id="prefix__defs2">
      <clipPath id="prefix__clipPath3753-7-6" clipPathUnits="userSpaceOnUse">
        <path id="prefix__path3751-4-3" d="M0 913.485h1280V0H0z" />
      </clipPath>
      <clipPath id="prefix__clipPath3753" clipPathUnits="userSpaceOnUse">
        <path id="prefix__path3751" d="M0 913.485h1280V0H0z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath3753-6">
        <path d="M0 913.485h1280V0H0z" id="prefix__path3751-3" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath3753-4">
        <path d="M0 913.485h1280V0H0z" id="prefix__path3751-30" />
      </clipPath>
      <clipPath id="prefix__clipPath3753-7-9" clipPathUnits="userSpaceOnUse">
        <path id="prefix__path3751-4-1" d="M0 913.485h1280V0H0z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath3753-1">
        <path d="M0 913.485h1280V0H0z" id="prefix__path3751-9" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath3753-7">
        <path d="M0 913.485h1280V0H0z" id="prefix__path3751-4" />
      </clipPath>
      <clipPath id="prefix__clipPath3753-6-5" clipPathUnits="userSpaceOnUse">
        <path id="prefix__path3751-3-2" d="M0 913.485h1280V0H0z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath3753-8">
        <path d="M0 913.485h1280V0H0z" id="prefix__path3751-47" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath3753-2">
        <path d="M0 913.485h1280V0H0z" id="prefix__path3751-1" />
      </clipPath>
      <linearGradient id="prefix__linearGradient4344">
        <stop
          id="prefix__stop4340"
          offset={0}
          stopColor="#2f55cc"
          stopOpacity={1}
        />
        <stop
          id="prefix__stop4342"
          offset={1}
          stopColor="#1de4be"
          stopOpacity={0.094}
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        y2={476.048}
        x2={1057.951}
        y1={344.735}
        x1={1025.726}
        id="prefix__linearGradient4346-0"
        xlinkHref="#prefix__linearGradient4344"
      />
    </defs>
    <g id="prefix__layer1" transform="translate(-.893 -77.093)">
      <g
        id="prefix__g5156"
        transform="matrix(1.90921 0 0 1.94064 -89.927 -40.996)"
      >
        <path
          id="prefix__polygon4125"
          transform="matrix(.54453 0 0 .64696 -422.995 -162.408)"
          fill="url(#prefix__linearGradient4346-0)"
          fillOpacity={1}
          d="M1049.11 345.09h-62.82l-7.67 13.28h62.85l-43.04 74.87-17.86-28.19-7.5 12.98 17.88 28.22 7.82 12.59 7.49-12.98.12-.07-.04-.06 58.11-100.64h-7.83z"
        />
        <path
          id="prefix__polygon4127"
          transform="matrix(.54453 0 0 .64696 -422.995 -162.408)"
          d="M879.9 345.09l63.8 100.7-11.22 7.11-68.31-107.81z"
        />
        <path
          id="prefix__polygon4129"
          transform="matrix(.54453 0 0 .64696 -422.995 -162.408)"
          d="M928.27 446.25l43.14-75.04 15.31-.06-50.63 87.69z"
        />
      </g>
    </g>
    <style id="style4117" type="text/css" />
    <style id="style5249" type="text/css" />
    <style type="text/css" id="style4117-5" />
    <style type="text/css" id="style4117-3" />
    <style type="text/css" id="style5249-7" />
    <style type="text/css" id="style4117-8" />
    <style type="text/css" id="style5249-78" />
    <style id="style4117-5-1" type="text/css" />
    <style type="text/css" id="style4117-6" />
    <style type="text/css" id="style5249-73" />
    <style id="style4117-5-6" type="text/css" />
    <style id="style4117-3-9" type="text/css" />
    <style id="style5249-7-5" type="text/css" />
    <style type="text/css" id="style4117-4" />
    <style type="text/css" id="style5249-789" />
    <style type="text/css" id="style4117-62" />
  </svg>
)

export default SvgComponent
