import React from "react";
import Logo from "../../../images/LMS/LMS_Logo.png";
import { Container } from "@material-ui/core";
import mainStyle from "../../../styles/LMS/mainStyle";

const styles = {
  header: {
    background: "white",
    height: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100%"
  }
};

const LegalTemplate = props => {
  return (
    <div className="pb-5">
      <div style={styles.header} className="mb-5">
        <div>
          <div
            className="text-center"
            onClick={() => {
              props.history.push("/lms");
            }}
          >
            <img src={Logo} height={"100px"} width={"100px"} />
          </div>
          <h2 className="text-center px-3" style={{ ...mainStyle.header }}>
            {props.name}
          </h2>
        </div>
      </div>
      <Container style={{ background: "snow" }}>{props.children}</Container>
    </div>
  );
};

export default LegalTemplate;
