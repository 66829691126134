import React from 'react'

const SvgComponent = props => (
  <svg
    width="210mm"
    height="297mm"
    viewBox="0 0 210 297"
    id="prefix__svg8"
    {...props}
  >
    <defs id="prefix__defs2">
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath3753-1">
        <path d="M0 913.485h1280V0H0z" id="prefix__path3751-9" />
      </clipPath>
      <clipPath id="prefix__clipPath3753" clipPathUnits="userSpaceOnUse">
        <path id="prefix__path3751" d="M0 913.485h1280V0H0z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath3753-6">
        <path d="M0 913.485h1280V0H0z" id="prefix__path3751-3" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath3753-4">
        <path d="M0 913.485h1280V0H0z" id="prefix__path3751-30" />
      </clipPath>
      <clipPath id="prefix__clipPath3753-7-9" clipPathUnits="userSpaceOnUse">
        <path id="prefix__path3751-4-1" d="M0 913.485h1280V0H0z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath3753-7">
        <path d="M0 913.485h1280V0H0z" id="prefix__path3751-4" />
      </clipPath>
      <clipPath id="prefix__clipPath3753-7-6" clipPathUnits="userSpaceOnUse">
        <path id="prefix__path3751-4-3" d="M0 913.485h1280V0H0z" />
      </clipPath>
      <clipPath id="prefix__clipPath3753-6-5" clipPathUnits="userSpaceOnUse">
        <path id="prefix__path3751-3-2" d="M0 913.485h1280V0H0z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath3753-2">
        <path d="M0 913.485h1280V0H0z" id="prefix__path3751-1" />
      </clipPath>
      <linearGradient id="prefix__linearGradient4344">
        <stop
          id="prefix__stop4340"
          offset={0}
          stopColor="#2f55cc"
          stopOpacity={1}
        />
        <stop
          id="prefix__stop4342"
          offset={1}
          stopColor="#1de4be"
          stopOpacity={1}
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        y2={476.048}
        x2={1057.951}
        y1={344.735}
        x1={1025.726}
        id="prefix__linearGradient4346"
        xlinkHref="#prefix__linearGradient4344"
      />
    </defs>
    <g id="prefix__layer1">
      <g id="prefix__g5475">
        <path
          id="prefix__polygon4125-3"
          transform="matrix(.54882 0 0 .61848 -423.229 -140.614)"
          fill="url(#prefix__linearGradient4346)"
          fillOpacity={1}
          d="M980.57 405.05l-7.5 12.98 17.88 28.22 7.82 12.59 7.49-12.98.12-.07-.04-.06 58.11-100.64h-78.16l-7.67 13.28h62.85l-43.04 74.87z"
        />
        <path
          id="prefix__polygon4127-0"
          transform="matrix(.54882 0 0 .61848 -423.229 -140.614)"
          fill="#fff"
          d="M864.17 345.09h15.73l63.8 100.7-11.22 7.11z"
        />
        <path
          id="prefix__polygon4129-2"
          transform="matrix(.54882 0 0 .61848 -423.229 -140.614)"
          fill="#fff"
          d="M936.09 458.84l-7.82-12.59 43.14-75.04 15.31-.06z"
        />
        <g
          transform="matrix(.54035 0 -.01092 .61837 -410.04 -140.566)"
          id="prefix__g5293"
          fill="#fff"
        >
          <path
            id="prefix__path5263"
            d="M836.65 562.04h-9l-6.99-17.68-5.95-17.76-5.87 17.93-6.84 17.51h-9l-20.66-56.27h11.9l13.26 40.59 13.67-40.59h6.91l14.07 40.59 12.86-40.59h11.9z"
          />
          <path
            id="prefix__path5265"
            d="M903.36 551.43h-29.42l-4.82 10.61h-11.49l25.24-56.27h11.58l25.24 56.27H908.1zm-14.71-33.84l-10.45 23.95h20.9z"
          />
          <path
            id="prefix__path5267"
            d="M975.7 533.42c.24 14.23-8.44 28.62-28.13 28.62h-22.1v-56.27h22.1c19.29 0 27.89 13.75 28.13 27.65zm-39.71 18.41h11.58c12.78 0 17.85-9.32 17.6-18.49-.24-8.76-5.39-17.52-17.6-17.52h-11.58z"
          />
          <path
            id="prefix__path5269"
            d="M1024.65 562.04h-42.12v-56.27h42.12v10.29h-31.59V529h30.46v9.89h-30.46v12.7h31.59z"
          />
          <path
            id="prefix__path5271"
            d="M1096.26 538.01c0 14.55-9.24 25.16-24.76 25.16-14.39 0-24.68-8.6-24.68-24.52h10.45c0 9.65 4.66 14.55 14.23 14.55 9.65 0 14.15-6.67 14.15-15.19v-22.35h-16.96v-9.89h27.57z"
          />
          <path
            id="prefix__path5273"
            d="M1156.14 562.04h-12.62l-16.4-18.81h-10.21v18.81h-10.61v-56.35c8.92 0 17.84.08 26.77.08 13.26.08 20.26 8.92 20.26 18.65 0 7.72-3.54 15.51-14.23 17.68l17.04 19.21zm-39.23-46.38v18.01h16.16c6.75 0 9.65-4.5 9.65-9s-2.97-9-9.65-9h-16.16z"
          />
          <path
            id="prefix__path5275"
            d="M787.47 575.33l-6.17 9.43h-.41l-6.21-9.43v14.31h-1.24v-16.11h1.47l6.17 9.39 6.1-9.39h1.52v16.11h-1.22v-14.31z"
          />
          <path
            id="prefix__path5277"
            d="M836.79 589.65l-1.77-3.96h-9.62l-1.75 3.96h-1.33l7.22-16.11h1.36l7.23 16.11zm-2.25-5.11l-4.33-9.8-4.33 9.8z"
          />
          <path
            id="prefix__path5279"
            d="M884.55 589.65h-1.63l-5.57-6.1h-4.39v6.1h-1.24v-16.11h7.09c3.5 0 5.27 2.51 5.29 5.02.02 2.62-1.7 4.92-5.22 4.92zm-11.6-7.28h5.71c2.81 0 4.05-1.59 4.07-3.84.02-1.89-1.27-3.84-3.93-3.84h-5.84v7.68z"
          />
          <path
            id="prefix__path5281"
            d="M929.68 589.65h-1.56l-8.49-7.94v7.92h-1.24v-16.08h1.24v7.34l7.82-7.34h1.5v.12l-8.1 7.59 8.84 8.31v.08z"
          />
          <path
            id="prefix__path5283"
            d="M964.28 580.97h9.43v1.13h-9.43v6.35h10.17v1.2h-11.39v-16.11h11.11v1.17h-9.89z"
          />
          <path
            id="prefix__path5285"
            d="M1013.51 574.67h-5.82v-1.1h12.89v1.1h-5.82v14.98h-1.24v-14.98z"
          />
          <path id="prefix__path5287" d="M1054.68 589.65v-16.11h1.22v16.11z" />
          <path
            id="prefix__path5289"
            d="M1091.74 573.54l10.84 13.32v-13.32h1.22v16.11h-.48l-10.86-13.3v13.3h-1.24v-16.11z"
          />
          <path
            id="prefix__path5291"
            d="M1151.81 576.51c-1.4-1.38-3.36-2.07-5.18-2.07-4.72 0-7.13 3.45-7.11 7.18 0 3.8 2.42 7.06 7.11 7.06 1.73 0 3.52-.6 4.9-1.86v-4.51h-5.61v-1.08h6.79v6.03c-1.68 1.68-3.68 2.6-6.08 2.6-5.48 0-8.31-3.82-8.31-8.21-.02-5.04 3.41-8.33 8.31-8.33 2.12 0 4.26.8 5.89 2.42z"
          />
        </g>
      </g>
    </g>
    <style id="style4117" type="text/css" />
    <style id="style5249" type="text/css" />
    <style type="text/css" id="style4117-5" />
    <style type="text/css" id="style4117-3" />
    <style type="text/css" id="style5249-7" />
    <style type="text/css" id="style4117-8" />
    <style type="text/css" id="style5249-78" />
    <style id="style4117-5-1" type="text/css" />
    <style type="text/css" id="style4117-6" />
    <style type="text/css" id="style5249-73" />
    <style id="style4117-5-6" type="text/css" />
    <style id="style4117-3-9" type="text/css" />
    <style id="style5249-7-5" type="text/css" />
    <style type="text/css" id="style4117-4" />
    <style type="text/css" id="style5249-789" />
  </svg>
)

export default SvgComponent
