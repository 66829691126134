import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { ClipLoader } from "react-spinners";
import ColorButton from "../../../misc/ColorButton";
import mainStyle from '../../../styles/LMS/mainStyle'
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { resetPassword } from "../../../redux/LMS/actions/loginActions";
import Swal from "sweetalert2";

const Reset = props => {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [loading, setLoading] = useState(false);
  const login = useSelector(state => state.login);
  const [response, setResponse] = useState(false);

  const submit = e => {
    e.preventDefault();
    new Promise(resolve => {
      resolve(setResponse(true));
    }).then(() => {
      props.resetPassword(props.token, password);
    });
  };

  useEffect(() => {
    if (response && login.status > 399) {
      Swal.fire({
        timer: 2000,
        title: "Error",
        text: login.message,
        type: "error",
        showConfirmButton: true
      }).then(() => {
        setResponse(false);
      });
    }
  }, [login.status]);

  useEffect(() => {
    setLoading(login.loading);
  }, [login.loading]);

  return (
    <div>
      <div>
        <h2
          className="mb-5 text-center"
          style={{ ...mainStyle.subHeader}}
        >
          Reset Password
        </h2>
      </div>
      <div>
        <p className='text-center' style={{ ...mainStyle.text }}>Please enter your new password.</p>
      </div>

      <form onSubmit={submit}>
        <div className="mb-3">
          <FormControl required variant="outlined" style={{ width: "100%" }}>
            <InputLabel required htmlFor="password">
              Password
            </InputLabel>
            <OutlinedInput
              id="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    style={{ outline: "none" }}
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                    type="button"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={85}
            />
          </FormControl>
        </div>

        <div className="mb-3">
          <FormControl required variant="outlined" style={{ width: "100%" }}>
            <InputLabel required htmlFor="password2">
              Re-enter Password
            </InputLabel>
            <OutlinedInput
              id="password2"
              type={showPassword2 ? "text" : "password"}
              value={password2}
              error={password !== password2 && password2.length > 0}
              onChange={e => setPassword2(e.target.value)}
              required
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    type="button"
                    style={{ outline: "none" }}
                    onClick={() => setShowPassword2(!showPassword2)}
                    onMouseDown={() => setShowPassword2(!showPassword2)}
                  >
                    {showPassword2 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={150}
            />
          </FormControl>
        </div>

        <div className="mb-3">
          <ColorButton
            contained
            noRadius
            disabled={loading || password !== password2 || password === ""}
            textColor="white"
            color={mainStyle.buttonColor}
            hoverColor={mainStyle.hoverColor}
            type="submit"
            style={{
              ...mainStyle.bigButton,
              width: "100%"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <span className={loading ? "mr-2" : ""}>Reset</span>
              <ClipLoader
                sizeUnit={"px"}
                size={15}
                color={"#fff"}
                loading={loading}
              />
            </div>
          </ColorButton>
        </div>
        <Link className='text-center' to="/lms">Back to login</Link>
      </form>
    </div>
  );
};

const actions = {
  resetPassword
};
export default connect(null, actions)(Reset);
