import React, { useEffect, useState } from "react";
import { Paper, List, ListItem, Divider } from "@material-ui/core";
import CardTitle from "../../../misc/LMS/CardTitle";
import { Link } from "react-router-dom";
import mainStyle from "../../../styles/LMS/mainStyle";
import { ShowHide, convertDate } from "../../../misc/utility";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { connect, useSelector } from "react-redux";
import { getNewLeads } from "../../../redux/LMS/actions/leadActions";
import { ClipLoader } from "react-spinners";

const styles = {};

// const leads = [
//   {
//     _id: "1",
//     firstName: "Wade",
//     lastName: "Smith",
//     date: "6/27/2019 at 9:30am",
//     campaign: "First Campaign",
//     phone: "+7777777777",
//     email: "wadejr@wadejr.com"
//   },
//   {
//     _id: "1",
//     firstName: "Wade",
//     lastName: "Smith",
//     date: "6/27/2019 at 9:30am",
//     campaign: "First Campaign",
//     phone: "+7777777777",
//     email: "wadejr@wadejr.com"
//   },
//   {
//     _id: "1",
//     firstName: "Wade",
//     lastName: "Smith",
//     date: "6/27/2019 at 9:30am",
//     campaign: "First Campaign",
//     phone: "+7777777777",
//     email: "wadejr@wadejr.com"
//   },
//   {
//     _id: "1",
//     firstName: "Wade",
//     lastName: "Smith",
//     date: "6/27/2019 at 9:30am",
//     campaign: "First Campaign",
//     phone: "+7777777777",
//     email: "wadejr@wadejr.com"
//   },
//   {
//     _id: "1",
//     firstName: "Wade",
//     lastName: "Smith",
//     date: "6/27/2019 at 9:30am",
//     campaign: "First Campaign",
//     phone: "+7777777777",
//     email: "wadejr@wadejr.com"
//   },
//   {
//     _id: "1",
//     firstName: "Wade",
//     lastName: "Smith",
//     date: "6/27/2019 at 9:30am",
//     campaign: "First Campaign",
//     phone: "+7777777777",
//     email: "wadejr@wadejr.com"
//   },
//   {
//     _id: "1",
//     firstName: "Wade",
//     lastName: "Smith",
//     date: "6/27/2019 at 9:30am",
//     campaign: "First Campaign",
//     phone: "+7777777777",
//     email: "wadejr@wadejr.com"
//   },
//   {
//     _id: "1",
//     firstName: "Wade",
//     lastName: "Smith",
//     date: "6/27/2019 at 9:30am",
//     campaign: "First Campaign",
//     phone: "+7777777777",
//     email: "wadejr@wadejr.com"
//   }
// ];

const Lead = ({ lead }) => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ ...mainStyle.text, fontSize: "20px", maxWidth: "50%" }}>
          <b>{lead.firstName + " " + lead.lastName}</b>
        </div>
        <div style={{ ...mainStyle.text, maxWidth: "50%", textAlign: "right" }}>
          {convertDate(lead.timeRecieved || lead.timeReceived)}
        </div>
      </div>
      <div style={{ ...mainStyle.text, wordWrap: "break-word" }}>
        {lead.email}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <a style={{ ...mainStyle.text }} href={"tel://" + lead.phone}>
            {lead.phone || lead.phoneNumber}
          </a>
        </div>
        <div>
          <Link
            to={"/lms/lead?id=" + lead._id}
            style={{
              ...mainStyle.text,
              color: mainStyle.defaultColor,
              display: "flex",
              alignItems: "center"
            }}
          >
            Archive <ArrowForwardIcon />
          </Link>
        </div>
      </div>
    </div>
  );
};

const showMore = (arr, showing, addX = 10) => {
  return arr.slice(0, showing + addX);
};

const Leads = props => {
  const l = useSelector(state => state.lead);
  const [leads, setLeads] = useState([]);
  const [defaultLeads, setDefaultLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, getSearch] = useState("");
  const [showing, setShowing] = useState(10);

  const getSort = sort => {
    setLeads(defaultLeads.reverse().slice(0, showing));
  };

  useEffect(() => {
    try {
      if (search === "") {
        setLeads(defaultLeads.slice(0, showing));
      } else {
        setLeads(
          defaultLeads.filter(lead => {
            return (
              lead.firstName.toLowerCase().indexOf(search.toLowerCase()) !==
                -1 ||
              lead.email.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              (lead.phone &&
                lead.phone.toLowerCase().indexOf(search.toLowerCase()) !==
                  -1) ||
              (lead.phoneNumber &&
                lead.phoneNumber.toLowerCase().indexOf(search.toLowerCase()) !==
                  -1) ||
              (lead.timeReceived &&
                lead.timeReceived
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1) ||
              (lead.timeRecieved &&
                lead.timeRecieved
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1) ||
              lead.lastName.toLowerCase().indexOf(search.toLowerCase()) !== -1
            );
          })
        );
      }
    } catch {
      setLeads([]);
    }
  }, [search]);

  useEffect(() => {
    props.getNewLeads();
  }, []);

  useEffect(() => {
    if (l.newLeads.length) {
      setDefaultLeads(l.newLeads);
      setLeads(l.newLeads.slice(0, showing));
    }
  }, [l.newLeads]);

  useEffect(() => {
    setLoading(l.leadsLoading);
  }, [l.leadsLoading]);

  const addMore = () => {
    setLeads(showMore(defaultLeads, showing, 10));
    setShowing(showing + 10);
  };

  const LeadsList = () => (
    <ShowHide
      show={!(!leads.length || loading)}
      placeholder={
        <ShowHide
          show={!loading}
          placeholder={
            <div className="text-center py-3">
              <ClipLoader
                sizeUnit={"px"}
                size={40}
                color={mainStyle.defaultColor}
                loading={loading}
              />
            </div>
          }
        >
          <p className="text-muted text-center py-3">No data.</p>
        </ShowHide>
      }
    >
      <List>
        {leads.map((lead, idx) => (
          <div>
            <div className="p-3">
              <Lead lead={lead} />
            </div>
            <ShowHide show={idx !== leads.length - 1}>
              <Divider variant="fullWidth" />
            </ShowHide>
          </div>
        ))}
      </List>
    </ShowHide>
  );

  return (
    <div>
      <Paper elevation={5}>
        <CardTitle
          title="Leads"
          search
          sort
          getSort={getSort}
          getSearch={getSearch}
        />
        <LeadsList />
        <ShowHide
          show={
            showing < defaultLeads.length &&
            defaultLeads.length > 10 &&
            search === ""
          }
        >
          <Divider variant="fullWidth" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
            className="p-3"
          >
            <div
              style={{
                ...mainStyle.text,
                color: mainStyle.defaultColor,
                cursor: "pointer"
              }}
              onClick={addMore}
            >
              Show More
            </div>
            <div style={{ ...mainStyle.text }}>
              {showing} / {defaultLeads.length}
            </div>
          </div>
        </ShowHide>
      </Paper>
    </div>
  );
};

const actions = {
  getNewLeads
};
export default connect(null, actions)(Leads);
