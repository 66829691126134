import {
  GET_NEW_LEADS,
  ADD_LEAD,
  DELETE_LEAD,
  UPDATE_LEAD,
  LEADS_LOADING,
  COT_LOADING,
  COUNT_LOADING,
  SEND_EMAIL,
  CALLS_ON_TIME,
  GET_LEAD_AND_CUSTOMER_COUNT
} from "../types";
import axios from "axios";
import { getConfig } from "../../reduxHelpers";

let config;

export const getNewLeads = () => dispatch => {
  config = getConfig("client");
  dispatch(setLeadsLoading());

  axios
    .get(
      "/api/leads/new",
      config
    )
    .then(res => {
      dispatch({ type: GET_NEW_LEADS, payload: res.data.leads });
    })
    .catch(error => {
      console.log(error);
    });
};

export const addLead = lead => dispatch => {
  config = getConfig("client");
  dispatch(setLeadsLoading());
  const body = {
    lead: lead
  };
  axios
    .post("/api/leads", body, config)
    .then(res => {
      dispatch({ type: ADD_LEAD, payload: res.data.leads });
    })
    .catch(error => {
      console.log(error);
    });
};

export const deleteLead = _id => dispatch => {
  config = getConfig("client");
  dispatch(setLeadsLoading());

  axios.delete("/api/leads?_id=" + _id, config).then(res => {
    dispatch({ type: DELETE_LEAD, payload: res.data.leads });
  });
};

export const updateLead = lead => dispatch => {
  config = getConfig("client");
  dispatch(setLeadsLoading());
  const body = {
    lead: lead
  };
  axios
    .patch("/api/leads", body, config)
    .then(res => {
      dispatch({ type: UPDATE_LEAD, payload: res.data.leads });
    })
    .catch(error => {
      console.log(error);
    });
};

export const emailLead = emailBody => dispatch => {
  config = getConfig("client");
  let body = {
    to: emailBody.to,
    subject: emailBody.subject,
    body: emailBody.body
  };

  axios
    .post("/api/leads/email", body, config)
    .then(res => {
      dispatch({ type: SEND_EMAIL });
    })
    .catch(error => {
      console.log(error);
    });
};

export const getCot = () => dispatch => {
  config = getConfig("client");
  dispatch(setCotLoading());

  axios
    .get("/api/leads/cot", config)
    .then(res => {
      dispatch({ type: CALLS_ON_TIME, payload: res.data.timeDiff });
    })
    .catch(error => {
      console.log(error);
    });
};

export const leadAndCustomerCount = () => dispatch => {
  config = getConfig("client");
  dispatch(setCountLoading());

  axios
    .get(
      "/api/leads/count",
      config
    )
    .then(res => {
      dispatch({
        type: GET_LEAD_AND_CUSTOMER_COUNT,
        leadsCount: res.data.leadsCount,
        customerCount: res.data.newCustomers,
        campaigns: res.data.campaigns
      });
    })
    .catch(error => {
      console.log(error);
    });
};

export const setLeadsLoading = () => ({ type: LEADS_LOADING });
export const setCotLoading = () => ({ type: COT_LOADING });
export const setCountLoading = () => ({ type: COUNT_LOADING });
