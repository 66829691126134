import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { TextField, createMuiTheme } from "@material-ui/core";

const ColorTextField = props => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: props.color || "#000"
      }
    }
  });
  return (
    <div>
      <ThemeProvider theme={theme}>
        <TextField
          {...props.params}
          style={props.style}
          color="primary"
          label={props.label}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          id={props.id}
          name={props.name}
          className={props.className}
          variant={props.variant}
          fullWidth={props.fullWidth}
          margin={props.margin}
          onKeyDown={props.onKeyDown}
          onBlur={props.onBlur}
          onMouseUp={props.onMouseUp}
          onMouseDown={props.onMouseDown}
        />
      </ThemeProvider>
    </div>
  );
};

export default ColorTextField;
