import React from "react";
import { Grid, Container } from "@material-ui/core";
import ColorButton from "../../../misc/ColorButton";
import ServiceIcon from "./ServiceIcon";
import like from "../../../images/Marketing/like.svg";
import web from "../../../images/Marketing/web.svg";
import seo from "../../../images/Marketing/seo.svg";
import brand from "../../../images/Marketing/brand.svg";
import ads from "../../../images/Marketing/ads.svg";
import photography from "../../../images/Marketing/photography.svg";
import world from "../../../images/Marketing/world.svg";
import mainStyle from "../../../styles/Marketing/mainStyle";
import ScrollAnimation from "react-animate-on-scroll";

const styles = {
  services: {
    backgroundImage: `url(${world})`
  },
  background: {
    backgroundColor: "rgb(239,242,246, .95)"
  }
};

const services1 = [
  {
    icon: like,
    iconStyle: {},
    // iconBackgroundColor: "#fff",
    iconTitle: "Social Media Management",
    title: "Social Media Management",
    content:
      "We offer full-service social media management services. Transform your social media presence with high-quality content, daily activity, and increasing followers."
  },
  {
    icon: ads,
    iconStyle: {},
    // iconBackgroundColor: "#d3d3d3",
    iconTitle: "Social Media Advertising",
    title: "Social Media Advertising",
    content:
      "Our social media advertising services are proven to accelerate growth. By advertising through social media, 100% of our clients have seen results at lower costs than before."
  },
  {
    icon: web,
    iconStyle: {},
    // iconBackgroundColor: "#9ccc65",
    iconTitle: "Web Development",
    title: "Web Development",
    content:
      "You need an effective website to get the most success from social media advertising. We build top quality websites that will keep your visitors engaged during the buying process."
  }
];
const services2 = [
  {
    icon: seo,
    iconStyle: {},
    // iconBackgroundColor: "#9ccc65",
    iconTitle: "SEO",
    title: "SEO",
    content:
      "Get found on Google & Bing for relevant searches with our SEO services. Our initial and ongoing optimization service ensures higher rankings and more clicks."
  },
  {
    icon: photography,
    iconStyle: {},
    // iconBackgroundColor: "#9ccc65",
    iconTitle: "Photography",
    title: "Photography",
    content:
      "A picture speaks for 1000 words. High quality photography is a prominent form of showcasing your business, products, and growing your brand!"
  },
  {
    icon: brand,
    iconStyle: {},
    // iconBackgroundColor: "#9ccc65",
    iconTitle: "Brand Development",
    title: "Brand Development",
    content:
      "Having a reconizable identity online is crucial for leveraging organic traffic for your business."
  }
];

const Services = () => {
  
  return (
    <div className="" style={{ ...styles.services }} id="services" >
      <div style={{ ...styles.background }} id="#servicesOverlay">
        <Container id="section" fixed>
          <div className="text-center mb-5">
            <h2 className="mb-2 pt-3">Services</h2>
            <p>
              <i>
                We create and manage top-performing social media campaigns for
                Dentists
              </i>
            </p>
          </div>
          <div className="mb-5" style={{ zIndex: -1 }}>
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="stretch"
              spacing={3}
            >
              {services1.map((service, idx) => (
                <Grid item xs={12} md={4} key={idx}>
                  <ServiceIcon
                    icon={service.icon}
                    iconStyle={service.iconStyle}
                    iconBackgroundColor={"transparent"}
                    iconTitle={service.iconTitle}
                    title={service.title}
                    content={service.content}
                  />
                </Grid>
              ))}
              {services2.map((service, idx) => (
                <Grid item xs={12} md={4} key={idx}>
                  <ServiceIcon
                    icon={service.icon}
                    iconStyle={service.iconStyle}
                    iconBackgroundColor={service.iconBackgroundColor}
                    iconTitle={service.iconTitle}
                    title={service.title}
                    content={service.content}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
          <div className="text-center mb-5">
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOnce
              initiallyVisible={false}
            >
              <ColorButton
                contained
                noRadius
                textColor="white"
                color={mainStyle.buttonColor}
                hoverColor={mainStyle.hoverColor}
                href="/get-started"
                style={{
                  ...mainStyle.bigButton
                }}
              >
                Get Started
              </ColorButton>
            </ScrollAnimation>
          </div>

          <div className="mb-3 text-center">
            <p>
              <i>
                Have a question? <a href="tel:17273247781">Give us a call.</a>
              </i>
            </p>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Services;
