import React, { useState, useEffect, useContext } from "react";
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import Views from "./views";
import {
  ClientSession,
  AdminSession,
  getAdminSessionCookie,
  getClientSessionCookie
} from "./misc/Session";
import { connect, useSelector } from "react-redux";
import { getAdmin } from "./redux/LMS/actions/adminActions";
import { getClient } from "./redux/LMS/actions/clientActions";

const pathNames = [
  "/",
  "/contact",
  "/marketing",
  "/marketing/faq",
  "/marketing/contact",
  "/marketing/get-started",
  "/lms",
  "/lms/recover",
  "/lms/privacy-policy",
  "/lms/terms-and-conditions",
  // "/lms/dashboard",
  // "/lms/archive",
  // "/lms/profile",
  // "/lms/template",
  // "/lms/admin",
  // "/lms/lead",
  "/contact/",
  "/marketing/",
  "/marketing/faq/",
  "/marketing/contact/",
  "/marketing/get-started/",
  "/lms/",
  "/lms/recover/",
  "/lms/privacy-policy/",
  "/lms/terms-and-conditions/",
  // "/lms/dashboard/",
  // "/lms/archive/",
  // "/lms/profile/",
  // "/lms/template/",
  // "/lms/admin/",
  // "/lms/lead/"
];

const PrivateAdminRoute = ({ component: Component, ...rest }) => {
  const session = true; //useContext(AdminSession);
  return (
    <Route
      {...rest}
      render={props =>
        session ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/lms", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const session = true; //useContext(ClientSession);
  return (
    <Route
      {...rest}
      render={props =>
        session ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/lms", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const NotFound = () => {
  if (!pathNames.includes(window.location.pathname))
    return <Route component={Views.Four04} />;
  else return null;
};

const Routes = props => {
  const admin = useSelector(state => state.admin);
  const client = useSelector(state => state.client);

  const [clientSession, setClientSession] = useState(
    getClientSessionCookie().token
  );
  const [adminSession, setAdminSession] = useState(
    getAdminSessionCookie().token
  );
  //

  useEffect(() => {
    props.getClient();
    // props.getAdmin();
  }, []);

  useEffect(() => {
    if (admin.admin && (admin.status === 200 || admin.status === 304)) {
      setAdminSession(getAdminSessionCookie().token);
    } else if (admin.status === 401 || (!admin.admin && admin.status === 304)) {
      setAdminSession(null);
    }
  }, [admin.admin, admin.status]);

  useEffect(() => {
    if (client.client && (client.status === 200 || client.status === 304)) {
      setClientSession(getClientSessionCookie().token);
    } else if (client.status === 401) {
      setClientSession(null);
    }
  }, [client.client, client.status]);

  return (
    <Router>
      <Switch>
        {/* Personal */}
        <Route exact path="/" component={Views.Personal} />
        <Route exact path="/contact" component={Views.Contact} />

        {/* Marketing */}
        <Route exact path="/marketing" component={Views.LandingPage} />
        <Route exact path="/marketing/faq" component={Views.FAQ} />
        <Route
          exact
          path="/marketing/contact"
          component={Views.MarketingContact}
        />
        <Route path="/marketing/get-started" component={Views.GetStarted} />

        {/* LMS */}
        <Route exact path="/lms" component={Views.Login} />
        <Route exact path="/lms/recover" component={Views.Recover} />
        <Route
          exact
          path="/lms/privacy-policy"
          component={Views.PrivacyPolicy}
        />
        <Route
          path="/lms/terms-and-conditions"
          exact
          component={Views.TermsAndConditions}
        />
        {/* <Route component={Views.Four04} /> */}
      </Switch>
      <Switch>
        <ClientSession.Provider value={clientSession}>
          {/* <PrivateRoute path="/lms/dashboard" exact component={Views.Dashboard} />
          <PrivateRoute path="/lms/lead" component={Views.Lead} />

          <PrivateRoute path="/lms/archive" component={Views.Archive} />
          <PrivateRoute path="/lms/profile" component={Views.Profile} />
          <PrivateRoute path="/lms/template" component={Views.Template} /> */}
        </ClientSession.Provider>
      </Switch>
      <Switch>
        <AdminSession.Provider value={adminSession}>
          {/* <PrivateAdminRoute path="/lms/admin" component={Views.Admin} /> */}
        </AdminSession.Provider>
      </Switch>
      <NotFound />
    </Router>
  );
};

const actions = {
  getAdmin,
  getClient
};
export default connect(null, actions)(Routes);
