import React, { useState } from "react";
import mainStyle from "../../../styles/Marketing/mainStyle";
import { Container, Grid, Paper, TextField } from "@material-ui/core";
import download from "../../../images/Marketing/bill.svg";
import ColorButton from "../../../misc/ColorButton";
import "./style.css";
import Recaptcha from "../../../misc/Recaptcha";
import { connect } from "react-redux";
import { addContact } from "../../../redux/Marketing/actions/contactActions";
import Swal from "sweetalert2";
import { ClipLoader } from "react-spinners";

const styles = {
  top: {
    position: "absolute",
    top: 0,
    zIndex: 1,
    backgroundColor: mainStyle.alternateBackgroundColor.backgroundColor,
    height: "150px",
    width: "100%"
  },
  middle: {
    position: "relative",
    marginTop: "-75px",
    top: 75,
    zIndex: 2,
    backgroundColor: "white"
  },
  bottom: {
    position: "absolute",
    top: 150,
    zIndex: 1,
    backgroundColor: mainStyle.secondaryColor,
    height: "100%",
    width: "100%"
  },
  content: {
    // height: "400px",
    width: "100%",
    position: "relative"
  }
};

const Download = props => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  let rd = false;
  const onNameChange = e => {
    setName(e.target.value);
  };
  const onEmailChange = e => {
    setEmail(e.target.value);
  };

  const submit = e => {
    e.preventDefault();

    props.addContact({ name: name, email: email, makeOver: true, test: false });
  };

  if (name === "" || email === "") {
    rd = false;
  } else {
    rd = true;
  }

  if (props.contact.success) {
    Swal.fire({
      title: "Success!",
      text: "Your marketing makeover presentation was sent to your email.",
      type: "success",
      confirmButtonText: "Ok"
    }).then(() => {
      setName("");
      setEmail("");
    });
  }

  return (
    <div
      style={{
        ...mainStyle.section,
        position: "relative",
        marginBottom: "150px"
      }}
    >
      <div style={styles.top} />
      <Container fixed>
        <div style={styles.middle}>
          <div className="pb-3" style={styles.content}>
            <Container fixed>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <div className="mb-3 mt-3">
                    <img
                      alt={"Download Icon"}
                      src={download}
                      title="Download Presentation"
                      style={{ height: "70px", width: "70px" }}
                    />
                  </div>
                  <div className="mb-3" style={{ maxWidth: "300px" }}>
                    <h2>Marketing Makeover</h2>
                  </div>
                  <div style={{ maxWidth: "300px" }}>
                    Receive a FREE presentation! Find out how we will gain
                    recognition for your Dentistry. See how we will{" "}
                    <b>grow your business</b>. Also, check out our calculations
                    for your return on investment.
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper className="p-3  mt-3 shadow">
                    <div className="mb-3">
                      <h3 className="text-center">
                        Let us know where to send your free presentation
                      </h3>
                    </div>
                    <form onSubmit={submit}>
                      <div className="mb-3  text-center">
                        {" "}
                        <TextField
                          id="name"
                          label="Name"
                          required
                          value={name}
                          onChange={onNameChange}
                          margin="normal"
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="mb-3 text-center">
                        {" "}
                        <TextField
                          id="email"
                          required
                          type="email"
                          label="Email"
                          value={email}
                          onChange={onEmailChange}
                          margin="normal"
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="mb-1">
                        <Recaptcha
                          id="re"
                          getSuccess={setSuccess}
                          getLoading={setLoading}
                          color={mainStyle.defaultColor}
                        />
                      </div>
                      <div>
                        <ColorButton
                          contained
                          noRadius
                          download
                          disabled={!success || props.contact.contactLoading}
                          textColor="white"
                          color={mainStyle.buttonColor}
                          hoverColor={mainStyle.hoverColor}
                          style={{
                            ...mainStyle.bigButton,
                            width: "100%"
                          }}
                          type="submit"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                          >
                            <span
                              className={
                                props.contact.contactLoading ? "mr-2" : ""
                              }
                            >
                              Submit
                            </span>

                            <ClipLoader
                              sizeUnit={"px"}
                              size={15}
                              color={mainStyle.defaultColor}
                              loading={props.contact.contactLoading}
                            />
                          </div>
                        </ColorButton>
                      </div>
                    </form>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </Container>
      <div style={styles.bottom} />
    </div>
  );
};

const mapStateToProps = state => ({ contact: state.contact });
const actions = {
  addContact
};

export default connect(mapStateToProps, actions)(Download);
