import React from 'react'
import Privacy from '../../components/LMS/Legal/PrivacyPolicy'

const PrivacyPolicy = (props) => {
    return (
        <div>
            <Privacy {...props}/>
        </div>
    )
};

export default PrivacyPolicy;